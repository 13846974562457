import { ID } from 'shared/types/id';
import {
  OrderDirection,
  priorityRanking,
  SortingMode,
} from 'shared/types/sorting-mode';
import { Task } from 'shared/types/task';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getTaskDueDate } from 'shared/utils/get-task-due';

export type Options = {
  today: Timestamp;
  weekStartsOn: WeekDays;
  direction?: OrderDirection;
  mode?: SortingMode[];
  order?: ID[];
};

export type DueDateOptions = {
  today: Timestamp;
  weekStartsOn: WeekDays;
};

const getSortedTasksByDueDate = (
  tasks: Task[],
  { today, weekStartsOn }: DueDateOptions,
) => {
  // create map for easy access
  const tasksMap = tasks.reduce<Record<string, Task>>(
    (acc, task) => ({ ...acc, [task.id]: task }),
    {},
  );

  // get the due-date for the tasks (habits and repeating tasks based on schedule)
  const dueDateList = tasks.map((task) => {
    const dueDate = getTaskDueDate(task, { today, weekStartsOn });

    return { id: task.id, dueDate };
  });

  // split tasks up into tasks with dueDate and tasks without dueDate
  const tasksWithDueDate = dueDateList
    .filter((task) => !!task.dueDate)
    // order tasks with dueDate on dueDate (last dueDate last, first dueDate first)
    .sort(
      (taskA, taskB) => taskA.dueDate!.getTime() - taskB.dueDate!.getTime(),
    );

  const tasksWithoutDueDate = dueDateList.filter((task) => !task.dueDate);

  // create list of all tasks with first the ordered tasks with dueDate and after the ordered list without dueDate
  return [...tasksWithDueDate, ...tasksWithoutDueDate].map(
    ({ id }) => tasksMap[id],
  );
};

export type PriorityOptions = {
  direction?: OrderDirection;
};

const getSortedTasksByPriority = (
  tasks: Task[],
  { direction = OrderDirection.Asc }: PriorityOptions,
) =>
  tasks.toSorted((taskA, taskB) => {
    const directionTaskA = direction === OrderDirection.Asc ? taskA : taskB;
    const directionTaskB = direction === OrderDirection.Asc ? taskB : taskA;

    if (
      !directionTaskA.priority ||
      directionTaskA.priority === TaskPriorityOptions.None
    ) {
      return 1;
    }

    if (
      !directionTaskB.priority ||
      directionTaskB.priority === TaskPriorityOptions.None
    ) {
      return -1;
    }

    return (
      priorityRanking[directionTaskA.priority] -
      priorityRanking[directionTaskB.priority]
    );
  });

const getSortedTasksByCustom = (tasks: Task[], order: ID[]) =>
  tasks.toSorted((taskA, taskB) => {
    const indexA = order.indexOf(taskA.id);
    const indexB = order.indexOf(taskB.id);

    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });

export const getSortedTasks = (
  tasks: Task[],
  { today, weekStartsOn, direction, mode = [], order = [] }: Options,
) => {
  let sortedTasks = tasks
    // first sort on createdAt to have a base ordering in case there are multiple tasks with same order values
    .toSorted(
      (taskA, taskB) => taskA.createdAt.getTime() - taskB.createdAt.getTime(),
    );

  mode?.forEach((mode) => {
    switch (mode) {
      case SortingMode.DueDate:
        sortedTasks = getSortedTasksByDueDate(sortedTasks, {
          today,
          weekStartsOn,
        });
        break;
      case SortingMode.Priority:
        sortedTasks = getSortedTasksByPriority(sortedTasks, { direction });
        break;
      case SortingMode.Custom:
        sortedTasks = getSortedTasksByCustom(sortedTasks, order);
        break;
    }
  });

  return sortedTasks;
};
