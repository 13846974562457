import { useGoals } from 'features/goals';
import { useHabits, useRepeatingTasks } from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { isBetween } from 'shared/lib/date-fns';
import { ID } from 'shared/types/id';
import { SortingMode } from 'shared/types/sorting-mode';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getCustomOrderBetweenDates } from 'shared/utils/get-custom-order-between-dates';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getTaskDueDate } from 'shared/utils/get-task-due';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

import { useUpcomingSections } from './use-upcoming-sections';
import { useUpcomingTasks } from './use-upcoming-tasks';

export const useUpcomingTaskSections = (weekStartsOn = initialWeekStartsOn) => {
  const { t } = useTranslation();
  const today = useToday();

  const todos = useUpcomingTasks();
  const repeatingTasks = useRepeatingTasks({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });
  const habits = useHabits({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });
  const goals = useGoals();
  const sections = useUpcomingSections();
  const user = useUser();

  const tasks = useMemo(
    () =>
      [...todos, ...habits, ...repeatingTasks].filter(
        (task) => task.type !== TaskType.Habit || !task.hideOnTodo,
      ),
    [todos, habits, repeatingTasks],
  );

  const tasksMap = useMemo(
    () =>
      tasks.reduce<Record<ID, Task>>((acc, task) => {
        acc[task.id] = task;
        return acc;
      }, {}),
    [tasks],
  );

  const filledSections = useMemo(
    () =>
      sections.map(({ endDate, startDate, ...rest }) => {
        const sectionTasks = tasks.filter((task) => {
          const dueDate = getTaskDueDate(task, {
            today,
            weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
          });

          if (!dueDate) {
            return false;
          }

          return isBetween(dueDate, startDate, endDate);
        });

        const sectionCustomOrder = getCustomOrderBetweenDates(
          user?.settings?.todoSorting ?? {},
          startDate,
          endDate,
        );

        const sortingModes = Array.from(
          new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
        ).filter(Boolean) as SortingMode[];

        const orderedTasks = getSortedTasks(sectionTasks, {
          today,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
          mode: sortingModes,
          order: sectionCustomOrder,
        });

        return {
          ...rest,
          startDate,
          endDate,
          items: orderedTasks.map((task) =>
            mapTaskToTaskCard(task, {
              today,
              goals,
              t,
              showDue: true,
              hideTodayDue: true,
              weekStartsOn,
            }),
          ),
        };
      }),
    [
      sections,
      tasks,
      user?.settings?.todoSorting,
      user?.settings?.todoSortingMode,
      user?.settings?.startOfWeek,
      today,
      goals,
      t,
      weekStartsOn,
    ],
  );

  const taskCardTasks = useMemo(
    () =>
      filledSections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [filledSections],
  );

  return {
    sections: filledSections,
    tasksMap,
    taskCardTasks,
  };
};
