import { useMemo } from 'react';
import { useGoalTemplatesSearchQuery } from 'shared/hooks/queries/use-goal-templates-search-query';

export type Options = {
  text: string;
};

export const useGoalTemplatesBySearch = ({ text }: Options) => {
  const { data } = useGoalTemplatesSearchQuery({ text });

  return useMemo(() => data ?? [], [data]);
};
