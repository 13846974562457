import { Goal } from 'shared/types/goal';
import {
  countMetricOptions,
  DefaultMetricOptions,
} from 'shared/types/metric-template';

export type Options = {
  goals: Goal[];
};

export type GoalCompletion = {
  start: number;
  current: number;
  end: number;
};

export const getGoalCompletionCount = (
  goal: Goal,
  { goals }: Options,
): GoalCompletion => {
  if (goal.metric?.id && countMetricOptions.includes(goal.metric?.id)) {
    const subGoals = goals.filter(
      ({ parentIds, archivedAt }) =>
        !archivedAt && parentIds?.[parentIds.length - 1] === goal.id,
    );

    if (goal.metric?.id === DefaultMetricOptions.SubGoalProgress) {
      const current = subGoals.reduce<number>((acc, subGoal) => {
        if (!!subGoal.completedAt) {
          return acc + 1;
        }

        if (subGoal.metric) {
          const start = subGoal.metric?.startValue ?? 0;
          const end = subGoal.metric?.targetValue ?? Infinity;
          const lastEntry = subGoal.metric.entries?.toSorted(
            (entryA, entryB) => entryB.date.getTime() - entryA.date.getTime(),
          )[0];

          return acc + ((lastEntry?.value ?? start) === end ? 1 : 0);
        }

        const completed = subGoal.completedTaskCount ?? 0;
        const total = subGoal.taskCount ?? 0;

        return acc + (completed === total && total !== 0 ? 1 : 0);
      }, 0);

      // all sub-goals count for the same portion
      const end = subGoals.length;

      return {
        start: 0,
        current,
        end,
      };
    }

    return {
      start: 0,
      current: subGoals.reduce(
        (acc, { completedTaskCount }) => acc + (completedTaskCount ?? 0),
        goal.completedTaskCount ?? 0,
      ),
      end: subGoals.reduce(
        (acc, { taskCount }) => acc + (taskCount ?? 0),
        goal.taskCount ?? 0,
      ),
    };
  }

  const start = goal.metric?.startValue ?? 0;

  return {
    start,
    end: goal.metric?.targetValue ?? Infinity,
    current:
      goal.metric?.entries?.toSorted(
        ({ date: dateA }, { date: dateB }) => dateB.getTime() - dateA.getTime(),
      )[0]?.value ?? start,
  };
};
