import { useUpdateGoal } from 'features/goals';
import { useUpdateTask } from 'features/tasks';
import { useCallback, useMemo } from 'react';
import { Goal } from 'shared/types/goal';
import { GoalSortingMode, SortingMode } from 'shared/types/sorting-mode';
import { CheckedTaskState, TaskCardTask } from 'shared/types/task-card-task';
import { mapTaskCardTaskToTask } from 'shared/utils/mappers/map-task-card-task-to-task';

export const useHandleTaskGoalAndSectionChanges = (
  initialItems: (Goal | TaskCardTask)[],
) => {
  const { submit: updateGoal } = useUpdateGoal();
  const { submit: updateTask } = useUpdateTask();
  const oldItems = useMemo(() => structuredClone(initialItems), [initialItems]);

  return useCallback(
    (changes: (Goal | TaskCardTask)[]) => {
      // first get the changed task(s) to update the goal-counts with.
      const changedTasks = changes.filter(
        (change) => 'fields' in change,
      ) as TaskCardTask[];

      changes.forEach((change) => {
        if ('fields' in change) {
          updateTask(mapTaskCardTaskToTask(change));
          return;
        }

        // get the taskCount based on the changed tasks
        const { taskCount, completedTaskCount } = changedTasks.reduce(
          (acc, task) => {
            const oldTask = oldItems.find(
              ({ id }) => id === task.id,
            ) as TaskCardTask;
            // see if the task has changed from goal
            if (oldTask.fields.goalId === task.fields.goalId) {
              return acc;
            }

            // task goal changed, changedTask is goal so increase taskCount
            if (task.fields.goalId === change.id) {
              acc.taskCount += 1;
              acc.completedTaskCount =
                acc.completedTaskCount +
                ([
                  CheckedTaskState.Checked,
                  CheckedTaskState.RepeatChecked,
                ].includes(task.checked)
                  ? 1
                  : 0);
            }

            // task goal changed, oldTask was goal so decrease taskCount
            if (oldTask.fields.goalId === change.id) {
              acc.taskCount += -1;
              acc.completedTaskCount =
                acc.completedTaskCount +
                ([
                  CheckedTaskState.Checked,
                  CheckedTaskState.RepeatChecked,
                ].includes(task.checked)
                  ? -1
                  : 0);
            }

            return acc;
          },
          {
            taskCount: change.taskCount ?? 0,
            completedTaskCount: change.completedTaskCount ?? 0,
          },
        );

        updateGoal(
          {
            id: change.id,
            taskCount: taskCount,
            completedTaskCount: completedTaskCount,
            taskSortingMode: SortingMode.Custom,
            taskSorting: change.taskSorting,
            goalSortingMode: GoalSortingMode.Custom,
            goalSorting: change.goalSorting,
            sections: change.sections,
          },
          true,
        );
      });
    },
    [oldItems, updateGoal, updateTask],
  );
};
