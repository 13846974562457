import React from 'react';
import { useNewVersionAvailable } from 'shared/contexts/version';
import { useUser } from 'shared/hooks/use-user';

import { UserButtonView } from './user-button-view';

export type UserButtonProps = {
  onCloseSideNavigation?: () => void;
};

export const UserButton: React.FC<UserButtonProps> = ({
  onCloseSideNavigation,
}) => {
  const user = useUser();
  const newVersionAvailable = useNewVersionAvailable();

  if (!user) {
    return null;
  }

  return (
    <UserButtonView
      name={user.name}
      onCloseSideNavigation={onCloseSideNavigation}
      newVersionAvailable={newVersionAvailable}
    />
  );
};
