import React from 'react';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { Week } from '../../types/week';
import { HabitDetailCard } from '../habit-detail-card';
import * as Styled from './habits-list.style';

export type HabitsListProps = {
  items: Habit[];
  goals: Goal[];
  lifeAreas: LifeArea[];
  weeks: Week[];
  weekStartsOn: WeekDays;
  onCompleteDate?: (id: ID, date: Timestamp) => void;
  onCompleteHabit?: (id: ID) => void;
  onEditHabit?: (id: ID) => void;
  onChangeHabitType?: (
    id: ID,
    type: TaskType.Habit | TaskType.Repeating,
  ) => void;
  onChangeHideOnTodo?: (id: ID) => void;
  onArchiveHabit?: (id: ID) => void;
  onDeleteHabit?: (id: ID) => void;
  scheduleDateWidth?: number;
  scheduleVisibleDates?: number;
  scheduleScrollToDate?: number;
  isSortable?: boolean;
};

export const HabitsList: React.FC<HabitsListProps> = ({
  items,
  goals,
  lifeAreas,
  weeks,
  weekStartsOn,
  onCompleteDate,
  onCompleteHabit,
  onEditHabit,
  onChangeHideOnTodo,
  onChangeHabitType,
  onArchiveHabit,
  onDeleteHabit,
  scheduleDateWidth,
  scheduleVisibleDates,
  scheduleScrollToDate,
  isSortable,
}) => (
  <Styled.List>
    {items.map(
      ({
        id,
        iconName,
        name,
        schedules,
        hideOnTodo,
        goalId,
        lifeAreaId,
        frozenAt,
        completedAt,
        archivedAt,
      }) => (
        <Styled.ListItem key={id}>
          <HabitDetailCard
            key={id}
            id={id}
            icon={iconName}
            name={name}
            schedules={schedules}
            hideOnTodo={!!hideOnTodo}
            goalId={goalId}
            goals={goals}
            lifeAreaId={lifeAreaId}
            lifeAreas={lifeAreas}
            weeks={weeks}
            weekStartsOn={weekStartsOn}
            isFrozen={!!frozenAt}
            isCompleted={!!completedAt}
            isArchived={!!archivedAt}
            scheduleScrollToDate={scheduleScrollToDate}
            scheduleDateWidth={scheduleDateWidth}
            scheduleVisibleDates={scheduleVisibleDates}
            onCompleteHabit={onCompleteHabit}
            onCompleteDate={onCompleteDate}
            onArchiveHabit={onArchiveHabit}
            onDeleteHabit={onDeleteHabit}
            onEditHabit={onEditHabit}
            onChangeHabitType={onChangeHabitType}
            onChangeHideOnTodo={onChangeHideOnTodo}
            isSortable={!!isSortable}
          />
        </Styled.ListItem>
      ),
    )}
  </Styled.List>
);
