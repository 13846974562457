import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.grey['100']};
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadowBox.card};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) =>
    `${theme.spacing(3)} ${theme.spacing(2)} 0 ${theme.spacing(4)}`};
`;

export const TargetContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconButtonContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const Body = styled.div``;

export const ChartContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(1)} ${theme.spacing(2)}
    ${theme.spacing(1.5)} ${theme.spacing(4)}`};
`;

export const DesktopButtonContainer = styled.div`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const MobileButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `0 0 ${theme.spacing(4)} 0`};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;
