import { useDeleteGoalSection } from 'features/goals';
import { useDeleteTasks, useTasks } from 'features/tasks';
import React, { useCallback, useMemo } from 'react';
import { DeleteGoalSectionDialog } from 'shared/dialogs/delete-goal-section';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

import {
  DeleteGoalSectionContext,
  DeleteGoalSectionContextProps,
} from './delete-goal-section-context';

export type DeleteGoalSectionProviderProps = {
  children: React.ReactNode;
};

export const DeleteGoalSectionProvider: React.FC<
  DeleteGoalSectionProviderProps
> = ({ children }) => {
  const [goal, setGoal] = React.useState<Goal>();
  const [sectionId, setSectionId] = React.useState<ID>();
  const deleteGoalSection = useDeleteGoalSection();
  const tasks = useTasks({ goals: [goal?.id!] }, !!goal?.id);
  const deleteTasks = useDeleteTasks();
  const goalSection = useMemo(
    () => goal?.sections?.find(({ id }) => id === sectionId),
    [goal, sectionId],
  );

  const openDialog = useCallback((goal: Goal, sectionId: ID) => {
    setGoal(goal);
    setSectionId(sectionId);
  }, []);

  const closeDialog = useCallback(() => {
    setGoal(undefined);
    setSectionId(undefined);
  }, []);

  const submit = useCallback(
    async (withTasks: boolean) => {
      try {
        if (!goal || !goalSection) {
          return;
        }

        const tasksToDelete = withTasks
          ? tasks.filter(({ id }) => goalSection.tasks.includes(id))
          : [];
        if (tasksToDelete.length > 0) {
          await deleteTasks(tasksToDelete);
        }

        deleteGoalSection(goal, goalSection.id, tasksToDelete);
      } finally {
        closeDialog();
      }
    },
    [closeDialog, deleteGoalSection, deleteTasks, goal, goalSection, tasks],
  );

  const value = useMemo<DeleteGoalSectionContextProps>(
    () => ({ onDeleteGoalSection: openDialog }),
    [openDialog],
  );

  return (
    <DeleteGoalSectionContext.Provider value={value}>
      {children}
      <DeleteGoalSectionDialog
        open={!!goalSection}
        name={goalSection?.name}
        onClose={closeDialog}
        onSubmit={submit}
      />
    </DeleteGoalSectionContext.Provider>
  );
};
