import {
  addDays,
  addWeeks,
  endOfDay,
  isSameDay,
  isSameWeek,
  startOfWeek,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type Options = {
  today: Timestamp;
  weekStartsOn: WeekDays;
};

export const getFrequencyDueDateBySchedule = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  const completionsThisWeek =
    schedule.completions
      ?.filter((completion) =>
        isSameWeek(today, completion, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      )
      .toSorted(
        (completionA, completionB) =>
          completionB.getTime() - completionA.getTime(),
      ) ?? [];

  const completedToday = completionsThisWeek.find((completion) =>
    isSameDay(completion, today),
  );

  const nextCompletionDate =
    completionsThisWeek.length < (schedule.frequency?.count ?? 0)
      ? endOfDay(addDays(today, completedToday ? 1 : 0))
      : endOfDay(
          addWeeks(
            startOfWeek(today, {
              weekStartsOn: weekdaysNumberMap[weekStartsOn],
            }),
            1,
          ),
        );

  const lastSkip = schedule.skips?.[schedule.skips.length - 1];

  return [
    nextCompletionDate,
    lastSkip ? endOfDay(addDays(lastSkip, 1)) : undefined,
  ]
    .filter(Boolean)
    .sort(
      (nextDueDateA, nextDueDateB) =>
        nextDueDateB!.getTime() - nextDueDateA!.getTime(),
    )[0];
};
