import React, { useRef } from 'react';
import { TaskCardContextMenu } from 'shared/components/connected/task-card-context-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { DataType } from 'shared/types/data-type';
import { Task } from 'shared/types/task';
import { useTheme } from 'styled-components';

export type ContextMenuProps = {
  task: Task;
  children: React.ReactNode;
};

export const ContextMenu: React.FC<ContextMenuProps> = ({ task, children }) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { position, open, close } = useContextMenuState({
    disabled: theme.isMobile || !!task.frozenAt,
  });

  useClickOutside(containerRef, close);

  return (
    <div
      ref={containerRef}
      data-component-type={DataType.Task}
      onContextMenu={open}
    >
      {children}
      {!!position && (
        <TaskCardContextMenu
          task={task}
          onClose={close}
          location={position}
          hideMeta
        />
      )}
    </div>
  );
};
