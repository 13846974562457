import { index, Options } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const Placeholder = styled.div`
  min-height: 6.4rem;
`;

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${index(Options.MOBILE_NAVIGATION)};
  padding-bottom: env(safe-area-inset-bottom);

  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadowBox.mobileNavigation};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;

  width: 100%;

  margin: 0;
  padding: 0;

  list-style: none;
`;

export const ListItem = styled.li`
  flex: 1;
`;
