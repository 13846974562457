import React, { useMemo } from 'react';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getTaskCheckOption } from 'shared/utils/get-task-check-option';

import { TaskCardCheckbox } from './task-card-checkbox';
import * as Styled from './task-card-header.style';
import { TaskCardTitle } from './task-card-title';

export type TaskCardHeaderProps = {
  task: Task;
  onComplete: () => void;

  onChangeName: (name: string) => void;
  onClickName?: (e: React.MouseEvent) => void;
  nameInputRef?: React.RefObject<HTMLTextAreaElement>;

  showRepeatingAsTodo?: boolean;
  isDisabled?: boolean;
  weekStartsOn: WeekDays;
  today: Timestamp;
};

export const TaskCardHeader: React.FC<TaskCardHeaderProps> = ({
  task,
  onComplete,

  onChangeName,
  onClickName,
  nameInputRef,

  showRepeatingAsTodo,
  isDisabled,
  weekStartsOn,
  today,
}) => {
  const checked = useMemo(
    () =>
      getTaskCheckOption(task, { today, showRepeatingAsTodo, weekStartsOn }),
    [showRepeatingAsTodo, task, today, weekStartsOn],
  );

  return (
    <Styled.Header>
      <TaskCardCheckbox
        checked={checked}
        priority={task.priority}
        isFrozen={!!task.frozenAt}
        onChange={onComplete}
        isDisabled={isDisabled}
      />

      <Styled.HeaderContent $isFaded={isDisabled}>
        <Styled.TitleContainer $isFaded={!!task.frozenAt}>
          <TaskCardTitle
            ref={nameInputRef}
            value={task.name}
            onChange={onChangeName}
            onClick={onClickName}
            isDisabled={isDisabled}
          />
        </Styled.TitleContainer>

        {task.description && (
          <Styled.Description>{task.description}</Styled.Description>
        )}
      </Styled.HeaderContent>
    </Styled.Header>
  );
};
