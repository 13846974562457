import { TaskPriorityOptions } from 'shared/types/task-base';

export enum SortingMode {
  DueDate = 'dueDate',
  CreatedAt = 'createdAt',
  Custom = 'custom',
  Priority = 'priority',
}

export enum GoalSortingMode {
  DueDate = 'dueDate',
  Custom = 'custom',
}

export enum TodayGroupBy {
  None = 'none',
  Goal = 'goal',
  LifeArea = 'lifeArea',
  Priority = 'priority',
  Type = 'type',
}

export enum OrderDirection {
  Asc = 'ascending',
  Desc = 'descending',
}

export const priorityRanking: Record<TaskPriorityOptions, number> = {
  [TaskPriorityOptions.High]: 0,
  [TaskPriorityOptions.Medium]: 1,
  [TaskPriorityOptions.Low]: 2,
  [TaskPriorityOptions.None]: 3,
};
