import styled, { css } from 'styled-components';

export const GridContainer = styled.div`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`0 ${theme.spacing(3)}`};
    `};
`;

export const PremiumBannerContainer = styled.div`
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(3)}`};
`;

export const EmptyContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)}`};
`;
