import { Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';

import { Item } from './types';

export const handleGoalWithinSection = (
  activeItem: Goal,
  overItem: Item | GoalSection,
  items: Item[],
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  // a goal cannot move to the place of a goal-section, so it keeps the place it had.
  if (over.data.current?.type === DraggableOptions.GoalSection) {
    return { items: [...items], changedItems: [] };
  }

  // a goal cannot move to the place of a task, so it keeps the place it had.
  if (over.data.current?.type === DraggableOptions.Task) {
    return { items: [...items], changedItems: [] };
  }

  if (!activeItem.parentIds?.length) {
    return { items, changedItems: [] };
  }

  if (over.data.current?.type === DraggableOptions.Goal) {
    const overGoal = overItem as Goal;

    if (!overGoal.parentIds?.length) {
      return { items, changedItems: [] };
    }

    const activeParentIndex = items.findIndex(
      ({ id }) =>
        id === activeItem.parentIds![activeItem.parentIds!.length - 1],
    );
    const activeParent = items[activeParentIndex] as Goal | undefined;

    const overParentIndex = items.findIndex(
      ({ id }) => id === overGoal.parentIds![overGoal.parentIds!.length - 1],
    );
    const overParent = items[overParentIndex] as Goal | undefined;

    if (!activeParent || !overParent) {
      return { items, changedItems: [] };
    }

    const goalSorting = overParent.goalSorting ?? [];

    const activeIndex =
      goalSorting.findIndex((id) => id === activeItem.id) ?? 0;
    const overIndex = goalSorting.findIndex((id) => id === overItem.id) ?? 0;

    overParent.goalSorting = arrayMove(goalSorting, activeIndex, overIndex);

    return { items: [...items], changedItems: [overParent] };
  }
  return { items, changedItems: [] };
};
