import { useCallback } from 'react';
import { useDeleteTaskMutation } from 'shared/hooks/queries/use-delete-task-mutation';
import { useUpdateGoalMutation } from 'shared/hooks/queries/use-update-goal-mutation';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { getTaskDocById } from 'shared/services/backend-api';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

export const useDeleteTasks = () => {
  const track = useTrackEvents();
  const { mutateAsync: deleteTask } = useDeleteTaskMutation();
  const { mutate: updateGoal } = useUpdateGoalMutation();
  const { mutate: updateTask } = useUpdateTaskMutation();

  return useCallback(
    async (tasks: Task[], updateGoals?: boolean) => {
      // get the goals and the completedTaskCount and taskCount adjustments
      const taskRelatedGoalIds = updateGoals
        ? tasks.reduce<
            Record<ID, { taskCount: number; completedTaskCount: number }>
          >((acc, task) => {
            if (task.goalId) {
              acc[task.goalId] = acc[task.goalId] ?? {
                taskCount: 0,
                completedTaskCount: 0,
              };
              acc[task.goalId].taskCount -= 1;

              if (task.completedAt) {
                acc[task.goalId].completedTaskCount -= 1;
              }
            }

            return acc;
          }, {})
        : {};

      // loop over all tasks to delete them
      await Promise.all(
        tasks.map(async (task) => {
          const taskParentId = task.parentIds?.[task.parentIds.length - 1];

          // update the parent task, if it isn't in the list of tasks being deleted
          const parentTask =
            taskParentId && !tasks.find(({ id }) => id === taskParentId)
              ? await getTaskDocById(taskParentId)
              : undefined;
          if (parentTask) {
            updateTask({
              id: parentTask.id,
              childIds: parentTask.childIds?.filter(
                (taskId) => taskId !== task.id,
              ),
            });
          }

          if (task.childIds?.length) {
            await Promise.all(
              task.childIds.map(async (childId) => {
                // if the task is already deleting, we don't have to again
                if (tasks.find(({ id }) => id === childId)) {
                  return;
                }

                await deleteTask(childId);
                track(ActionEvents.TaskDelete, {
                  type: taskTypeToTrackingTaskType[TaskType.Task],
                });
              }),
            );
          }

          await deleteTask(task.id);

          track(ActionEvents.TaskDelete, {
            type: taskTypeToTrackingTaskType[task.type],
          });
        }),
      );

      // update all goals related to the tasks
      Object.entries(taskRelatedGoalIds).forEach(
        ([goalId, { taskCount, completedTaskCount }]) => {
          updateGoal({ goal: { id: goalId, taskCount, completedTaskCount } });
        },
      );
    },
    [deleteTask, track, updateGoal, updateTask],
  );
};
