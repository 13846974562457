import styled, { css } from 'styled-components';

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing(2)};
    `};
`;

export const ListItem = styled.li``;
