import React from 'react';
import { Plus } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './mobile-add-button.style';

export type MobileAddButtonProps = Omit<
  React.ComponentProps<'button'>,
  'onClick'
> & {
  onClick?: () => void;
};

export const MobileAddButton: React.FC<MobileAddButtonProps> = ({
  onClick,
  ...rest
}) => {
  const _onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.();
  };

  return (
    <Styled.Button onClick={_onClick} {...rest}>
      <Icon icon={Plus} />
    </Styled.Button>
  );
};
