import {
  closestCenter,
  CollisionDetection,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { useCallback, useMemo, useRef } from 'react';
import { ID } from 'shared/types/id';
import { SectionBase, SectionItemBase } from 'shared/types/orderable-section';

// this code is based upon https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx#L195
export const useSectionCollisionDetection = <
  Item extends SectionItemBase,
  Section extends SectionBase<Item>,
>(
  activeId: ID | undefined,
  items: Section[],
) => {
  const lastOverId = useRef<UniqueIdentifier>();
  const sectionIds = useMemo(() => items.map(({ id }) => id), [items]);

  return useCallback<CollisionDetection>(
    (args) => {
      // moving section
      if (!!activeId && sectionIds.includes(activeId)) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) =>
            sectionIds.includes(container.id.toString()),
          ),
        });
      }

      // moving item
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId) {
        // over section
        if (sectionIds.includes(overId.toString())) {
          const sectionItems =
            items.find(({ id }) => id === overId)?.items || [];

          if (sectionItems.length) {
            overId = getFirstCollision(
              closestCenter({
                ...args,
                droppableContainers: args.droppableContainers.filter(
                  (container) =>
                    container.id !== overId &&
                    sectionItems.find(({ id }) => id === container.id),
                ),
              }),
              'id',
            );
          }
        }
        lastOverId.current = overId ?? undefined;

        return overId ? [{ id: overId }] : [];
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items, sectionIds],
  );
};
