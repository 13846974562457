import { createContext } from 'react';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

export type DeleteGoalSectionContextProps = {
  onDeleteGoalSection: (goal: Goal, sectionId: ID) => void;
};

export const DeleteGoalSectionContext =
  createContext<DeleteGoalSectionContextProps>({
    onDeleteGoalSection: () => {},
  });
