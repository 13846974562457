import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $hasImage: boolean }>`
  position: relative;
  width: 100%;

  ${({ theme, $hasImage }) =>
    theme.isMobile &&
    $hasImage &&
    css`
      height: 20.8rem;
    `};
`;

export const MobileImage = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: none;

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};

  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(6)} ${theme.spacing(1)} 0`};
    `};
`;

export const MobileBackButtonContainer = styled.div<{ $hasImage: boolean }>`
  border-radius: ${({ theme }) => theme.spacing(2)};

  ${({ theme, $hasImage }) =>
    $hasImage &&
    css`
      background: ${rgba(theme.palette.common.black, 0.2)};
      color: ${theme.palette.common.white};
    `};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;

export const DesktopBackButtonContainer = styled.div`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const Interactions = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const FavoriteButtonContainer = styled.div<{ $isFavorite: boolean }>`
  ${({ theme, $isFavorite }) =>
    $isFavorite &&
    css`
      color: ${theme.palette.warning.main};
      fill: ${theme.palette.warning.main};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const MoreButtonContainer = styled.div<{ $hasImage: boolean }>`
  ${({ theme, $hasImage }) =>
    theme.isMobile &&
    css`
      border-radius: ${theme.spacing(2)};
      background: ${$hasImage
        ? rgba(theme.palette.common.black, 0.2)
        : 'transparent'};
      color: ${$hasImage ? theme.palette.common.white : 'inherit'};
    `};
`;
