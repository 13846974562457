import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(8)}`};

  overflow: auto;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(2)} 0 ${theme.spacing(8)}`};
    `};
`;
