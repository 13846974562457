import { ID } from 'shared/types/id';

export type ItemBase = { id: ID; parentIds?: ID[] | null };

export const groupItems = <Item extends ItemBase>(items: Item[]): Item[] => {
  const idToItem = new Map(items.map((item) => [item.id, item]));
  const reordered: Item[] = [];
  const visited = new Set<string>();

  items.forEach((item) => {
    const parentId = item.parentIds?.slice(-1)[0];
    // check to see if an item has been added or is a child of a parent item to come
    if (visited.has(item.id) || (parentId && idToItem.has(parentId))) {
      return;
    }

    visited.add(item.id);
    reordered.push(item);

    items.forEach((child) => {
      if (child.parentIds?.slice(-1)[0] === item.id) {
        visited.add(child.id);
        reordered.push(child);
      }
    });
  });

  return reordered;
};
