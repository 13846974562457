import { Active, Over } from '@dnd-kit/core';
import { GoalSection } from 'shared/types/goal-section';
import { getGoalIdFromOtherSectionId } from 'shared/utils/goal-section-id-helpers';

import { Item } from './types';

export const getItemById = (
  items: Item[],
  interactiveItem: Active | Over,
): Item | GoalSection | undefined => {
  const interactiveItemId = interactiveItem.id.toString();
  const otherSectionGoalId = getGoalIdFromOtherSectionId(interactiveItemId);
  const activeItemIndex = items.findIndex(
    (item) =>
      item.id === interactiveItemId ||
      otherSectionGoalId === item.id ||
      (!('fields' in item) &&
        item.sections?.some(({ id }) => id === interactiveItemId)),
  );
  // Goal | TaskCardTask
  const item = items[activeItemIndex];

  // Section
  if (
    item.id !== interactiveItemId &&
    !otherSectionGoalId &&
    !('fields' in item)
  ) {
    return item.sections?.find(({ id }) => id === interactiveItemId);
  }

  return item;
};
