import { Active, Over } from '@dnd-kit/core';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';
import { TaskCardTask } from 'shared/types/task-card-task';

import { getItemById } from './get-item-by-id';
import { handleGoalBetweenSections } from './handle-goal-between-sections';
import { handleGoalSectionBetweenSections } from './handle-goal-section-between-sections';
import { handleGoalSectionWithinSection } from './handle-goal-section-within-section';
import { handleGoalWithinSection } from './handle-goal-within-section';
import { handleTaskWithinSection } from './handle-task-within-section';
import { handleTasksBetweenSections } from './handle-tasks-between-sections';
import { Item } from './types';

export const handleItemBetweenNestedSectionMovement = (
  items: Item[],
  active: Active,
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  const activeItem = getItemById(items, active);
  const overItem = getItemById(items, over);

  if (!activeItem || !overItem || activeItem.id === overItem.id) {
    return { items, changedItems: [] };
  }

  if (active.data.current?.type === DraggableOptions.Task) {
    return handleTasksBetweenSections(
      activeItem as TaskCardTask,
      overItem,
      items,
      over,
    );
  }

  if (active.data.current?.type === DraggableOptions.GoalSection) {
    return handleGoalSectionBetweenSections(
      activeItem as GoalSection,
      overItem,
      items,
      over,
    );
  }

  if (active.data.current?.type === DraggableOptions.Goal) {
    return handleGoalBetweenSections(activeItem as Goal, overItem, items, over);
  }

  return { items, changedItems: [] };
};

export const handleItemWithinNestedSectionMovement = (
  items: Item[],
  active: Active,
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  const activeItem = getItemById(items, active);
  const overItem = getItemById(items, over);

  if (!activeItem || !overItem || activeItem.id === overItem.id) {
    return { items, changedItems: [] };
  }

  if (active.data.current?.type === DraggableOptions.Task) {
    return handleTaskWithinSection(
      activeItem as TaskCardTask,
      overItem,
      items,
      over,
    );
  }

  if (active.data.current?.type === DraggableOptions.GoalSection) {
    return handleGoalSectionWithinSection(
      activeItem as GoalSection,
      overItem,
      items,
      over,
    );
  }

  if (active.data.current?.type === DraggableOptions.Goal) {
    return handleGoalWithinSection(activeItem as Goal, overItem, items, over);
  }

  return { items, changedItems: [] };
};
