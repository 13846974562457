import { CircularProgress } from '@mui/material';
import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  margin: ${({ theme }) => `${theme.spacing(8)} auto`};
  max-width: 32rem;
`;

export const Anchor = styled(QueryNavLink)`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
`;

export const Loader = styled(CircularProgress)``;
