import { addMonths, isSameMonth, startOfMonth } from 'date-fns';
import {
  useMonthTasks,
  useOverdueTasks,
  useRestOfMonthTasks,
  useTodaysTodos,
  useUpcomingTenDaysTasks,
  useYearTasks,
} from 'features/tasks';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useShouldHaveRestOfMonth } from 'shared/hooks/use-should-have-rest-of-month';

export const useUpcomingTasks = () => {
  const today = useToday();
  const overdueTasks = useOverdueTasks();
  const todaysTasks = useTodaysTodos();
  const restOfWeekTasks = useUpcomingTenDaysTasks();
  const restOfMonthTasks = useRestOfMonthTasks();
  const { shouldHaveRestOfMonth, restOfMonthStartDate } =
    useShouldHaveRestOfMonth();

  // the `restOfTheMonthsTasks` could contain all the tasks of the next month. To prevent double tasks,
  // we need to see if the `restOfMonthStartDate` is in the same month as the current day.
  // If this is not the case, we need to add an extra month
  const startMonth =
    shouldHaveRestOfMonth && !isSameMonth(restOfMonthStartDate, today) ? 1 : 0;
  const firstMonthTasks = useMonthTasks(addMonths(today, startMonth + 1));
  const secondMonthTasks = useMonthTasks(addMonths(today, startMonth + 2));
  const thirdMonthTasks = useMonthTasks(addMonths(today, startMonth + 3));
  const fourthMonthTasks = useMonthTasks(addMonths(today, startMonth + 4));
  const fifthMonthTasks = useMonthTasks(addMonths(today, startMonth + 5));
  const sixthMonthTasks = useMonthTasks(addMonths(today, startMonth + 6));
  const seventhMonthTasks = useMonthTasks(addMonths(today, startMonth + 7));
  const eighthMonthTasks = useMonthTasks(addMonths(today, startMonth + 8));
  const ninthMonthTasks = useMonthTasks(addMonths(today, startMonth + 9));
  const tenthMonthTasks = useMonthTasks(addMonths(today, startMonth + 10));

  const nextYearStartDate = startOfMonth(addMonths(today, startMonth + 11));
  const nextYearTasks = useYearTasks(nextYearStartDate);

  return useMemo(
    () => [
      ...overdueTasks,
      ...todaysTasks,
      ...restOfWeekTasks,
      ...restOfMonthTasks,
      ...firstMonthTasks,
      ...secondMonthTasks,
      ...thirdMonthTasks,
      ...fourthMonthTasks,
      ...fifthMonthTasks,
      ...sixthMonthTasks,
      ...seventhMonthTasks,
      ...eighthMonthTasks,
      ...ninthMonthTasks,
      ...tenthMonthTasks,
      ...nextYearTasks,
    ],
    [
      eighthMonthTasks,
      fifthMonthTasks,
      firstMonthTasks,
      fourthMonthTasks,
      nextYearTasks,
      ninthMonthTasks,
      overdueTasks,
      restOfMonthTasks,
      restOfWeekTasks,
      secondMonthTasks,
      seventhMonthTasks,
      sixthMonthTasks,
      tenthMonthTasks,
      thirdMonthTasks,
      todaysTasks,
    ],
  );
};
