import { useGoals } from 'features/goals';
import React, { useMemo, useRef } from 'react';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { ID } from 'shared/types/id';

import { EditList } from './edit-list';
import { ManageList } from './manage-list';

export type GoalSectionContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  id: ID;
  goalId: ID;
  inputRef: React.RefObject<HTMLInputElement>;
  onClose: () => void;
};

export const GoalSectionContextMenu: React.FC<GoalSectionContextMenuProps> = ({
  id,
  goalId,
  inputRef,
  onClose,

  position,
  location,
  referenceElement,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const goals = useGoals();
  const goal = useMemo(
    () => goals.find((goal) => goal.id === goalId),
    [goalId, goals],
  );

  useClickOutside(containerRef, onClose);

  return goal && !goal.frozenAt ? (
    <PopupMenu
      ref={containerRef}
      location={location}
      position={position}
      referenceElement={referenceElement}
    >
      <EditList
        goal={goal}
        sectionId={id}
        inputRef={inputRef}
        onClose={onClose}
      />
      <ManageList goal={goal} sectionId={id} onClose={onClose} />
    </PopupMenu>
  ) : null;
};
