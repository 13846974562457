import { Currency } from 'shared/types/currency';

// todo: actually fetch currencies
export const getPremiumCurrencies = (): Currency[] => [
  {
    id: 'eur',
    label: 'EUR',
    sign: '€',
    options: [
      {
        type: 'year',
        amount: '3.99',
        totalAmount: '47,90',
        discountPercentage: 20,
      },
      {
        type: 'month',
        amount: '4,99',
      },
    ],
  },
  {
    id: 'usd',
    label: 'USD',
    sign: '$',
    options: [
      {
        type: 'year',
        amount: '3.99',
        totalAmount: '47.90',
        discountPercentage: 20,
      },
      {
        type: 'month',
        amount: '4.99',
      },
    ],
  },
  {
    id: 'gbp',
    label: 'GBP',
    sign: '£',
    options: [
      {
        type: 'year',
        amount: '3.33',
        totalAmount: '39.99',
        discountPercentage: 33,
      },
      {
        type: 'month',
        amount: '4.99',
      },
    ],
  },
];
