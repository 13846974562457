import styled, { css } from 'styled-components';

export const Header = styled.header`
  padding: ${({ theme }) => `0 ${theme.spacing(6)} ${theme.spacing(3)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`0 ${theme.spacing(3)}`};
    `};
`;
