import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'shared/assets/icons';
import { Locales } from 'shared/assets/localization';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { FormSelect } from 'shared/components/ui/form-select';
import { IconButton } from 'shared/components/ui/icon-button';
import { version } from 'shared/constants';
import { useDateFormatOptions } from 'shared/hooks/use-date-format-options';
import { useSupportedLanguageOptions } from 'shared/hooks/use-supported-language-options';
import { useThemeModeOptions } from 'shared/hooks/use-theme-mode-options';
import { useWeekDaysOptions } from 'shared/hooks/use-week-days-options';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { ThemeModes } from 'shared/types/theme-modes';
import { Notifications } from 'shared/types/user-settings';
import { UserSubscription } from 'shared/types/user-subscription';
import { WeekDays } from 'shared/types/week-days';

import { NotificationsTable } from './notifications-table';
import * as Styled from './settings-form.style';
import { UserName } from './user-name';

export type SettingsFormProps = {
  onClose: () => void;
  name: string;
  onChangeName: (name: string) => void;
  language?: Locales;
  onChangeLanguage: (language: Locales) => void;
  isSignedInWithProvider?: boolean;
  email: string;
  onChangePassword: () => void;
  dateFormat: DateFormatOptions;
  onChangeDateFormat: (dateFormat: DateFormatOptions) => void;
  weekStartSetOnIos?: boolean;
  weekStartsOn: WeekDays;
  onChangeWeekStart: (weekDay: WeekDays) => void;
  notifications?: Notifications;
  onChangeNotifications: (notifications: Notifications) => void;
  themePreference: ThemeModes;
  onChangeThemePreference: (themeMode: ThemeModes) => void;
  activeSubscription?: UserSubscription;
  onManageSubscription: () => void;
  manageSubscriptionLoading?: boolean;
};

export const SettingsForm: React.FC<SettingsFormProps> = ({
  onClose,
  name,
  onChangeName,
  language,
  onChangeLanguage,
  isSignedInWithProvider,
  email,
  onChangePassword,
  dateFormat,
  onChangeDateFormat,
  weekStartSetOnIos,
  weekStartsOn,
  onChangeWeekStart,
  notifications,
  onChangeNotifications,
  themePreference,
  onChangeThemePreference,
  activeSubscription,
  onManageSubscription,
  manageSubscriptionLoading,
}) => {
  const { t } = useTranslation();
  const supportedLanguageOptions = useSupportedLanguageOptions();
  const dateFormatOptions = useDateFormatOptions();
  const themeModeOptions = useThemeModeOptions();
  const weekDaysOptions = useWeekDaysOptions();

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onChangeLanguage(e.target.value as Locales);

  const onChangeDateFormatOption = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onChangeDateFormat(e.target.value as DateFormatOptions);

  const onChangeWeekStartsOnOption = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => onChangeWeekStart(e.target.value as WeekDays);

  const onChangeThemeMode = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onChangeThemePreference(e.target.value as ThemeModes);

  return (
    <Styled.Container>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('forms.user-settings.title')}
        </Typography>
        <IconButton icon={X} onClick={onClose} />
      </Styled.Header>

      <Styled.Body>
        {/* name */}
        <Styled.Section>
          <Typography variant="h6" component="label" htmlFor="name">
            {t('forms.user-settings.sections.name.label')}
          </Typography>

          <UserName id="name" value={name} onChange={onChangeName} />
        </Styled.Section>

        {/* Language */}
        <Styled.Section>
          <Typography variant="h6" component="span">
            {t('forms.user-settings.sections.language.label')}
          </Typography>

          <FormSelect
            id="language"
            options={supportedLanguageOptions}
            placeholder={t(
              'forms.user-settings.sections.language.fields.language.placeholder',
            )}
            label={t(
              'forms.user-settings.sections.language.fields.language.label',
            )}
            value={language}
            onChange={onChangeLang}
            fullWidth
          />
        </Styled.Section>

        {/* security */}
        <Styled.Section>
          <Typography variant="h6" component="span">
            {t('forms.user-settings.sections.security.label')}
          </Typography>

          {isSignedInWithProvider ? (
            <>
              <Styled.SubSection>
                <Typography variant="body2" component="p">
                  {t('forms.user-settings.sections.security.siwa.title')}
                </Typography>
                <Styled.SecondaryTextTypography variant="overline">
                  {email}
                </Styled.SecondaryTextTypography>
              </Styled.SubSection>
            </>
          ) : (
            <>
              <Styled.SubSection>
                <Typography variant="body2" component="p">
                  {t('forms.user-settings.sections.security.email.title')}
                </Typography>
                <Styled.SecondaryTextTypography variant="overline" $canCopy>
                  {email}
                </Styled.SecondaryTextTypography>
              </Styled.SubSection>
              <Styled.SplitSectionRow>
                <Typography variant="body2" component="span">
                  {t('forms.user-settings.sections.security.password.label')}
                </Typography>
                <Button
                  onClick={onChangePassword}
                  size={Sizes.Small}
                  variant={Variants.Outlined}
                  color={Colors.Inherit}
                >
                  {t('forms.user-settings.sections.security.password.button')}
                </Button>
              </Styled.SplitSectionRow>
            </>
          )}
        </Styled.Section>

        {/* dates */}
        <Styled.Section>
          <Typography variant="h6" component="span">
            {t('forms.user-settings.sections.date-and-time.label')}
          </Typography>

          <FormSelect
            id="date-format"
            options={dateFormatOptions}
            label={t(
              'forms.user-settings.sections.date-and-time.date-format.label',
            )}
            value={dateFormat}
            onChange={onChangeDateFormatOption}
            fullWidth
          />

          <FormSelect
            id="week-start"
            options={weekDaysOptions}
            label={t(
              'forms.user-settings.sections.date-and-time.week-start.label',
            )}
            value={weekStartsOn}
            disabled={weekStartSetOnIos}
            onChange={onChangeWeekStartsOnOption}
            helperText={
              weekStartSetOnIos
                ? t(
                    'forms.user-settings.sections.date-and-time.week-start-set-on-ios.label',
                  )
                : undefined
            }
            fullWidth
          />
        </Styled.Section>

        {/* notifications */}
        <Styled.Section>
          <Typography variant="h6" component="span">
            {t('forms.user-settings.sections.notifications.label')}
          </Typography>

          <NotificationsTable
            notifications={notifications}
            onChange={onChangeNotifications}
          />
        </Styled.Section>

        {/* appearance */}
        <Styled.Section>
          <Typography variant="h6" component="span">
            {t('forms.user-settings.sections.appearance.label')}
          </Typography>

          <FormSelect
            id="thema"
            options={themeModeOptions}
            label={t(
              'forms.user-settings.sections.appearance.theme-preference.label',
            )}
            value={themePreference}
            onChange={onChangeThemeMode}
            fullWidth
          />
        </Styled.Section>

        {/* subscription */}
        {!!activeSubscription && (
          <Styled.Section>
            <Typography variant="h6" component="span">
              {t('forms.user-settings.sections.subscriptions.label')}
            </Typography>
            <Styled.SplitSectionRow>
              <Typography variant="body2" component="span">
                {t(
                  activeSubscription.unsubscribedAt
                    ? 'forms.user-settings.sections.subscriptions.premium-unsubscribed.label'
                    : 'forms.user-settings.sections.subscriptions.premium.label',
                )}
              </Typography>
              <Button
                onClick={onManageSubscription}
                size={Sizes.Small}
                variant={Variants.Outlined}
                color={Colors.Inherit}
                disabled={manageSubscriptionLoading}
              >
                {t(
                  'forms.user-settings.sections.subscriptions.premium.button.label',
                )}
                {manageSubscriptionLoading && (
                  <Styled.ButtonLoader color="primary" size={12} />
                )}
              </Button>
            </Styled.SplitSectionRow>
          </Styled.Section>
        )}

        {/* delete account */}
        <Styled.Section>
          <Typography variant="h6" component="label">
            {t('forms.user-settings.sections.delete-account.label')}
          </Typography>
          <Styled.SecondaryTextTypography variant="overline">
            {t('forms.user-settings.sections.delete-account.description.1')}
            <Styled.Anchor href="mailto:support@griply.app">
              support@griply.app
            </Styled.Anchor>
            {t('forms.user-settings.sections.delete-account.description.2')}
          </Styled.SecondaryTextTypography>
        </Styled.Section>

        {/* app version */}
        <Styled.Section>
          <Styled.SecondaryTextTypography variant="overline" textAlign="center">
            {t('forms.user-settings.sections.app-version.label', { version })}
          </Styled.SecondaryTextTypography>
        </Styled.Section>
      </Styled.Body>
    </Styled.Container>
  );
};
