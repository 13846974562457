import { useDeleteTask, useTaskById } from 'features/tasks';
import React, { useRef } from 'react';
import { Trash2 } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { ID } from 'shared/types/id';

export type TaskDeleteButtonProps = {
  taskId: ID;
};

export const TaskDeleteButton: React.FC<TaskDeleteButtonProps> = ({
  taskId,
}) => {
  const task = useTaskById(taskId);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { submit: deleteTask } = useDeleteTask();

  const onClick = () => !!task && deleteTask(task);

  return (
    <>
      <IconButton
        icon={Trash2}
        size={Sizes.Large}
        ref={buttonRef}
        onClick={onClick}
      />
    </>
  );
};
