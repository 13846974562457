import { Habit, NewHabit } from './habit';
import { HabitSchedule } from './habit-schedule';
import { ID } from './id';
import { NewRepeatingTask, RepeatingTask } from './repeating-task';
import { TaskPriorityOptions, TaskType } from './task-base';
import { Timestamp } from './timestamp';
import { NewTodo, Todo } from './todo';

export type Task = Todo | Habit | RepeatingTask;

export type CreateTask = {
  name: string;
  description?: string;
  type: TaskType;
  endStrategy?: {
    deadline?: Timestamp | null;
    completionCount?: number | null;
  } | null;
  goalId: ID | null;
  sectionId: ID | null;
  lifeAreaId: ID | null;
  reminderTime: number | null;
  parentIds: ID[] | null;
  priority: TaskPriorityOptions | null;
  schedules: HabitSchedule[] | null;
};

export type NewTask = NewTodo | NewHabit | NewRepeatingTask;

export type UpdateTask = Partial<Task> & Required<Pick<Task, 'id'>>;

export enum TaskCheckOptions {
  Checked = 'checked',
  Unchecked = 'unchecked',
  RepeatSingleChecked = 'repeat-single-checked',
  RepeatChecked = 'repeat-checked',
}

export enum LinkOptions {
  LifeArea = 'life-area',
  Goal = 'goal',
}
