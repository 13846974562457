import { endOfDay, isYesterday } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useToday } from 'shared/contexts/today';
import {
  CreateTask,
  CreateTask as NewTaskType,
  NewTask,
} from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

const initializeNewTask: (
  initialValues?: Partial<CreateTask>,
) => CreateTask = ({
  name,
  endStrategy,
  goalId,
  sectionId,
  lifeAreaId,
  reminderTime,
  schedules,
  parentIds,
  priority,
  ...initialValues
} = {}) => ({
  name: name || '',
  type: TaskType.Task,
  endStrategy: {
    deadline: endStrategy?.deadline ? endOfDay(endStrategy.deadline) : null,
    completionCount: null,
  },
  goalId: goalId ?? null,
  sectionId: sectionId ?? null,
  lifeAreaId: lifeAreaId ?? null,
  reminderTime: reminderTime || null,
  schedules: schedules || null,
  parentIds: parentIds || null,
  priority: priority || null,
  ...initialValues,
});

export const useNewTaskState = (
  onSubmit: (task: NewTask) => void,
  initialValues: Partial<CreateTask> = {},
) => {
  const today = useToday();
  const [newTask, setNewTask] = useState(initializeNewTask(initialValues));

  const updateNewTask = useCallback(
    (partialTask: Partial<NewTaskType>) =>
      setNewTask((currentNewTask) => ({
        ...currentNewTask,
        ...partialTask,
        endStrategy: {
          ...currentNewTask.endStrategy,
          ...partialTask?.endStrategy,
        },
      })),
    [],
  );

  const submit = useCallback(() => {
    if (!newTask.name) {
      return;
    }

    setNewTask(initializeNewTask(initialValues));

    onSubmit({
      ...newTask,
      startDate: new Date(),
      createdAt: new Date(),
      endStrategy: {
        deadline: newTask.endStrategy?.deadline ?? null,
        completionCount: newTask.endStrategy?.completionCount ?? null,
      },
    } as NewTask);
  }, [initialValues, newTask, onSubmit]);

  // update the deadline when it becomes a new day
  useEffect(() => {
    setNewTask(({ endStrategy, ...currentNewTask }) => ({
      ...currentNewTask,
      endStrategy: {
        deadline:
          endStrategy?.deadline && isYesterday(endStrategy.deadline)
            ? today
            : endStrategy?.deadline,
        completionCount: endStrategy?.completionCount ?? null,
      },
    }));
  }, [today]);

  useEffect(() => {
    setNewTask((currentNewTask) => ({
      ...currentNewTask,
      goalId: initialValues.goalId ?? null,
    }));
  }, [initialValues.goalId]);

  return {
    newTask,
    updateNewTask,
    submit,
  };
};
