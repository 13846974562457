import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './template-search.style';

export type TemplateSearchProps = {
  onChange: (search: string) => void;
};

export const TemplateSearch: React.FC<TemplateSearchProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const [input, setInput] = React.useState('');

  const _onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setInput(target.value);
    onChange(target.value);
  };

  const clear = () => {
    setInput('');
    onChange('');
  };

  return (
    <Styled.Container $hasValue={!!input}>
      <Styled.IconContainer>
        <Icon icon={Search} />
      </Styled.IconContainer>
      <Styled.Input
        value={input}
        onChange={_onChange}
        placeholder={t('pages.goal-templates.search.placeholder.label')}
      />
      {!!input && (
        <Styled.ClearButton onClick={clear} tabIndex={-1}>
          <Icon icon={X} />
        </Styled.ClearButton>
      )}
    </Styled.Container>
  );
};
