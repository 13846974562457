import React from 'react';
import { useTheme } from 'styled-components';

import { HabitDetailDesktopCard } from '../habit-detail-desktop-card';
import { HabitDetailMobileCard } from '../habit-detail-mobile-card';

export type HabitDetailCardProps = React.ComponentProps<
  typeof HabitDetailDesktopCard
> &
  React.ComponentProps<typeof HabitDetailMobileCard>;

export const HabitDetailCard: React.FC<HabitDetailCardProps> = ({
  id,
  icon,
  name,
  schedules,
  goalId,
  goals,
  lifeAreaId,
  lifeAreas,
  weeks,
  hideOnTodo,
  isFrozen,
  isCompleted,
  isArchived,
  scheduleScrollToDate,
  scheduleDateWidth,
  scheduleVisibleDates,
  onCompleteHabit,
  onCompleteDate,
  onEditHabit,
  onChangeHabitType,
  onChangeHideOnTodo,
  onArchiveHabit,
  onDeleteHabit,
  weekStartsOn,
  isSortable,
}) => {
  const theme = useTheme();
  return theme.isMobile ? (
    <HabitDetailMobileCard
      id={id}
      icon={icon}
      name={name}
      schedules={schedules}
      goalId={goalId}
      goals={goals}
      lifeAreaId={lifeAreaId}
      lifeAreas={lifeAreas}
      weekStartsOn={weekStartsOn}
      isFrozen={!!isFrozen}
      isCompleted={!!isCompleted}
      isArchived={!!isArchived}
      isSortable={!!isSortable}
      onCompleteDate={onCompleteDate}
      onEditHabit={onEditHabit}
    />
  ) : (
    <HabitDetailDesktopCard
      id={id}
      icon={icon}
      name={name}
      schedules={schedules}
      hideOnTodo={!!hideOnTodo}
      goalId={goalId}
      goals={goals}
      lifeAreaId={lifeAreaId}
      lifeAreas={lifeAreas}
      weeks={weeks}
      weekStartsOn={weekStartsOn}
      isFrozen={!!isFrozen}
      scheduleScrollToDate={scheduleScrollToDate}
      scheduleDateWidth={scheduleDateWidth}
      scheduleVisibleDates={scheduleVisibleDates}
      onCompleteHabit={onCompleteHabit}
      onCompleteDate={onCompleteDate}
      onArchiveHabit={onArchiveHabit}
      onDeleteHabit={onDeleteHabit}
      onEditHabit={onEditHabit}
      onChangeHabitType={onChangeHabitType}
      onChangeHideOnTodo={onChangeHideOnTodo}
      isCompleted={isCompleted}
      isArchived={isArchived}
      isSortable={!!isSortable}
    />
  );
};
