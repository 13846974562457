import { useDuplicateSection } from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyDuplicateObject, Edit3 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { Goal } from 'shared/types/goal';

export type EditListProps = {
  goal: Goal;
  sectionId: string;
  inputRef: React.RefObject<HTMLInputElement>;
  onClose: () => void;
};

export const EditList: React.FC<EditListProps> = ({
  goal,
  sectionId,
  inputRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const duplicateSection = useDuplicateSection();

  const onEditName = (e: React.MouseEvent) => {
    e.preventDefault();
    inputRef.current?.focus();
    onClose();
  };

  const onDuplicateSection = (e: React.MouseEvent) => {
    e.preventDefault();
    duplicateSection(sectionId, goal);
    onClose();
  };

  return (
    <PopupMenuList hasBottomBorder>
      <PopupMenuListItem>
        <PopupMenuButton onClick={onEditName} start={<Icon icon={Edit3} />}>
          {t('context-menus.goal.section.labels.edit')}
        </PopupMenuButton>
      </PopupMenuListItem>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onDuplicateSection}
          start={<Icon icon={CopyDuplicateObject} />}
        >
          {t('context-menus.goal.section.labels.duplicate')}
        </PopupMenuButton>
      </PopupMenuListItem>
    </PopupMenuList>
  );
};
