import { CSS, Transform } from '@dnd-kit/utilities';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $isDisabled: boolean;
  $isSelected: boolean;
  $isDragging?: boolean;
  $transform?: Transform | null;
  $transition?: string;
}>`
  position: relative;
  container-type: inline-size;
  display: flex;
  align-items: center;
  height: 5.6rem;
  padding: ${({ theme }) =>
    theme.isMobile ? `0 ${theme.spacing(3)} 0 0` : `0 ${theme.spacing(3)}`};
  transform: ${({ $transform }) =>
    $transform && CSS.Translate.toString($transform)};
  transition: ${({ $transition }) => `background 200ms, ${$transition}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) =>
    theme.isMobile
      ? theme.palette.background.paper
      : theme.palette.background.default};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `};

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${theme.palette.brown['50']};
    `}

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      > * {
        opacity: 0;
      }

      &:after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: ${({ theme }) => theme.spacing(2)};
        background-color: ${({ theme }) => theme.palette.primary.light};
      }
    `};

  // adjusting items that are not dragged
  ${({ $transform }) =>
    !$transform &&
    css`
      &:hover {
        ${DragHandle} {
          opacity: 1;
        }
      }
    `};
`;

export const DragHandle = styled.div`
  position: absolute;
  right: calc(100% - ${({ theme }) => theme.spacing(2)});
  top: 50%;

  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 200ms;

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  min-width: 13.6rem;

  ${({ theme }) =>
    `@container (min-width: ${theme.breakpoints.values.xs + 1}px)`} {
    width: 25.4rem;
    max-width: 40%;
  }
`;

export const IconContainer = styled.span`
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.brown['100']};

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};

  ${({ theme }) =>
    `@container (min-width: ${theme.breakpoints.values.xs + 1}px)`} {
    display: flex;
  }
`;

export const CompletedContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(33%, -33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const DetailContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  min-width: 0;
`;

export const TitleContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const Title = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.h6)};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TitleIconContainer = styled.span<{ $rotated: boolean }>`
  display: inline-block;

  font-size: 1.6rem;
  line-height: 62.5%;

  ${({ $rotated }) =>
    $rotated &&
    css`
      transform: rotate(180deg);
    `};
`;

export const MetaContainer = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  min-width: 0;

  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const MetaSpan = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Dot = styled.span`
  display: inline-block;
  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};
  min-width: ${({ theme }) => theme.spacing(1)};
  min-height: ${({ theme }) => theme.spacing(1)};

  border-radius: 50%;
  background: ${({ theme }) => theme.palette.divider};
`;

export const ScheduleContainer = styled.div`
  flex: 1;
  height: 100%;
`;
