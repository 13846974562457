import { doc, getDoc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { goalConverter } from './converters/goal';
import { CollectionOptions, getDatabase } from './helpers';

export const getGoalById = async (id: ID) => {
  return doc(getDatabase(), CollectionOptions.Goals, id).withConverter(
    goalConverter,
  );
};

export const getGoalDocById = async (id: ID) => {
  const query = await getGoalById(id);
  const snapshot = await getDoc(query);
  return snapshot.data();
};
