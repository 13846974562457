import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { TaskCardTask } from 'shared/types/task-card-task';

import { Task } from './task';
import * as Styled from './task-goal-section-list.style';

export type TasksListProps = Omit<
  React.ComponentProps<typeof Task>,
  'task' | 'hasDnd'
> & {
  tasks: TaskCardTask[];
  hasIndentation?: boolean;
  hasDnd?: boolean;
  noRightSpacing?: boolean;
};

export const TasksList: React.FC<TasksListProps> = ({
  tasks,
  hasIndentation,
  hasDnd,
  noRightSpacing,

  selectedItem,
  onTask,
  onUnfocus,
  hideGoal,
  showRepeatingAsTodo,
  isSortingDisabled,
}) => {
  const SortableContainer = hasDnd ? SortableContext : React.Fragment;
  const sortableProps = hasDnd
    ? {
        items: tasks.map(({ id }) => id),
        strategy: verticalListSortingStrategy,
      }
    : {};

  return (
    // @ts-ignore
    <SortableContainer {...sortableProps}>
      <Styled.List $noRightSpacing={!!noRightSpacing}>
        {tasks.map((task) => (
          <Styled.ListItem key={task.id} $hasIndentation={!!hasIndentation}>
            <Task
              task={task}
              selectedItem={selectedItem}
              onTask={onTask}
              onUnfocus={onUnfocus}
              hideGoal={hideGoal}
              showRepeatingAsTodo={showRepeatingAsTodo}
              isSortingDisabled={isSortingDisabled}
              hasDnd={hasDnd}
            />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </SortableContainer>
  );
};
