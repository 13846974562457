import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { LinkedItemSearchMenu } from 'shared/components/ui/linked-item-search-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';
import { LinkOptions } from 'shared/types/task';

import * as Styled from './linked-item-select.style';

export type LinkedItemSelectProps = {
  lifeAreaId?: ID | null;
  goalId?: ID | null;
  onChange: (changes: { lifeAreaId: ID | null; goalId: ID | null }) => void;
};

export const LinkedItemSelect: React.FC<LinkedItemSelectProps> = ({
  lifeAreaId,
  goalId,
  onChange,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const item = useMemo(
    () =>
      goals.find(({ id }) => id === goalId) ??
      lifeAreas.find(({ id }) => id === lifeAreaId),
    [goals, lifeAreas, goalId, lifeAreaId],
  );

  const onChangeLinkedItem = (link: { id: ID; type: LinkOptions } | null) =>
    onChange({
      lifeAreaId: link?.type === LinkOptions.LifeArea ? link.id : null,
      goalId: link?.type === LinkOptions.Goal ? link.id : null,
    });

  const onCreateGoal = () => {
    // do nothing, as we cannot create a goal on the create-task sheet.
  };

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Title onClick={openMenu} $hasValue={!!item}>
        <Styled.IconContainer>
          <Icon icon={Target} />
        </Styled.IconContainer>

        <Styled.Label>
          {item?.name ?? t('forms.new-task.linked-item.placeholder')}
        </Styled.Label>

        <Styled.ChevronContainer>
          <Icon icon={ChevronLeft} />
        </Styled.ChevronContainer>
      </Styled.Title>

      {menuOpen && (
        <LinkedItemSearchMenu
          goals={goals}
          lifeAreas={lifeAreas}
          onChange={onChangeLinkedItem}
          onCreate={onCreateGoal}
          value={item?.id}
          referenceElement={containerRef}
        />
      )}
    </Styled.Container>
  );
};
