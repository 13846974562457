import { useUpdateGoal } from 'features/goals';
import { useCallback } from 'react';
import { ID } from 'shared/types/id';

import { useGoalById } from './use-goal-by-id';

export const useCreateSection = (goalId?: ID) => {
  const goal = useGoalById(goalId);
  const { submit: updateGoal } = useUpdateGoal();

  return useCallback(() => {
    if (!goal) {
      return;
    }

    const sections = goal.sections ?? [];

    updateGoal({
      id: goalId,
      sections: [
        ...sections,
        {
          id: window.crypto.randomUUID(),
          name: '',
          tasks: [],
          createdAt: new Date(),
        },
      ],
    });
  }, [goal, goalId, updateGoal]);
};
