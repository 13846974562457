import { UAParser } from 'ua-parser-js';

export const isMobileDevice = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return result.device.type === 'mobile';
};

declare module '@mui/material/styles' {
  interface Theme {
    isMobile: boolean;
  }

  interface ThemeOptions {
    isMobile?: boolean;
  }
}
