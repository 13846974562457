import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { useCallback, useEffect, useState } from 'react';
import { ID } from 'shared/types/id';
import { SectionBase, SectionItemBase } from 'shared/types/orderable-section';
import {
  handleItemBetweenSectionsMovement,
  handleItemWithinSectionMovement,
} from 'shared/utils/handle-section-item-movement';

export const useDroppableSectionControls = <
  Item extends SectionItemBase,
  Section extends SectionBase<Item>,
>({
  initialSections,
  onDragEnd,
}: {
  initialSections: Section[];
  onDragEnd: (sections: Section[], movedItem: Item) => void;
}) => {
  const [currentSections, setCurrentSections] =
    useState<Section[]>(initialSections);
  const [activeItemId, setActiveItemId] = useState<ID>();

  useEffect(() => {
    setCurrentSections(initialSections);
  }, [initialSections]);

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => setActiveItemId(active.id.toString()),
    [],
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over?.id || over.id === active.id || !activeItemId) {
        return;
      }

      setCurrentSections((oldSections) => {
        const { sections } = handleItemBetweenSectionsMovement(
          oldSections,
          active,
          over,
        );

        return sections;
      });
    },
    [activeItemId],
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveItemId(undefined);
      if (!over?.id || !activeItemId) {
        return;
      }

      const { sections, activeItem: activeTask } =
        handleItemWithinSectionMovement<Item, Section>(
          currentSections,
          active,
          over,
        );

      if (!activeTask) {
        return;
      }

      return onDragEnd?.(sections, activeTask);
    },
    [activeItemId, currentSections, onDragEnd],
  );

  const handleDragCancel = useCallback(() => {
    setActiveItemId(undefined);
    setCurrentSections(initialSections);
  }, [initialSections]);

  return {
    sections: currentSections,
    activeId: activeItemId,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
  };
};
