import { useCallback } from 'react';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import { useUpdateGoal } from './use-update-goal';

export const useDeleteGoalSection = () => {
  const { submit: updateGoal } = useUpdateGoal();

  return useCallback(
    (goal: Goal, sectionId: ID, tasks?: Task[]) => {
      updateGoal({
        id: goal.id,
        sections: goal.sections?.filter(({ id }) => id !== sectionId),
        taskCount: tasks?.length
          ? tasks.filter(({ goalId }) => goalId === goal.id).length * -1
          : 0,
      });
    },
    [updateGoal],
  );
};
