import {
  useArchiveGoal,
  useCreateUnlimitedGoalsAllowed,
  useGoals,
} from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useFavoriteIds,
  useGoalSorting,
  useToggleFavorite,
  useUpdateGoalOrder,
  useUserHadTrial,
} from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GoalDataGrid,
  SelfSortingGoalDataGrid,
} from 'shared/components/ui/goal-data-grid';
import { GridContainer } from 'shared/components/ui/grid-container';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import {
  useOpenCreateGoalDialog,
  useOpenEditGoalDialog,
} from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useToday } from 'shared/contexts/today';
import { ID } from 'shared/types/id';
import { FavoriteType } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';
import { useTheme } from 'styled-components';

import { EmptyBanner } from './components/empty-banner';
import { TabPanel } from './components/tab-panel';
import * as Styled from './overview.style';
import { GoalsFilterOptions, goalsFilters } from './utils/goals-filter';

export const ThisYear: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const today = useToday();
  const year = today.getFullYear().toString();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const goalSorting = useGoalSorting(year);
  const favoriteIds = useFavoriteIds();
  const userHadTrial = useUserHadTrial();
  const toggleFavorite = useToggleFavorite();

  const updateOrder = useUpdateGoalOrder(year);
  const openEditDialog = useOpenEditGoalDialog();
  const openCreateDialog = useOpenCreateGoalDialog();
  const openDeleteDialog = useOpenDeleteGoalDialog();
  const completeGoal = useCompleteGoal();
  const archiveGoal = useArchiveGoal();

  const openPremiumDialog = useOpenPremiumDialog();
  const createGoalAllowed = useCreateUnlimitedGoalsAllowed();

  const onEdit = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openEditDialog(goal, id);
  };
  const onComplete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && completeGoal(goal);
  };
  const onDelete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openDeleteDialog(goal);
  };
  const onArchive = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && archiveGoal(goal);
  };

  const onFavorite = (id: ID) => {
    toggleFavorite(id, FavoriteType.Goal);
  };

  const filteredGoals = useMemo(
    () =>
      sortItems(
        goalsFilters[GoalsFilterOptions.ThisYear](goals, today).filter(
          ({ parentIds }) => !parentIds?.length,
        ),
        goalSorting,
        'id',
      ),
    [goalSorting, goals, today],
  );

  const activeGoals = useMemo(
    () => filteredGoals.filter(({ frozenAt }) => !frozenAt),
    [filteredGoals],
  );
  const frozenGoals = useMemo(
    () => filteredGoals.filter(({ frozenAt }) => !!frozenAt),
    [filteredGoals],
  );

  return (
    <GridContainer>
      {!activeGoals.length ? (
        <EmptyBanner />
      ) : (
        <TabPanel role="tab-panel">
          <SelfSortingGoalDataGrid
            goals={activeGoals}
            allGoals={goals}
            lifeAreas={lifeAreas}
            favorites={favoriteIds}
            onReorder={updateOrder}
            onEditGoal={onEdit}
            onCompleteGoal={onComplete}
            onArchiveGoal={onArchive}
            onDeleteGoal={onDelete}
            onFavoriteGoal={onFavorite}
            onCreateGoal={
              !theme.isMobile && createGoalAllowed
                ? openCreateDialog
                : undefined
            }
          />
          {!createGoalAllowed && (
            <>
              <Styled.PremiumBannerContainer>
                <PremiumBanner
                  title={t('pages.goals.premium-banner.title')}
                  description={t(
                    userHadTrial
                      ? 'pages.goals.premium-banner.description'
                      : 'pages.goals.premium-banner.try-for-free.description',
                  )}
                  onClick={openPremiumDialog}
                  isTrial={!userHadTrial}
                />
              </Styled.PremiumBannerContainer>
              <GoalDataGrid
                goals={frozenGoals}
                allGoals={goals}
                lifeAreas={lifeAreas}
                favorites={favoriteIds}
                onCreateGoal={!theme.isMobile ? openPremiumDialog : undefined}
                onGoal={openPremiumDialog}
                isFrozen
                hideHeader
                showPremiumLabel
              />
            </>
          )}
        </TabPanel>
      )}
    </GridContainer>
  );
};
