import { addDays, isBefore, startOfDay } from 'date-fns';
import { useUpdateTask } from 'features/tasks';
import { useCallback, useMemo } from 'react';
import {
  DayOrder,
  useUpdateTodoDaysOrderMutation,
} from 'shared/hooks/queries/use-update-todo-days-order-mutation';
import { useUser } from 'shared/hooks/use-user';
import {
  DateSection,
  DateSectionOptions,
} from 'shared/types/orderable-section';
import { TaskCardTask } from 'shared/types/task-card-task';
import { mapTaskCardTaskToTask } from 'shared/utils/mappers/map-task-card-task-to-task';

export const useHandleDateSectionDrop = (sections: DateSection[]) => {
  const { submit: updateTask } = useUpdateTask();
  const { mutate: updateOrder } = useUpdateTodoDaysOrderMutation();
  const user = useUser();

  const oldSections = useMemo(() => structuredClone(sections), [sections]);

  return useCallback(
    (newSections: DateSection[], movedTask: TaskCardTask) => {
      const newSection = newSections.find(({ items }) =>
        items.find(({ id }) => id === movedTask.id),
      );

      const oldSection = oldSections.find(({ items }) =>
        items.find(({ id }) => id === movedTask.id),
      );

      if (!newSection || !oldSection) {
        // data error
        return;
      }

      if (
        movedTask.fields.schedules?.length &&
        newSection.id !== oldSection.id
      ) {
        // moving a habit into another date means setting a skip on the day before that date
        const schedules = structuredClone(movedTask.fields.schedules);
        const activeSchedule = schedules.find(({ endDate }) => !endDate);

        if (!activeSchedule) {
          return;
        }

        const skipDate = addDays(startOfDay(newSection.deadline), -1);

        activeSchedule.skips = [
          ...(activeSchedule.skips ?? []).filter((skip) =>
            isBefore(skip, skipDate),
          ),
          skipDate,
        ];

        updateTask({
          id: movedTask.id,
          schedules,
        });
        return;
      }

      // check whether the task has moved between sections to update the deadline
      if (newSection.deadline.getTime() !== oldSection.deadline.getTime()) {
        // update the task with the new deadline
        const updatedTask = mapTaskCardTaskToTask({
          ...movedTask,
          fields: {
            ...movedTask.fields,
            endStrategy: {
              ...movedTask.fields.endStrategy,
              deadline: newSection.deadline,
            },
            schedules: undefined,
          },
        });
        updateTask(updatedTask);
      }

      // update order if the updated or old section is one of the first 7 days
      if (
        newSection.type === DateSectionOptions.Day ||
        oldSection.type === DateSectionOptions.Day
      ) {
        const oldSectionOrderKey =
          newSection.id !== oldSection.id ? oldSection.id : undefined;
        const oldSectionOrder = user?.settings?.todoSorting?.[
          oldSectionOrderKey as string
        ]?.filter((id) => id !== movedTask.id);

        const ordersToUpdate: DayOrder[] = [
          newSection.type === DateSectionOptions.Day
            ? {
                date: newSection.deadline,
                ids: newSection.items.map(({ id }) => id),
              }
            : undefined,
          oldSectionOrder
            ? {
                date: oldSection.deadline,
                ids: oldSectionOrder,
              }
            : undefined,
        ].filter(Boolean) as DayOrder[];

        updateOrder(ordersToUpdate);
      }
    },
    [oldSections, updateTask, user?.settings?.todoSorting, updateOrder],
  );
};
