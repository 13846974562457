import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css, DefaultTheme } from 'styled-components';

import { Variants } from './types';

const getVariantTypography = (variant: Variants, theme: DefaultTheme) => {
  const variantTypographyMap: Record<Variants, TypographyStyleOptions> = {
    [Variants.Body2]: theme.typography.body2,
    [Variants.H5]: theme.typography.h5,
  };

  return variantTypographyMap[variant];
};

export const Container = styled.span<{ $fullWidth: boolean }>`
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`;

const ContentBase = css<{
  $isStrikeThrough: boolean;
  $variant: Variants;
  $isFaded: boolean;
}>`
  ${({ theme, $variant }) =>
    typographyToCss(getVariantTypography($variant, theme))};
  padding: 0;
  max-width: 100%;

  border: none;
  background: transparent;
  outline: none;

  ${({ $isStrikeThrough }) =>
    $isStrikeThrough &&
    css`
      text-decoration: line-through;
    `};

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.3;
    `};
`;

export const NameInput = styled.textarea<{
  $isStrikeThrough: boolean;
  $enabled: boolean;
  $variant: Variants;
  $isFaded: boolean;
}>`
  ${ContentBase};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  resize: none;
  overflow: hidden;

  background: transparent;
  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  ${({ $enabled }) =>
    !$enabled &&
    css`
      pointer-events: none;
    `};
`;

export const SizingSpan = styled.span<{
  $isStrikeThrough: boolean;
  $variant: Variants;
  $isFaded: boolean;
}>`
  ${ContentBase};
  display: inline-block;
  visibility: hidden;
  white-space: pre-wrap;
`;
