import React, { useMemo } from 'react';
import { Link, LinkProps, useSearchParams } from 'react-router-dom';
import { combineSearchParams } from 'shared/utils/combine-search-params';

import { getGlobalAllowedSearchParameters } from '../get-global-allowed-search-parameters';

export type QueryNavLinkProps = LinkProps & {};

export const QueryNavLink = React.forwardRef<
  HTMLAnchorElement,
  QueryNavLinkProps
>(({ to, ...rest }, ref) => {
  const [search] = useSearchParams();

  const processedTo = useMemo(() => {
    const allowedSearchParams = getGlobalAllowedSearchParameters(search);

    const toSearch = typeof to === 'object' ? to.search?.toString() : '';
    const rest = typeof to === 'object' ? to : {};
    const pathname = typeof to === 'object' ? to.pathname : to;

    const combinedSearch = combineSearchParams(
      allowedSearchParams,
      new URLSearchParams(toSearch),
    );

    return {
      ...rest,
      pathname,
      search: combinedSearch.toString(),
    };
  }, [search, to]);

  return <Link to={processedTo} {...rest} ref={ref} />;
});
