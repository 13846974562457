import React from 'react';
import { ChevronLeft, Lock } from 'shared/assets/icons';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { Icon } from 'shared/components/ui/icon';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './section-header-life-area.style';

export type SectionHeaderLifeAreaProps = {
  id: ID;
  name: string;
  image?: Image | null;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: string;
  isFrozen?: boolean;
};

export const SectionHeaderLifeArea: React.FC<SectionHeaderLifeAreaProps> = ({
  id,
  name,
  image,
  icon,
  color,
  isFrozen,
}) => (
  <Styled.Container>
    <Styled.ImageContainer $isFaded={!!isFrozen}>
      {image ? (
        <Styled.BlurImageContainer>
          <BlurHashImage hash={image.blurHash} src={image.url} />
        </Styled.BlurImageContainer>
      ) : (
        <Styled.ImageIconContainer>
          <Icon icon={icon} />
        </Styled.ImageIconContainer>
      )}
      {!!color && <Styled.ColorDot $color={color} />}
    </Styled.ImageContainer>

    <Styled.HeaderBody>
      <Styled.TitleContainer
        as={isFrozen || id === lifeAreaOtherID ? 'span' : undefined}
        to={
          isFrozen || id === lifeAreaOtherID
            ? ''
            : replaceUrlParams(Paths.LifeAreaDetail, { id })
        }
      >
        <Styled.Title $isFaded={!!isFrozen}>{name}</Styled.Title>
        <Styled.LinkIconContainer $isFaded={!!isFrozen}>
          <Icon icon={isFrozen ? Lock : ChevronLeft} />
        </Styled.LinkIconContainer>
      </Styled.TitleContainer>
    </Styled.HeaderBody>
  </Styled.Container>
);
