import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'shared/routes';
import { getGoals } from 'shared/services/backend-api';
import { User } from 'shared/types/user';

export const useNavigateAppOpenScreen = () => {
  const navigate = useNavigate();

  return useCallback(
    async (user: User | null) => {
      if (user) {
        const goals = await getGoals();
        navigate(goals.length ? Paths.Home : Paths.Goals);
      }
    },
    [navigate],
  );
};
