import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span<{ $disabled: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.45;
    `};
`;

export const Label = styled.label`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  min-width: 9.6rem;

  // align with select
  padding-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const ContentContainer = styled.span<{ $fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const SelectContainer = styled.span<{ $fullWidth: boolean }>`
  display: inline-block;
  position: relative;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const Select = styled.select<{ $hasError: boolean; $isSmall: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  margin: 0;
  padding: ${({ theme }) =>
    `${theme.spacing(1.5)} ${theme.spacing(7)}
    ${theme.spacing(1.5)} ${theme.spacing(2)}`};
  width: 100%;

  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  cursor: pointer;
  transition: border-color 200ms;

  // hide basic arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  color: inherit;

  &:invalid {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.brown['200']};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.palette.divider};
  }

  ${({ theme, $hasError }) =>
    $hasError &&
    css`
      border-color: ${theme.palette.error.main};
    `};

  ${({ $isSmall, theme }) =>
    $isSmall &&
    css`
      ${typographyToCss(theme.typography.buttonSmall)};

      padding: ${({ theme }) =>
        `${theme.spacing(0.5)} ${theme.spacing(7)}
        ${theme.spacing(0.5)} ${theme.spacing(2)}`};
    `};

  ${({ theme, $isSmall }) =>
    theme.isMobile &&
    ($isSmall
      ? css`
          padding: ${({ theme }) =>
            `${theme.spacing(1.5)} ${theme.spacing(7)}
        ${theme.spacing(1.5)} ${theme.spacing(3)}`};
        `
      : css`
          padding: ${({ theme }) =>
            `${theme.spacing(2.5)} ${theme.spacing(7)}
    ${theme.spacing(2.5)} ${theme.spacing(3)}`};
        `)};
`;

export const Option = styled.option``;

export const ChevronContainer = styled.span`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing(2)};

  transform: translateY(-50%) rotate(270deg);
  pointer-events: none;

  line-height: 62.5%;
`;
