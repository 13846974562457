import {
  useArchiveGoal,
  useCreateUnlimitedGoalsAllowed,
  useGoals,
} from 'features/goals';
import {
  filterGoalsOnLifeArea,
  useLocalizedLifeAreaById,
  useLocalizedLifeAreas,
} from 'features/life-areas';
import {
  useFavoriteIds,
  useToggleFavorite,
  useUpdateLifeAreaGoalOrder,
  useUserHadTrial,
} from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { EmptyGoals } from 'shared/components/ui/empty-goals';
import {
  GoalDataGrid,
  SelfSortingGoalDataGrid,
} from 'shared/components/ui/goal-data-grid';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import {
  useOpenCreateGoalDialog,
  useOpenEditGoalDialog,
} from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { ID } from 'shared/types/id';
import { FavoriteType } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';

import * as Styled from './goals-data-grid.style';

export const GoalsDataGrid: React.FC = () => {
  const { t } = useTranslation();
  const { id: idParam } = useParams();
  // early return not possible with hooks, so this is the way to go for now
  const id = idParam ?? '';

  const lifeArea = useLocalizedLifeAreaById(id);
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();
  const userHadTrial = useUserHadTrial();
  const favoriteIds = useFavoriteIds();
  const openPremiumDialog = useOpenPremiumDialog();
  const createGoalAllowed = useCreateUnlimitedGoalsAllowed();

  const { updateOrder } = useUpdateLifeAreaGoalOrder(lifeArea);
  const openEditDialog = useOpenEditGoalDialog();
  const openCreateDialog = useOpenCreateGoalDialog();
  const openDeleteDialog = useOpenDeleteGoalDialog();
  const completeGoal = useCompleteGoal();
  const archiveGoal = useArchiveGoal();
  const toggleFavorite = useToggleFavorite();

  const onCreate = () => {
    openCreateDialog({ lifeAreaId: id });
  };
  const onEdit = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openEditDialog(goal, id);
  };
  const onComplete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && completeGoal(goal);
  };
  const onDelete = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && openDeleteDialog(goal);
  };
  const onArchive = (id: ID) => {
    const goal = goals.find((goal) => goal.id === id);
    !!goal && archiveGoal(goal);
  };

  const onFavorite = (id: ID) => {
    toggleFavorite(id, FavoriteType.Goal);
  };

  const lifeAreaGoals = useMemo(
    () => filterGoalsOnLifeArea(goals, id),
    [goals, id],
  );

  const activeGoals = useMemo(
    () =>
      sortItems(
        lifeAreaGoals.filter(({ frozenAt }) => !frozenAt),
        lifeArea?.goalSorting ?? [],
        'id',
      ),
    [lifeAreaGoals, lifeArea?.goalSorting],
  );

  const frozenGoals = useMemo(
    () => lifeAreaGoals.filter(({ frozenAt }) => !!frozenAt),
    [lifeAreaGoals],
  );

  return lifeAreaGoals.length ? (
    <Styled.GridContainer>
      <SelfSortingGoalDataGrid
        goals={activeGoals}
        allGoals={goals}
        lifeAreas={lifeAreas}
        favorites={favoriteIds}
        onReorder={updateOrder}
        onEditGoal={onEdit}
        onCompleteGoal={onComplete}
        onArchiveGoal={onArchive}
        onDeleteGoal={onDelete}
        onCreateGoal={createGoalAllowed ? onCreate : undefined}
        onFavoriteGoal={onFavorite}
      />
      {!createGoalAllowed && (
        <>
          <Styled.PremiumBannerContainer>
            <PremiumBanner
              title={t('pages.goals.premium-banner.title')}
              description={t(
                userHadTrial
                  ? 'pages.goals.premium-banner.description'
                  : 'pages.goals.premium-banner.try-for-free.description',
              )}
              onClick={openPremiumDialog}
              isTrial={!userHadTrial}
            />
          </Styled.PremiumBannerContainer>
          <GoalDataGrid
            goals={frozenGoals}
            allGoals={goals}
            lifeAreas={lifeAreas}
            favorites={favoriteIds}
            onCreateGoal={openPremiumDialog}
            onGoal={openPremiumDialog}
            isFrozen
            hideHeader
            showPremiumLabel
          />
        </>
      )}
    </Styled.GridContainer>
  ) : (
    <Styled.EmptyContainer>
      <EmptyGoals onAdd={createGoalAllowed ? onCreate : openPremiumDialog} />
    </Styled.EmptyContainer>
  );
};
