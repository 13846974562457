import { useCreateTask, useUpdateTask } from 'features/tasks';
import { useCallback } from 'react';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';

export type Options = {
  parentIds?: ID[];
  parentChildIds?: ID[] | null;
};
export const useCreateSubTask = ({ parentIds, parentChildIds }: Options) => {
  const {
    submit: createTask,
    reset,
    retry,
    isLoading,
    isSuccess,
    error,
  } = useCreateTask();
  const { submit: updateTask } = useUpdateTask();

  const submit = useCallback(
    async (name: string) => {
      const task = await createTask({
        name,
        type: TaskType.Task,
        parentIds: parentIds || null,
        createdAt: new Date(),
        goalId: null,
        lifeAreaId: null,
        reminderTime: null,
        startDate: null,
        priority: null,
        endStrategy: null,
        schedules: null,
        hideOnTodo: null,
      });

      const parentId = parentIds?.[parentIds.length - 1];

      if (parentId) {
        updateTask({
          id: parentId,
          childIds: [...(parentChildIds ?? []), task.id],
        });
      }
    },
    [createTask, parentChildIds, parentIds, updateTask],
  );

  return {
    submit,
    reset,
    retry,
    isLoading,
    isSuccess,
    error,
  };
};
