export enum Sizes {
  ExtraLarge = 'extra-large',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  ExtraSmall = 'extra-small',
}

export enum Variants {
  Contained = 'contained',
  Outlined = 'outlined',
}
