import { rgba } from 'polished';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  container-type: inline-size;
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(6)} 0`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`0 ${theme.spacing(3)}`};
    `};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) =>
    `@container (min-width: ${theme.breakpoints.values.xs + 1}px)`} {
    flex-direction: row;
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      gap: 0;
    `};
`;

export const IllustrationContainer = styled.div<{ $hasImage: boolean }>`
  position: relative;
  display: block;

  ${({ theme, $hasImage }) =>
    theme.isMobile &&
    $hasImage &&
    css`
      display: none;
    `};
`;

export const ImageContainer = styled.div<{ $hasImage: boolean }>`
  position: relative;
  width: 8.8rem;
  height: 8.8rem;

  border-radius: ${({ theme }) => theme.spacing(3)};
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing(2)} ${theme.spacing(4)} -${theme.spacing(2)} ${rgba(theme.palette.common.black, 0.3)}`};
  overflow: hidden;

  font-size: 4rem;

  ${({ $hasImage }) =>
    !$hasImage &&
    css`
      width: 4.8rem;
      height: 4.8rem;
      box-shadow: none;
      margin-top: ${({ theme }) => theme.spacing(4)};
    `};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1.2rem;
  width: 1.2rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;

export const Content = styled.div<{ $completed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  padding-top: ${({ theme }) => theme.spacing(3.5)};

  color: ${({ theme, $completed }) =>
    $completed ? theme.palette.text.secondary : 'inherit'};
`;

export const MetaContainer = styled.div`
  display: flex;
  flex-wrap: ${({ theme }) => (theme.isMobile ? 'nowrap' : 'wrap')};
  gap: ${({ theme }) => theme.spacing(4)};
  min-width: 0;
`;

export const MetaItem = styled.span<{
  $hideMobile?: boolean;
  $noOverflow?: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme, $noOverflow }) =>
    theme.isMobile &&
    !$noOverflow &&
    css`
      min-width: 0;
      overflow: hidden;

      text-overflow: ellipsis;
    `};

  ${({ theme, $hideMobile }) =>
    theme.isMobile &&
    $hideMobile &&
    css`
      display: none;
    `};
`;

export const MetaLabel = styled.span`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 0;
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const IconContainer = styled.span`
  font-size: 1.2rem;
  line-height: 62.5%;
`;
