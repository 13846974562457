import { dragging, hovered } from 'shared/assets/styles';
import styled from 'styled-components';

export const Container = styled.div<{
  $isDragging: boolean;
  $isHovered: boolean;
}>`
  position: relative;

  ${({ $isDragging }) => $isDragging && dragging};

  ${({ $isHovered, $isDragging }) => $isHovered && !$isDragging && hovered};
`;
