import { CSS, Transform } from '@dnd-kit/utilities';
import { rgba } from 'polished';
import { dragging } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $isDisabled: boolean;
  $isDragging?: boolean;
  $transform?: Transform | null;
  $transition?: string;
}>`
  container-type: inline-size;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};

  padding: ${({ theme }) => theme.spacing(4)};

  transform: ${({ $transform }) =>
    $transform && CSS.Translate.toString($transform)};
  transition: ${({ $transition }) => `background 200ms, ${$transition}`};

  border-radius: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadowBox.card};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `};

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      ${dragging};

      &:after {
        border-radius: ${({ theme }) => theme.spacing(3)};
      }
    `};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
`;

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 3.2rem;
  width: 3.2rem;
  min-width: 3.2rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.brown['100']};

  font-size: 2rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const CompletedContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(33%, -33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ColorDot = styled.div<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  min-width: 0;
`;

export const TitleContainer = styled.div<{ $isFrozen: boolean }>`
  display: flex;
  align-items: center;

  color: ${({ theme, $isFrozen }) =>
    $isFrozen ? theme.palette.text.secondary : theme.palette.text.primary};
`;

export const Title = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ChevronContainer = styled.span`
  display: inline-block;
  transform: rotate(180deg);

  font-size: 1.6rem;
  line-height: 62.5%;
`;

export const HeaderMetaContainer = styled.div`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  min-width: 0;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ProgressContainer = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: inherit;
`;

export const MetaSpan = styled.span<{ $noEllipsis?: boolean }>`
  ${({ $noEllipsis }) =>
    !$noEllipsis &&
    css`
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const Dot = styled.span`
  display: inline-block;
  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};
  min-width: ${({ theme }) => theme.spacing(1)};
  min-height: ${({ theme }) => theme.spacing(1)};

  border-radius: 50%;
  background: ${({ theme }) => theme.palette.divider};
`;

export const StateIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 3.2rem;
  width: 3.2rem;
  min-width: 3.2rem;

  border-radius: 50%;
  background: ${({ theme }) => theme.palette.background.paper};

  font-size: 2rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const WeeksList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;

  margin: 0;
  padding: 0;

  list-style: none;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

export const WeekListItem = styled.li`
  flex: 1;
  height: 4rem;
`;

export const WeekButton = styled.button<{
  $checked: boolean;
  $isRecommended: boolean;
}>`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  border: none;
  background: ${({ theme, $checked }) =>
    $checked ? theme.palette.primary.main : theme.palette.background.paper};

  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme, $checked, $isRecommended }) =>
    $checked
      ? theme.palette.primary.contrastText
      : $isRecommended
        ? theme.palette.text.primary
        : theme.palette.brown['200']};

  &:disabled {
    color: ${({ theme }) => rgba(theme.palette.brown['200'], 0.5)};
  }
`;
