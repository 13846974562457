import {
  CollisionDetection,
  getFirstCollision,
  pointerWithin,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { useCallback, useRef } from 'react';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getOtherSectionId } from 'shared/utils/goal-section-id-helpers';

export const useCustomCollisionDetection = (
  activeId: ID | undefined,
  items: (Goal | TaskCardTask)[],
) => {
  const lastOverId = useRef<UniqueIdentifier>();

  return useCallback<CollisionDetection>(
    (args) => {
      const intersections = pointerWithin(args);

      if (!intersections.length) {
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      }

      const overId = getFirstCollision(intersections, 'id')?.toString();

      if (!overId) {
        // this basically cannot happen, as we have intersections, so there is also an id
        return intersections;
      }

      // get parents of active id
      const activeTask = items.find(
        (item) => 'fields' in item && item.id === activeId,
      ) as TaskCardTask | undefined;
      const activeGoalSection = (
        items.find(
          (item) =>
            !('fields' in item) &&
            !!item.sections?.find(({ id, tasks }) =>
              // if there is an activeTask, it means we are dragging a task
              activeTask ? tasks.includes(activeTask.id) : id === activeId,
            ),
        ) as Goal | undefined
      )?.sections?.find(({ id, tasks }) =>
        activeTask ? tasks.includes(activeTask.id) : id === activeId,
      );
      const activeGoal = items.find(
        (item) =>
          !('fields' in item) &&
          (activeTask
            ? item.id === activeTask.fields.goalId
            : activeGoalSection
              ? item.sections?.find(({ id }) => id === activeGoalSection.id)
              : item.id === activeId),
      ) as Goal | undefined;

      // see if the overId is the same as the activeSection (if it exists)
      if (activeTask && activeGoalSection && overId === activeGoalSection.id) {
        // if the parent is the same, return the lastOverId
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      }
      // see if the overId is the same as activeGoal (if it exists)
      if (activeTask && activeGoal && overId === activeGoal.id) {
        // if the parent is the same, return the lastOverId
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      }

      // see if the overId is the "other" section of the activeGoal (if it exists)
      if (
        activeTask &&
        activeGoal &&
        overId === getOtherSectionId(activeGoal.id) &&
        activeGoal.taskSorting?.includes(activeTask.id)
      ) {
        // if the parent is the same, return the lastOverId
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      }

      // if not, set the lastOverId to overId
      lastOverId.current = overId;

      return intersections;
    },
    [activeId, items],
  );
};
