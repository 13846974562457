import { Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';
import { Item } from 'shared/utils/handle-nested-sections-movement/types';

export const handleGoalSectionWithinSection = (
  activeItem: GoalSection,
  overItem: Item | GoalSection,
  items: Item[],
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  // if the over is goal, the location of the item has already been set during the moving
  if (over.data.current?.type === DraggableOptions.Goal) {
    return { items: [...items], changedItems: [] };
  }

  // a goal-section cannot move to the place of a task, so it keeps the place it had.
  if (over.data.current?.type === DraggableOptions.Task) {
    return { items: [...items], changedItems: [] };
  }

  if (over.data.current?.type === DraggableOptions.GoalSection) {
    const activeParentIndex = items.findIndex(
      (item) =>
        !('fields' in item) &&
        item.sections?.some(({ id }) => id === activeItem.id),
    );
    const activeParent = items[activeParentIndex] as Goal | undefined;

    if (!activeParent) {
      return { items, changedItems: [] };
    }

    const overSection = overItem as GoalSection;
    const sections = activeParent.sections ?? [];
    const overIndex =
      sections.findIndex(({ id }) => id === overSection.id) ?? 0;
    const activeIndex =
      sections.findIndex(({ id }) => id === activeItem.id) ?? 0;
    activeParent.sections = arrayMove(sections, activeIndex, overIndex);

    return { items: [...items], changedItems: [activeParent] };
  }

  return { items, changedItems: [] };
};
