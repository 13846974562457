import { useGoals } from 'features/goals';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { goalInboxID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskCardTask } from 'shared/types/task-card-task';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { sortItems } from 'shared/utils/sort-items';

import { useTodayTasks } from './use-today-tasks';

export const useGoalTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const tasks = useTodayTasks();
  const goals = useGoals();

  const sections = useMemo(
    () =>
      [
        // sort goals before mapping to sections
        ...sortItems(
          goals,
          user?.settings?.goalRoadmapSorting?.[
            GoalRoadmapSortingKeys.InProgress
          ] ?? [],
          'id',
          // map to sections
        ).map(({ id }) => ({
          id,
          items: getSortedTasks(
            tasks.filter(({ goalId }) => goalId === id),
            {
              today,
              weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
              mode: Array.from(
                new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
              ).filter(Boolean) as SortingMode[],
              order:
                user?.settings?.todayGroupSorting?.goal?.sorting?.[id] ?? [],
            },
          ).map((task) =>
            mapTaskToTaskCard(task, {
              today,
              goals,
              t,
              showDue: true,
              hideTodayDue: true,
              weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
            }),
          ),
        })),
        // add inbox as group as well
        {
          id: goalInboxID,
          items: getSortedTasks(
            tasks.filter(({ goalId }) => !goalId),
            {
              today,
              weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
              mode: Array.from(
                new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
              ).filter(Boolean) as SortingMode[],
              order:
                user?.settings?.todayGroupSorting?.goal?.sorting?.['null'] ??
                [],
            },
          ).map((task) =>
            mapTaskToTaskCard(task, {
              today,
              goals,
              t,
              showDue: true,
              hideTodayDue: true,
              weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
            }),
          ),
        },
        // filter out the empty sections, except inbox
      ].filter(({ id, items }) => id === goalInboxID || !!items.length),
    [
      goals,
      t,
      tasks,
      today,
      user?.settings?.goalRoadmapSorting,
      user?.settings?.startOfWeek,
      user?.settings?.todayGroupSorting?.goal?.sorting,
      user?.settings?.todoSortingMode,
    ],
  );

  const taskCardTasks = useMemo(
    () =>
      sections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [sections],
  );

  return {
    sections,
    taskCardTasks,
  };
};
