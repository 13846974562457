import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, Menu, User } from 'shared/assets/icons';
import { UserContextMenu } from 'shared/components/connected/user-context-menu';
import { Icon } from 'shared/components/ui/icon';
import { sideNavigationHoverClass } from 'shared/constants';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Image } from 'shared/types/image';

import * as Styled from './user-button.style';

export type UserButtonViewProps = {
  name: string;
  image?: Image;
  onCloseSideNavigation?: () => void;
  newVersionAvailable?: boolean;
};

export const UserButtonView: React.FC<UserButtonViewProps> = ({
  name,
  image,
  onCloseSideNavigation,
  newVersionAvailable,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const closeSideNav = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onCloseSideNavigation?.();
  };

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Button
        onClick={openMenu}
        tabIndex={0}
        $hasSideNavButton={!!onCloseSideNavigation}
      >
        <Styled.ButtonContent>
          <Styled.AvatarContainer>
            {image ? (
              <Styled.Image src={image.url} />
            ) : (
              <Styled.IconContainer>
                <Icon icon={User} />
              </Styled.IconContainer>
            )}
            {newVersionAvailable && <Styled.NewVersionBadge />}
          </Styled.AvatarContainer>

          <Styled.Name>{name}</Styled.Name>
        </Styled.ButtonContent>

        <Styled.ChevronContainer $open={menuOpen}>
          <Icon icon={ChevronLeft} />
        </Styled.ChevronContainer>

        {onCloseSideNavigation && (
          <Styled.SideNavigationButton
            className={sideNavigationHoverClass}
            onClick={closeSideNav}
            aria-label={t('general.menu.labels.close')}
          >
            <Icon icon={Menu} />
          </Styled.SideNavigationButton>
        )}
      </Styled.Button>

      {menuOpen && (
        <UserContextMenu onClose={closeMenu} referenceElement={containerRef} />
      )}
    </Styled.Container>
  );
};
