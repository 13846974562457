import { UtmParameters } from 'shared/types/utm-parameters';

export const getUrlUtmParameters = () => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);

  const utmParameterKeys = Object.values(UtmParameters);

  return utmParameterKeys.reduce<Partial<Record<UtmParameters, string>>>(
    (acc, parameter) => {
      const value = searchParams.get(parameter);
      if (value) {
        acc[parameter as UtmParameters] = value;
      }

      return acc;
    },
    {},
  );
};
