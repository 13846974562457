import { useCallback, useEffect, useRef } from 'react';
import { useDeleteTaskMutation } from 'shared/hooks/queries/use-delete-task-mutation';
import { useUpdateGoalMutation } from 'shared/hooks/queries/use-update-goal-mutation';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { getGoalDocById } from 'shared/services/backend-api';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

import { useTaskById } from './use-task-by-id';

export const useDeleteTask = (parentTaskId?: ID) => {
  const deleteTaskRef = useRef<Task>();
  const parentTask = useTaskById(parentTaskId);

  const track = useTrackEvents();
  const {
    mutate: deleteTask,
    isSuccess,
    reset,
    error,
    isPending,
  } = useDeleteTaskMutation();
  const { mutate: updateGoal } = useUpdateGoalMutation();
  const { mutate: updateTask } = useUpdateTaskMutation();

  const submit = useCallback(
    async (task: Task) => {
      deleteTaskRef.current = task;

      // filter the task that will be deleted from the parent task.
      if (parentTask) {
        updateTask({
          id: parentTask.id,
          childIds: parentTask.childIds?.filter((taskId) => taskId !== task.id),
        });
      }

      const goal = task.goalId ? await getGoalDocById(task.goalId) : undefined;
      if (goal) {
        updateGoal({
          goal: {
            id: goal.id,
            completedTaskCount: task.completedAt ? -1 : 0,
            taskCount: -1,
            sections: goal.sections?.map((section) => ({
              ...section,
              tasks: section.tasks.filter((id) => id !== task.id),
            })),
          },
        });
      }

      task.childIds?.forEach((id) => {
        deleteTask(id);
        track(ActionEvents.TaskDelete, {
          type: taskTypeToTrackingTaskType[TaskType.Task],
        });
      });

      deleteTask(task.id);
    },
    [deleteTask, parentTask, track, updateGoal, updateTask],
  );

  const retry = useCallback(() => {
    const task = deleteTaskRef.current;
    if (task) {
      deleteTask(task.id);
    }
  }, [deleteTask]);

  const resetAll = useCallback(() => {
    reset();
    deleteTaskRef.current = undefined;
  }, [reset]);

  useEffect(() => {
    if (isSuccess && deleteTaskRef.current) {
      track(ActionEvents.TaskDelete, {
        type: taskTypeToTrackingTaskType[deleteTaskRef.current.type],
      });
    }
  }, [isSuccess, track]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
