import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { Header } from './components/header';
import { MobileAddTaskButton } from './components/mobile-add-task-button';
import { TasksList } from './components/tasks-list';

export const Inbox: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.Inbox, undefined);

  return (
    <GridContainer>
      <Header />

      <TasksList />

      <MobileAddTaskButton />
    </GridContainer>
  );
};
