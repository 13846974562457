import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Section } from 'shared/components/ui/section';
import { Sizes } from 'shared/components/ui/section/types';
import { TasksList } from 'shared/components/ui/tasks-list';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { DueDisplayOptions } from 'shared/types/task-base';

import { useCompletedLifeAreaTasks } from '../../hooks/use-completed-life-area-tasks';

const displayDue = [DueDisplayOptions.Past, DueDisplayOptions.Future];

export const CompletedTasks: React.FC = () => {
  const { t } = useTranslation();
  const selectedTask = useActiveTaskId();
  const { id } = useParams();

  const { taskCardTasks } = useCompletedLifeAreaTasks(id);

  const orderedCompletedTasks = useMemo(
    () =>
      taskCardTasks.toSorted(
        (taskA, taskB) =>
          (taskB.raw.task.completedAt?.getTime() ?? 0) -
          (taskA.raw.task.completedAt?.getTime() ?? 0),
      ),
    [taskCardTasks],
  );

  return orderedCompletedTasks.length ? (
    <Section
      title={t('pages.today.completed.title')}
      titleCount={orderedCompletedTasks.length}
      size={Sizes.Small}
      canCollapse
    >
      <TasksList
        tasks={orderedCompletedTasks}
        displayDue={displayDue}
        selectedTask={selectedTask}
        hideLifeArea
        showRepeatingAsTodo
      />
    </Section>
  ) : null;
};
