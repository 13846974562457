import React from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessChart } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenGoalProgress } from 'shared/contexts/goal-progress';
import { Goal } from 'shared/types/goal';
import { countMetricOptions } from 'shared/types/metric-template';

export type UpdateProgressListProps = {
  goal: Goal;
  onClose: () => void;
};

export const UpdateProgressList: React.FC<UpdateProgressListProps> = ({
  goal,
  onClose,
}) => {
  const { t } = useTranslation();
  const openProgress = useOpenGoalProgress();

  const onUpdateProgress = (e: React.MouseEvent) => {
    e.preventDefault();
    openProgress(goal.id, goal.metric!);
    onClose();
  };

  return goal.metric?.id && !countMetricOptions.includes(goal.metric.id) ? (
    <PopupMenuList hasBottomBorder>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onUpdateProgress}
          start={<Icon icon={BusinessChart} />}
        >
          {t('context-menus.goal.labels.update-progress')}
        </PopupMenuButton>
      </PopupMenuListItem>
    </PopupMenuList>
  ) : null;
};
