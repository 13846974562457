import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Sliders } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Variants } from 'shared/components/ui/icon-button';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { SortingMode, TodayGroupBy } from 'shared/types/sorting-mode';
import { useTheme } from 'styled-components';

import { groupByOptions, sortOptions } from './header-interaction-options';
import * as Styled from './header-interactions.style';

export type HeaderInteractionsProps = {
  sort?: SortingMode;
  groupBy?: TodayGroupBy;
  onSort: (sort: SortingMode) => void;
  onGroupBy: (groupBy: TodayGroupBy) => void;
};

export const HeaderInteractions: React.FC<HeaderInteractionsProps> = ({
  sort,
  groupBy,
  onSort,
  onGroupBy,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const { t } = useTranslation();

  const _onGroupBy = (value: TodayGroupBy) => {
    onGroupBy(value);
    closeMenu();
  };

  const _onSort = (value: SortingMode) => {
    onSort(value);
    closeMenu();
  };

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <IconButton
        icon={Sliders}
        onClick={openMenu}
        variant={theme.isMobile ? Variants.Outlined : Variants.Contained}
      />

      {menuOpen && (
        <PopupMenu referenceElement={containerRef} position="bottom-end">
          <PopupMenuList hasBottomBorder>
            <PopupMenuTitle title={t('pages.today.group-by.title')} />

            {groupByOptions.map(({ id, label, value }) => (
              <PopupMenuListItem key={id}>
                <PopupMenuButton
                  start={groupBy === id ? <Icon icon={Check} /> : undefined}
                  onClick={() => _onGroupBy(value)}
                  hasIndentation={groupBy !== id}
                >
                  {t(label)}
                </PopupMenuButton>
              </PopupMenuListItem>
            ))}
          </PopupMenuList>
          <PopupMenuList>
            <PopupMenuTitle title={t('pages.today.sort.title')} />

            {sortOptions.map(({ id, label, value }) => (
              <PopupMenuListItem key={id}>
                <PopupMenuButton
                  start={sort === id ? <Icon icon={Check} /> : undefined}
                  onClick={() => _onSort(value)}
                  hasIndentation={sort !== id}
                >
                  {t(label)}
                </PopupMenuButton>
              </PopupMenuListItem>
            ))}
          </PopupMenuList>
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
