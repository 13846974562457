import React, { forwardRef } from 'react';
import { ResizableTextArea } from 'shared/components/ui/resizable-text-area';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

export type TaskCardTitleProps = {
  taskId: ID;
  value: string;
  isEditing?: boolean;
  completed?: boolean;
  placeholder?: string;
  onClick?: () => void;
  onChange: (updateTask: Pick<Task, 'id' | 'name'>) => void;
  onFocus?: (e: React.FocusEvent) => void;
  fullWidth?: boolean;
};

export const TaskCardTitle = forwardRef<
  HTMLTextAreaElement,
  TaskCardTitleProps
>(
  (
    {
      taskId,
      value,
      isEditing,
      completed,
      placeholder,
      onClick,
      onChange,
      onFocus,
      fullWidth,
    },
    ref,
  ) => {
    const onChangeTitle = (name: string) => {
      onChange({ id: taskId, name });
    };

    return (
      <ResizableTextArea
        ref={ref}
        value={value}
        placeholder={placeholder}
        onChange={onChangeTitle}
        onClick={onClick}
        onFocus={onFocus}
        isStrikeThrough={!isEditing && !!completed}
        disabled={!isEditing}
        fullWidth={fullWidth}
      />
    );
  },
);
