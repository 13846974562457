import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from 'shared/components/connected/sub-tasks-list/context-menu';
import { Checkbox } from 'shared/components/ui/checkbox';
import { ResizableTextArea } from 'shared/components/ui/resizable-text-area';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './sub-task-card.style';

const SortableContainer: React.FC<{ id: ID; children: React.ReactNode }> = ({
  id,
  children,
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
  };

  return (
    <Styled.SortableContainer
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      style={style}
      $isDragging={isDragging}
    >
      {children}
    </Styled.SortableContainer>
  );
};

export type SubTaskCardProps = {
  task: Task;
  onClick?: (id: ID) => void;
  onChange?: (changes: {
    id: ID;
    name?: string;
    completedAt?: Timestamp | null;
  }) => void;
  onUnfocus?: (id: ID) => void;
  isSelected?: boolean;
  isFaded?: boolean;
  isDisabled?: boolean;
};

export const SubTaskCard: React.FC<SubTaskCardProps> = ({
  task,
  onClick,
  onChange,
  onUnfocus,
  isSelected,
  isFaded,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const nameInputRef = useRef<HTMLTextAreaElement>(null);
  const [updatedName, setUpdatedName] = useState(task.name);

  const _onClick = () => onClick?.(task.id);

  const onChangeCompleted = () =>
    onChange?.({
      id: task.id,
      completedAt: task.completedAt ? null : new Date(),
    });

  const _onChange = () => {
    nameInputRef.current?.blur();

    updatedName &&
      updatedName !== task.name &&
      onChange?.({ id: task.id, name: updatedName });

    onUnfocus?.(task.id);
  };

  useKeysDown(['Enter'], (e) => {
    if (document.activeElement === nameInputRef.current) {
      e.preventDefault();

      _onChange();
    }
  });

  useClickOutside(containerRef, _onChange);

  useEffect(() => {
    setUpdatedName(task.name);
  }, [task.name]);

  return (
    <SortableContainer id={task.id}>
      <ContextMenu task={task}>
        <Styled.Container
          ref={containerRef}
          onClick={_onClick}
          $isSelected={!!isSelected}
          $isDisabled={!!isDisabled}
        >
          <Styled.CheckboxContainer $isFaded={!!isFaded}>
            <Checkbox
              checked={!!task.completedAt}
              onChange={onChangeCompleted}
            />
          </Styled.CheckboxContainer>

          <Styled.TitleContainer $isFaded={!!isFaded}>
            <ResizableTextArea
              ref={nameInputRef}
              value={updatedName}
              onChange={setUpdatedName}
              placeholder={t('forms.new-task.title.placeholder')}
            />
          </Styled.TitleContainer>
        </Styled.Container>
      </ContextMenu>
    </SortableContainer>
  );
};
