import { InboxCount, LoadingInboxCount } from 'pages/inbox';
import { LoadingTodayCount, TodayCount } from 'pages/today';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/ui/icon';
import { NavigationSectionLayout } from 'shared/components/ui/navigation-section-layout';
import { Paths } from 'shared/routes';
import {
  MainNavigationItemId,
  SubNavigationItem,
} from 'shared/routes/navigation';

import * as Styled from './side-navigation.style';

type SubItemProps = SubNavigationItem & {
  activePath?: Paths;
  onClick: () => void;
};

export const SubItem: React.FC<SubItemProps> = ({
  link,
  id,
  pathsToMatch,
  comingSoon,
  icon,
  onClick,
  activePath,
  translationLabel,
}) => {
  const { t } = useTranslation();
  const Container = link ? Styled.Link : Styled.DisabledLink;

  const active = !!activePath && !!pathsToMatch?.includes(activePath);

  return (
    <Container to={link!} $active={active} onClick={onClick}>
      {!!icon && (
        <Styled.SubItemIcon $active={active}>
          <Icon icon={icon} />
        </Styled.SubItemIcon>
      )}

      <Styled.LinkTitle>{t(translationLabel)}</Styled.LinkTitle>
      {comingSoon && (
        <Styled.ComingSoon>{t('general.labels.coming-soon')}</Styled.ComingSoon>
      )}

      {id === MainNavigationItemId.Today && (
        <Suspense fallback={<LoadingTodayCount />}>
          <TodayCount />
        </Suspense>
      )}
      {id === MainNavigationItemId.Inbox && (
        <Suspense fallback={<LoadingInboxCount />}>
          <InboxCount />
        </Suspense>
      )}
    </Container>
  );
};

export type NavigationSectionProps = {
  translationLabel: string;
  initialOpen?: boolean;
  subItems: SubNavigationItem[];
  onNavigationItem: () => void;
  activePath?: Paths;
  isFirst?: boolean;
};

export const NavigationSection: React.FC<NavigationSectionProps> = ({
  translationLabel,
  initialOpen,
  subItems,
  onNavigationItem,
  activePath,
  isFirst,
}) => {
  const { t } = useTranslation();

  return (
    <NavigationSectionLayout
      label={t(translationLabel)}
      isFirst={isFirst}
      initialOpen={initialOpen}
    >
      <Styled.SubNavigationList>
        {subItems.map((subItem) => (
          <Styled.SubNavigationListItem key={subItem.id}>
            <SubItem
              id={subItem.id}
              link={subItem.link}
              activePath={activePath}
              pathsToMatch={subItem.pathsToMatch}
              translationLabel={subItem.translationLabel}
              icon={subItem.icon}
              comingSoon={subItem.comingSoon}
              onClick={onNavigationItem}
            />
          </Styled.SubNavigationListItem>
        ))}
      </Styled.SubNavigationList>
    </NavigationSectionLayout>
  );
};
