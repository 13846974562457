import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/components/connected/page-header';
import { useDelayedCallback } from 'shared/hooks/use-delayed-callback';

import { TemplateSearch } from '../template-search';

export type HeaderProps = {
  onSearch: (search: string) => void;
};

export const Header: React.FC<HeaderProps> = ({ onSearch }) => {
  const { t } = useTranslation();

  const onChangeSearch = useDelayedCallback(onSearch, 500);

  return (
    <PageHeader
      title={t('pages.goal-templates.title')}
      description={t('pages.goal-templates.description')}
      interactions={<TemplateSearch onChange={onChangeSearch} />}
    />
  );
};
