import { doc, getDoc } from 'firebase/firestore';
import { getRollbar } from 'shared/services/error-tracking';
import { User } from 'shared/types/user';

import { userConverter } from './converters/user';
import { CollectionOptions, getAuthentication, getDatabase } from './helpers';

export const getUserRef = async () => {
  const auth = await getAuthentication();

  if (!auth.currentUser?.uid) {
    return;
  }

  // set user uid for error logging to track errors for people
  getRollbar().configure({
    payload: {
      user: {
        id: auth.currentUser.uid,
      },
    },
  });

  return doc(
    getDatabase(),
    CollectionOptions.Users,
    auth.currentUser?.uid!,
  ).withConverter(userConverter);
};

export const getUser = async () => {
  const auth = await getAuthentication();

  if (!auth.currentUser) {
    return null;
  }

  const userRef = await getUserRef();
  if (!userRef) {
    return null;
  }

  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) {
    return null;
  }

  let user = docSnap.data() as User;
  user.isSiwa = auth.currentUser.providerId === 'apple.com';
  user.email = auth.currentUser.email as string;

  return user;
};
