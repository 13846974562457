import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { GoalRoadmapSortingOptions } from 'shared/types/user-settings';

export const useGoalSorting = (sortingOption: GoalRoadmapSortingOptions) => {
  const user = useUser();

  return useMemo(
    () => user?.settings?.goalRoadmapSorting?.[sortingOption] ?? [],
    [user, sortingOption],
  );
};
