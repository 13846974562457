import { useQuery } from '@tanstack/react-query';
import { getGoalTemplateCategories } from 'shared/services/backend-api';

export const queryKey = 'goal-template-categories';

export const useGoalTemplateCategoriesQuery = () =>
  useQuery({
    queryKey: [queryKey],
    queryFn: () => getGoalTemplateCategories(),
  });
