import { Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';
import { TaskCardTask } from 'shared/types/task-card-task';

import { Item } from './types';

export const handleTaskWithinSection = (
  activeItem: TaskCardTask,
  overItem: Item | GoalSection,
  items: Item[],
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  // if the over is goal, the location of the item has already been set during the moving
  if (over.data.current?.type === DraggableOptions.Goal) {
    return { items: [...items], changedItems: [] };
  }

  // if the over is goal, the location of the item has already been set during the moving
  if (over.data.current?.type === DraggableOptions.GoalSection) {
    return { items: [...items], changedItems: [] };
  }

  if (over.data.current?.type === DraggableOptions.Task) {
    const activeParentIndex = items.findIndex(
      ({ id }) => id === activeItem.fields.goalId,
    );
    const activeParent = items[activeParentIndex] as Goal | undefined;

    if (!activeParent) {
      return { items, changedItems: [] };
    }

    const activeSection = activeParent.sections?.find(({ tasks }) =>
      tasks.includes(activeItem.id),
    );

    const overTask = overItem as TaskCardTask;

    if (activeSection) {
      const taskSorting = activeSection.tasks;
      const overIndex = taskSorting.findIndex((id) => id === overTask.id) ?? 0;
      const activeIndex =
        taskSorting.findIndex((id) => id === activeItem.id) ?? 0;
      activeSection.tasks = arrayMove(taskSorting, activeIndex, overIndex);

      return { items: [...items], changedItems: [activeParent] };
    }

    const taskSorting = activeParent.taskSorting ?? [];
    const overIndex = taskSorting.findIndex((id) => id === overTask.id) ?? 0;
    const activeIndex =
      taskSorting.findIndex((id) => id === activeItem.id) ?? 0;
    activeParent.taskSorting = arrayMove(taskSorting, activeIndex, overIndex);

    return { items: [...items], changedItems: [activeParent] };
  }

  return { items, changedItems: [] };
};
