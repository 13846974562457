import { useCreateSection, useCreateSubGoalAllowed } from 'features/goals';
import { useCreateHabitAllowed } from 'features/tasks';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Repeat, Section, Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { ID } from 'shared/types/id';

export type ActionPlanContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  onClose?: () => void;
  parentIds: ID[];
  lifeAreaId?: ID | null;
  id?: ID;
  newTaskRef?: React.RefObject<HTMLTextAreaElement>;
};

export const ActionPlanContextMenu: React.FC<ActionPlanContextMenuProps> = ({
  referenceElement,
  location,
  position,
  onClose,
  parentIds,
  lifeAreaId,
  id,
  newTaskRef,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const isCreateHabitAllowed = useCreateHabitAllowed();
  const isCreateSubGoalsAllowed = useCreateSubGoalAllowed();
  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateGoalDialog = useOpenCreateGoalDialog();
  const openCreateHabitDialog = useOpenCreateHabitDialog();
  const createSection = useCreateSection(id);

  const close = () => onClose?.();

  const onCreateTask = (e: React.MouseEvent) => {
    e.preventDefault();
    newTaskRef?.current?.click();
    close();
  };

  const onCreateHabit = () => {
    openCreateHabitDialog({ goalId: id });
    close();
  };

  const openCreateGoal = () => {
    openCreateGoalDialog({ parentIds, lifeAreaId });
    close();
  };

  const onCreateSection = () => {
    createSection();
    close();
  };

  useClickOutside(containerRef, close);

  return (
    <PopupMenu
      ref={containerRef}
      referenceElement={referenceElement}
      location={location}
      position={position}
    >
      <PopupMenuList>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onCreateTask} start={<Icon icon={Check} />}>
            {t('context-menus.goal.action-plan.labels.add-task')}
          </PopupMenuButton>
        </PopupMenuListItem>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={isCreateHabitAllowed ? onCreateHabit : openPremiumDialog}
            isPremium={!isCreateHabitAllowed}
            start={<Icon icon={Repeat} />}
          >
            {t('context-menus.goal.action-plan.labels.add-habit')}
          </PopupMenuButton>
        </PopupMenuListItem>
        {!(parentIds.length > 1) && (
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={
                isCreateSubGoalsAllowed ? openCreateGoal : openPremiumDialog
              }
              isPremium={!isCreateSubGoalsAllowed}
              start={<Icon icon={Target} />}
            >
              {t('context-menus.goal.action-plan.labels.add-sub-goal')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onCreateSection}
            start={<Icon icon={Section} />}
          >
            {t('context-menus.goal.action-plan.labels.add-section')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>
    </PopupMenu>
  );
};
