import { httpsCallable } from 'firebase/functions';
import { UtmParameters } from 'shared/types/utm-parameters';

import { getFunctions } from './helpers';

export type UtmValues = Record<UtmParameters, string>;

export const setUserUtmParameters = async (parameters: Partial<UtmValues>) => {
  const functions = getFunctions();
  const send = httpsCallable<Partial<UtmValues>>(
    functions,
    'users-setUtmParametersOnUser',
  );

  await send(parameters);
};
