import React from 'react';

import { DesktopPageHeader } from './desktop-page-header';
import { MobilePageHeader } from './mobile-page-header';

export type PageHeaderProps = React.ComponentProps<typeof DesktopPageHeader> &
  React.ComponentProps<typeof MobilePageHeader>;

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  interactions,
  mobileNavigation,
  activePath,
}) => (
  <>
    <DesktopPageHeader
      title={title}
      description={description}
      interactions={interactions}
    />
    <MobilePageHeader
      title={title}
      interactions={interactions}
      mobileNavigation={mobileNavigation}
      activePath={activePath}
    />
  </>
);
