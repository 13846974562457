import React from 'react';
import { To } from 'react-router';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './navigation-item.style';

export type NavigationItemProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  to: To;
  isSelected?: boolean;
};

export const NavigationItem: React.FC<NavigationItemProps> = ({
  icon,
  label,
  to,
  isSelected,
}) => (
  <Styled.Container to={to} $isSelected={!!isSelected}>
    <Styled.IconContainer>
      <Icon icon={icon} />
    </Styled.IconContainer>
    <Styled.Label>{label}</Styled.Label>
  </Styled.Container>
);
