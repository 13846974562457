export enum UtmParameters {
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  UtmTerm = 'utm_term',
  InitialUtmSource = 'initial_utm_source',
  InitialUtmCampaign = 'initial_utm_campaign',
  InitialUtmMedium = 'initial_utm_medium',
  InitialUtmTerm = 'initial_utm_term',
  InitialUtmContent = 'initial_utm_content',
}
