import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(2)} ${theme.spacing(3)} 0`};
    `};
`;

export const Badge = styled.span<{ $active: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  display: inline-block;

  border-radius: ${({ theme }) => theme.spacing(1.5)};
`;
