import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};

  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  color: ${({ theme }) => theme.palette.grey['500']};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(2)} ${theme.spacing(1)} 0`};
    `};
`;

export const Interactions = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const FavoriteButtonContainer = styled.div<{ $isFavorite: boolean }>`
  ${({ theme, $isFavorite }) =>
    $isFavorite &&
    css`
      color: ${theme.palette.warning.main};
      fill: ${theme.palette.warning.main};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;
