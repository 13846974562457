import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.span<{ $hasValue: boolean }>`
  display: flex;
  align-items: center;

  min-width: 0;
  //overflow: hidden;
  //
  //text-overflow: ellipsis;
  //white-space: nowrap;

  color: ${({ theme, $hasValue }) =>
    $hasValue ? theme.palette.text.primary : theme.palette.text.secondary};
`;

export const IconContainer = styled.span`
  margin-right: ${({ theme }) => theme.spacing(1.5)};

  line-height: 62.5%;
`;

export const Label = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  min-width: 0;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ChevronContainer = styled.span`
  transform: rotate(180deg);

  line-height: 62.5%;
`;
