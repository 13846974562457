import { useUpdateTodayGroupBy, useUpdateTodoSortMode } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/components/connected/page-header';
import { useActivePath } from 'shared/hooks/use-active-path';
import { useUser } from 'shared/hooks/use-user';
import { mobileTodoNavigation } from 'shared/routes/navigation';

import { HeaderInteractions } from '../header-interactions';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const setSortMode = useUpdateTodoSortMode();
  const setGroupBy = useUpdateTodayGroupBy();
  const user = useUser();
  const activePath = useActivePath({ level: 2 });

  return (
    <PageHeader
      title={t('pages.today.title')}
      interactions={
        <HeaderInteractions
          sort={user?.settings?.todoSortingMode}
          groupBy={user?.settings?.todayGroupBy}
          onSort={setSortMode}
          onGroupBy={setGroupBy}
        />
      }
      mobileNavigation={mobileTodoNavigation}
      activePath={activePath}
    />
  );
};
