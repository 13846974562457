import { useDeleteGoalSection } from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trash2 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenDeleteGoalSectionDialog } from 'shared/contexts/delete-goal-section';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';

export type ManageListProps = {
  goal: Goal;
  sectionId: ID;
  onClose: () => void;
};

export const ManageList: React.FC<ManageListProps> = ({
  goal,
  sectionId,
  onClose,
}) => {
  const { t } = useTranslation();
  const deleteGoalSection = useDeleteGoalSection();
  const openDeleteSectionDialog = useOpenDeleteGoalSectionDialog();

  const onDeleteSection = () => {
    const section = goal.sections?.find((section) => section.id === sectionId);

    if (!section) {
      onClose();
      return;
    }

    if (!section.tasks?.length) {
      // delete section right away
      deleteGoalSection(goal, sectionId);
      onClose();
      return;
    }

    // open section delete dialog
    openDeleteSectionDialog(goal, sectionId);
    onClose();
  };

  return (
    <PopupMenuList>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onDeleteSection}
          start={<Icon icon={Trash2} />}
        >
          {t('context-menus.goal.section.labels.delete')}
        </PopupMenuButton>
      </PopupMenuListItem>
    </PopupMenuList>
  );
};
