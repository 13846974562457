import { useCallback } from 'react';
import { useUpdateGoalOrderMutation } from 'shared/hooks/queries/use-update-goal-order-mutation';
import { ID } from 'shared/types/id';
import { GoalRoadmapSortingOptions } from 'shared/types/user-settings';

export const useUpdateGoalOrder = (option: GoalRoadmapSortingOptions) => {
  const { mutate: updateOrder } = useUpdateGoalOrderMutation();

  return useCallback(
    (newOrder: ID[]) => {
      updateOrder({ newOrder, option });
    },
    [option, updateOrder],
  );
};
