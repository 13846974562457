import styled from 'styled-components';

export const Container = styled.div``;

export const Illustration = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;

  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
  min-height: 3.2rem;

  border-radius: 50%;
  border: none;
  background: ${({ theme }) => theme.palette.brown['100']};

  font-size: 1.6rem;
  color: ${({ theme }) => theme.palette.brown['200']};
`;

export const IconContainer = styled.span``;
