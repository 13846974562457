import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

export const CheckboxContainer = styled.span<{ $isFaded?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacing(0.25)};
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;

  line-height: 62.5%;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.grey['400']};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 3.2rem;
      height: 3.2rem;
      min-width: 3.2rem;
      min-height: 3.2rem;
    `};

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.3;
    `};
`;

export const CheckboxCheckContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(-33%, 33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const HeaderContent = styled.div<{ $isFaded?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.75)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  min-width: 0;

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.5;
    `}

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-top: ${({ theme }) => theme.spacing(1.5)};
      pointer-events: none;
    `};
`;

export const TitleContainer = styled.span<{ $isFaded: boolean }>`
  flex: 1;
  line-height: 1;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  overflow: hidden;

  color: ${({ theme }) => theme.palette.text.secondary};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
