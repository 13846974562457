import { useCallback, useMemo } from 'react';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useUpdateTodayGoalTaskOrderMutation } from 'shared/hooks/queries/use-update-today-goal-task-order-mutation';
import { goalInboxID, SectionBase } from 'shared/types/orderable-section';
import { TaskCardTask } from 'shared/types/task-card-task';

export const useGoalTodayReorder = (sections: SectionBase<TaskCardTask>[]) => {
  const { mutate: updateGoalSorting } = useUpdateTodayGoalTaskOrderMutation();
  const { mutate: updateTask } = useUpdateTaskMutation();

  const oldSections = useMemo(() => structuredClone(sections), [sections]);

  return useCallback(
    (newSections: SectionBase<TaskCardTask>[], movedItem: TaskCardTask) => {
      const newSection = newSections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );
      const oldSection = oldSections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );

      if (!newSection || !oldSection) {
        return;
      }

      const goalsToUpdate = [
        {
          goalId: newSection.id,
          taskIds: newSection.items.map(({ id }) => id),
        },
      ];

      // if the section changed, get the new oldSection variant and add it to the goalsToUpdate list
      if (newSection.id !== oldSection.id) {
        const newOldSection = newSections.find(
          ({ id }) => id === oldSection.id,
        );

        if (!newOldSection) {
          // should not be possible, just check for type-safety
          return;
        }

        goalsToUpdate.push({
          goalId: newOldSection.id,
          taskIds: newOldSection.items.map(({ id }) => id),
        });

        updateTask({
          id: movedItem.id,
          goalId: newSection.id === goalInboxID ? null : newSection.id,
        });
      }

      updateGoalSorting(goalsToUpdate);
    },
    [oldSections, updateGoalSorting, updateTask],
  );
};
