import { useCallback } from 'react';
import { useLoginUserWithAppleMutation } from 'shared/hooks/queries/use-login-user-with-apple-mutation';
import { useNavigateAppOpenScreen } from 'shared/hooks/use-navigate-app-open-screen';

export const useLoginWithApple = () => {
  const navigateAppOpen = useNavigateAppOpenScreen();
  const { mutateAsync, error, reset, isPending } =
    useLoginUserWithAppleMutation();

  const login = useCallback(async () => {
    await mutateAsync();
    await navigateAppOpen(null);
  }, [mutateAsync, navigateAppOpen]);

  return { login, error, reset, isLoading: isPending };
};
