import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, Inbox } from 'shared/assets/icons';
import { GoalHeader as GoalHeaderUI } from 'shared/components/ui/goal-header';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { getGoalColor } from 'shared/utils/get-goal-color';

export type GoalHeaderProps = {
  goalId?: ID;
  goals: Goal[];
  lifeAreas: LifeArea[];
  isLink?: boolean;
  isFrozen?: boolean;
};

export const GoalHeader: React.FC<GoalHeaderProps> = React.memo(
  ({ goalId, goals, lifeAreas, isLink, isFrozen }) => {
    const { t } = useTranslation();
    const goal = useMemo(
      () => goals.find(({ id }) => id === goalId),
      [goals, goalId],
    );

    const goalColor = useMemo(
      () =>
        goal?.id
          ? getGoalColor(goal.id, { lifeAreas, goals }) ?? undefined
          : undefined,
      [goal?.id, goals, lifeAreas],
    );

    const goalName = !goalId
      ? t('general.sections.inbox.title')
      : goal?.name ?? '';

    return (
      <GoalHeaderUI
        id={goalId}
        name={goalName}
        image={goal?.image}
        imagePlaceholderIcon={
          !goalId
            ? Inbox
            : goal?.iconName
              ? IconFormIcons[goal.iconName]
              : undefined
        }
        color={goalColor}
        isLink={isLink}
        isFrozen={isFrozen}
      />
    );
  },
);
