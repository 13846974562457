import { useQuery } from '@tanstack/react-query';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { GetGoalsOptions, getGoalsQuery } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'goals';

export const useGoalsQuery = (
  uid?: ID,
  options: GetGoalsOptions = {},
  enabled = true,
) =>
  useQuery({
    queryKey: [
      queryKey,
      uid ?? '',
      ...Object.entries(options)
        .filter(([, value]) => value !== undefined)
        .map(
          ([key, value]) =>
            `${key}: ${
              value instanceof Date
                ? value.getTime().toString()
                : Array.isArray(value)
                  ? value.toSorted().join(',')
                  : value?.toString()
            }`,
        ),
    ],
    queryFn: createSubscriptionQuery([() => getGoalsQuery(options)]),
    enabled: !!uid && enabled,
  });

export type { GetGoalsOptions };
