import styled from 'styled-components';

export const Button = styled.button`
  margin: 0;
  padding: ${({ theme }) => theme.spacing(3)};

  border: none;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;

  font-size: 3.2rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
