import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { useGoals } from 'features/goals';
import { filterGoalsOnLifeArea } from 'features/life-areas';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useActivePath } from 'shared/hooks/use-active-path';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import { useLifeAreaTasks } from '../../hooks/use-life-area-tasks';
import * as Styled from './detail-navigation-tabs.style';

type NavigationPaths =
  | Paths.LifeAreaDetail
  | Paths.LifeAreaDetailGoals
  | Paths.LifeAreaDetailTasks;

const navigationTabs: { label: string; path: NavigationPaths }[] = [
  {
    label: 'pages.life-area-detail.tabs.vision.label',
    path: Paths.LifeAreaDetail,
  },
  {
    label: 'pages.life-area-detail.tabs.goals.label',
    path: Paths.LifeAreaDetailGoals,
  },
  {
    label: 'pages.life-area-detail.tabs.tasks.label',
    path: Paths.LifeAreaDetailTasks,
  },
];

export const DetailNavigationTabs: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath();
  const { id } = useParams();
  const { taskCardTasks } = useLifeAreaTasks(id);
  const goals = useGoals();

  const activeIndex = useMemo(
    () => navigationTabs.findIndex(({ path }) => path === activePath),
    [activePath],
  );

  const navigationNumbers = useMemo<
    Record<NavigationPaths, number | undefined>
  >(
    () => ({
      [Paths.LifeAreaDetail]: undefined,
      [Paths.LifeAreaDetailGoals]: filterGoalsOnLifeArea(goals, id!).length,
      [Paths.LifeAreaDetailTasks]: taskCardTasks.length,
    }),
    [goals, id, taskCardTasks.length],
  );

  if (!id) {
    return null;
  }

  return (
    <Styled.Container>
      <MuiTabs value={activeIndex} variant="scrollable" scrollButtons={false}>
        {navigationTabs.map(({ label, path }) => (
          <MuiTab
            key={path}
            component={QueryNavLink}
            to={replaceUrlParams(path, { id })}
            label={t(label)}
            icon={
              navigationNumbers[path] ? (
                <Styled.Badge $active={activePath === path}>
                  {navigationNumbers[path]}
                </Styled.Badge>
              ) : undefined
            }
            iconPosition="end"
            replace
          />
        ))}
      </MuiTabs>
    </Styled.Container>
  );
};
