import {
  AuthError,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { Locales } from 'shared/assets/localization';
import { setUserUtmParameters } from 'shared/services/backend-api/set-user-utm-parameters';
import { getRollbar } from 'shared/services/error-tracking';
import { SignupErrorTypes } from 'shared/types/signup-form';
import { getUrlUtmParameters } from 'shared/utils/get-url-utm-parameters';

import { getAuthentication } from './helpers';
import { setUserCountry } from './set-user-country';
import { updateUser } from './update-user';

export const signupUser = async (
  email: string,
  password: string,
  name?: string,
  locale?: Locales,
) => {
  const auth = await getAuthentication();

  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    // todo: electron should be added to the source as well, once implemented
    await updateUser({
      name,
      language: locale,
      metaSource: 'web_app',
      notifications: {
        taskReminder: { desktop: true, mobile: true },
      },
    });

    try {
      await setUserCountry();
    } catch (e) {
      getRollbar().error('Failed setting user country', e as Error);
    }

    try {
      const parameters = getUrlUtmParameters();
      await setUserUtmParameters(parameters);
    } catch (e) {
      getRollbar().error('Failed setting user utm parameters', e as Error);
    }

    return userCredential.user.uid;
  } catch (e) {
    if ((e as AuthError).message.includes(AuthErrorCodes.EMAIL_EXISTS)) {
      throw SignupErrorTypes.EmailTaken;
    }

    switch ((e as AuthError).code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        throw SignupErrorTypes.EmailTaken;
      default:
        throw SignupErrorTypes.Unknown;
    }
  }
};
