import { endOfDay } from 'date-fns';
import { FirestoreDataConverter } from 'firebase/firestore';
import { IconFormNames } from 'shared/assets/icons';
import { NewHabit } from 'shared/types/habit';
import { FirebaseReturnSchedule } from 'shared/types/habit-schedule';
import { NewTask, Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const taskConverter: FirestoreDataConverter<Task> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();

    const parsedData = {
      completedAt: firebaseTimestampToDate(data.completedAt),
      createdAt: firebaseTimestampToDate(data.createdAt) ?? new Date(),
      frozenAt: firebaseTimestampToDate(data.frozenAt),
      startDate: firebaseTimestampToDate(
        data.startDate ? data.startDate : data.createdAt,
      ),
      reminderTime: data.reminderTime ?? null,
      endStrategy: {
        deadline: firebaseTimestampToDate(data.endStrategy?.deadline),
        completionCount: data.endStrategy?.completionCount ?? null,
      },
      iconName: data.iconName ?? null,
      schedules: data.schedules,
    };

    if ([TaskType.Habit, TaskType.Repeating].includes(data.type)) {
      parsedData.iconName = data.iconName ?? IconFormNames.Repeat;
      parsedData.schedules = (data.schedules as FirebaseReturnSchedule[])?.map(
        (schedule) => ({
          ...schedule,
          startDate: firebaseTimestampToDate(schedule.startDate),
          endDate: firebaseTimestampToDate(schedule.endDate),
          completions:
            schedule.completions?.map((date) =>
              firebaseTimestampToDate(date),
            ) ?? [],
          skips:
            schedule.skips?.map((date) => firebaseTimestampToDate(date)) ?? [],
        }),
      );
    }

    return {
      ...data,
      ...parsedData,
    } as Task;
  },
  toFirestore: (task) => task,
};

export const newTaskConverter = (task: NewTask) => {
  const deadline = task.endStrategy?.deadline;

  return {
    ...task,
    iconName: (task as NewHabit).iconName ?? null,
    createdAt: new Date(),
    startDate: task.startDate ?? new Date(),
    duration: null,
    frozenAt: null,
    completedAt: null,
    archivedAt: null,
    goalId: task.goalId ?? null,
    lifeAreaId: task.lifeAreaId ?? null,
    reminderTime: task.reminderTime ?? null,
    endStrategy: {
      deadline: deadline ? endOfDay(deadline) : null,
      completionCount: task.endStrategy?.completionCount ?? null,
    },
    schedules: task.schedules?.length ? task.schedules : null,
  };
};
