import { Active, Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { ID } from 'shared/types/id';

type ItemBase = {
  id: ID;
};

type SectionBase<Item extends ItemBase> = {
  id: ID;
  items: Item[];
  // this means that it does not sort the items, just adds it to the bottom of the section
  isDroppableOnly?: boolean;
};

export const handleItemBetweenSectionsMovement = <
  Item extends ItemBase,
  Section extends SectionBase<Item>,
>(
  sections: Section[],
  active: Active,
  over: Over,
): { sections: Section[] } => {
  const activeSectionIndex = sections.findIndex((section) =>
    section.items.find((item) => item.id === active.id),
  );
  const activeSection = sections[activeSectionIndex];
  const overSectionIndex = sections.findIndex(
    (section) =>
      section.id === over.id ||
      section.items.find((item) => item.id === over.id),
  );
  const overSection = sections[overSectionIndex];

  // don't do anything if no containers found or if within the same container. Movement inside the container is handled elsewhere
  if (!activeSection || !overSection || activeSection.id === overSection.id) {
    return { sections };
  }
  const activeItem = activeSection.items.find((item) => item.id === active.id);

  // if the activeItem is not found, no changes can be done
  if (!activeItem) {
    return { sections };
  }

  const activeComesFromTop = activeSectionIndex < overSectionIndex;

  // based on the order of the sections, add the item to the bottom or to the top
  let newIndex = activeComesFromTop ? 0 : overSection.items.length;

  // if hovered over an item inside the section, find the item index
  if (over.id !== overSection.id) {
    const overItemIndex = overSection.items.findIndex(
      ({ id }) => id === over.id,
    );
    newIndex = overItemIndex + (activeComesFromTop ? 0 : 1);
  }

  // filter out the active item of the active section
  activeSection.items = activeSection.items.filter(
    ({ id }) => id !== active.id,
  );

  // add the active item to the overSection in the place it would be expected of.
  overSection.items = overSection.items.toSpliced(
    newIndex,
    0,
    activeItem as Item,
  );

  // return a new array, as the sections have changed data
  return { sections: [...sections] };
};

export const handleItemWithinSectionMovement = <
  Item extends ItemBase,
  Section extends SectionBase<Item>,
>(
  sections: Section[],
  active: Active,
  over: Over,
): { sections: Section[]; activeItem?: Item } => {
  const activeSectionIndex = sections.findIndex((section) =>
    section.items.find((item) => item.id === active.id),
  );
  // we only need the active section, as the items should be moving within the same section
  const activeSection = sections[activeSectionIndex];

  const activeIndex =
    activeSection?.items.findIndex(({ id }) => id === active.id) ?? -1;
  const overIndex =
    activeSection?.items.findIndex(({ id }) => id === over.id) ?? -1;

  // no activeSection means invalid data. Same for no indices
  if (!activeSection || activeIndex < 0 || overIndex < 0) {
    return { sections };
  }

  const activeItem = activeSection.items[activeIndex];
  // move the active item to the location of the over item
  activeSection.items = arrayMove(activeSection.items, activeIndex, overIndex);

  // return a new array, as the sections have changed data
  return { sections: [...sections], activeItem };
};
