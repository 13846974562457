import React, { useRef } from 'react';
import { TaskCardContextMenu } from 'shared/components/connected/task-card-context-menu';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { DataType } from 'shared/types/data-type';
import { Task } from 'shared/types/task';
import { useTheme } from 'styled-components';

export type ContextMenuProps = {
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  task: Task;
  hideMeta?: boolean;
};

export const ContextMenu: React.FC<ContextMenuProps> = ({
  children,
  task,
  onOpen,
  onClose,
  hideMeta,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { position, open, close } = useContextMenuState({
    disabled: theme.isMobile || !!task.frozenAt,
    onOpen: () => onOpen?.(),
    onClose: () => onClose?.(),
  });

  useClickOutside(containerRef, close);

  return (
    <div
      ref={containerRef}
      data-component-type={DataType.Task}
      onContextMenu={open}
    >
      {children}
      {!!position && (
        <TaskCardContextMenu
          task={task}
          onClose={close}
          location={position}
          hideMeta={hideMeta}
        />
      )}
    </div>
  );
};
