import {
  differenceInDays,
  endOfDay,
  format,
  isBefore,
  isSameDay,
} from 'date-fns';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'shared/assets/icons';
import {
  DatepickerWithOptions,
  IconOptions,
} from 'shared/components/ui/datepicker-with-options';
import { Icon } from 'shared/components/ui/icon';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { Colors } from 'shared/components/ui/task-meta-interaction/types';
import { useToday } from 'shared/contexts/today';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './task-meta-interaction-due-date.style';

export type TaskMetaInteractionDeadlineProps = Omit<
  React.ComponentProps<typeof TaskMetaInteraction>,
  'start' | 'onClick' | 'label' | 'selected' | 'tooltipLabel'
> & {
  value?: Timestamp;
  dateFormat: DateFormatOptions;
  weekStartsOn: WeekDays;
  onChange: (date?: Timestamp) => void;
  isDeadline?: boolean;
};

export const TaskMetaInteractionDueDate: React.FC<
  TaskMetaInteractionDeadlineProps
> = ({
  value,
  dateFormat,
  weekStartsOn,
  onChange,
  isDeadline,

  ...rest
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const today = useToday();

  const { due, overdue } = useMemo(() => {
    if (!value) {
      return { due: '', overdue: false };
    }

    if (isSameDay(value, today)) {
      return { due: t('general.labels.dates.today'), overdue: false };
    }

    if (isBefore(value, today)) {
      return {
        due: t('general.labels.days-ago', {
          count: differenceInDays(endOfDay(today), endOfDay(value)),
        }),
        overdue: true,
      };
    }

    return { due: format(value, 'd MMM'), overdue: false };
  }, [value, today, t]);

  const _onChange = (date?: Timestamp) => {
    onChange(date);
    closeMenu();
  };

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <TaskMetaInteraction
        startColor={overdue ? Colors.Error : Colors.Inherit}
        start={<Icon icon={Calendar} />}
        onClick={openMenu}
        label={due}
        selected={menuOpen}
        tooltipLabel={t(
          isDeadline
            ? due
              ? 'task.deadline.tooltip.edit.label'
              : 'task.deadline.tooltip.add.label'
            : 'task.schedule.skip.tooltip.label',
        )}
        {...rest}
      />

      {menuOpen && (
        <DatepickerWithOptions
          referenceElement={containerRef}
          value={value}
          onChange={_onChange}
          dateFormat={dateFormat}
          weekStartsOn={weekStartsOn}
          icon={IconOptions.Calendar}
          canRemoveValue={isDeadline}
        />
      )}
    </Styled.Container>
  );
};
