import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled from 'styled-components';

export const Container = styled(QueryNavLink)<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};

  height: 6.4rem;
  width: 100%;

  text-decoration: none;
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.palette.text.primary : theme.palette.text.secondary};
`;

export const IconContainer = styled.span`
  font-size: 2.4rem;
  line-height: 62.5%;
`;

export const Label = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 1rem;
  line-height: 1.5;
`;
