import { useCallback } from 'react';
import { useDuplicateTasksMutation } from 'shared/hooks/queries/use-duplicate-tasks-mutation';
import { getTaskDocs } from 'shared/services/backend-api/get-tasks';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';
import { ID } from 'shared/types/id';
import { chunkArray } from 'shared/utils/chunk-array';

import { useUpdateGoal } from './use-update-goal';

export const useDuplicateSection = () => {
  const { mutateAsync: duplicateTasks } = useDuplicateTasksMutation();
  const { submit: updateGoal } = useUpdateGoal();

  return useCallback(
    async (sectionId: ID, goal: Goal) => {
      // get the section out of the goal
      const sectionIndex =
        goal.sections?.findIndex((section) => section.id === sectionId) ?? -1;

      const section = goal.sections?.[sectionIndex];
      if (!section) {
        return;
      }

      // get tasks of goal
      const mainTasks = await getTaskDocs({ goals: [goal.id] });

      // filter tasks to only those in the section
      const sectionTasks = mainTasks.filter((task) =>
        section.tasks.includes(task.id),
      );
      // get subtasks of all sectionTasks
      const subTaskIds = Array.from(
        new Set(
          sectionTasks
            .map(({ childIds }) => childIds)
            .filter(Boolean)
            .flat(1) as ID[],
        ),
      );

      // get subtasks
      const subTasks = await Promise.all(
        chunkArray(subTaskIds, 30).map(
          async (chunkedSubTaskIds) =>
            await getTaskDocs({ ids: chunkedSubTaskIds }),
        ),
      );
      //combine tasks (unique entries only)
      const tasks = Array.from(new Set([...sectionTasks, ...subTasks.flat(1)]));

      // duplicate tasks
      const newTasks = await duplicateTasks(tasks);
      const newParentTasks = newTasks.filter(({ parentIds }) => !parentIds);

      // create new section based on old section but with new tasks
      const newSection: GoalSection = {
        id: window.crypto.randomUUID(),
        name: section.name,
        createdAt: new Date(),
        tasks: newParentTasks.map((task) => task.id),
      };
      // add section to goal below selected section
      const newSections = goal.sections?.toSpliced(
        sectionIndex + 1,
        0,
        newSection,
      );
      // update the goal (new section & taskCount & completedTaskCount)
      updateGoal({
        id: goal.id,
        sections: newSections,
        taskCount: newParentTasks.length,
        completedTaskCount: newParentTasks.filter((task) => !!task.completedAt)
          .length,
      });
    },
    [duplicateTasks, updateGoal],
  );
};
