import Typography from '@mui/material/Typography';
import React, { forwardRef } from 'react';
import { Tooltip } from 'shared/components/ui/tooltip';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';

import * as Styled from './task-meta-interaction.style';
import { Colors } from './types';

export type TaskMetaInteractionProps = {
  start: React.ReactNode;
  startColor?: Colors;
  label?: string;
  onClick?: () => void;
  tooltipLabel?: string;
  selected?: boolean;
  highlight?: boolean;
  hasTooltip?: boolean;
  isMobileOutlined?: boolean;
  isFrozen?: boolean;
};

export const TaskMetaInteraction = forwardRef<
  HTMLButtonElement,
  TaskMetaInteractionProps
>(
  (
    {
      start,
      startColor = Colors.Inherit,
      label,
      onClick,
      tooltipLabel,
      selected,
      highlight,
      hasTooltip,
      isMobileOutlined,
      isFrozen,
    },
    ref,
  ) => {
    const containerRef = useForwardedRef(ref);

    return (
      <>
        <Styled.Container
          ref={containerRef}
          onClick={!isFrozen ? onClick : undefined}
          as={!isFrozen && onClick ? undefined : 'span'}
          $selected={!!selected}
          $highlight={!!highlight}
          $isMobileOutlined={!!isMobileOutlined}
          $isFrozen={!!isFrozen}
        >
          <Styled.IconContainer $color={startColor}>
            {start}
          </Styled.IconContainer>

          {!!label && (
            <Styled.Title>
              <Typography variant="overline">{label}</Typography>
            </Styled.Title>
          )}
        </Styled.Container>
        {!!hasTooltip && !!tooltipLabel && !selected && (
          <Tooltip label={tooltipLabel} referenceElement={containerRef} />
        )}
      </>
    );
  },
);
