import { Typography } from '@mui/material';
import React from 'react';

import * as Styled from './page-header.style';

export type PageHeaderProps = {
  title: string;
  description?: string;
  interactions?: React.ReactNode;
};

export const DesktopPageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  interactions,
}) => (
  <Styled.DesktopContainer>
    <Styled.Content>
      <Typography variant="h1">{title}</Typography>
      {description && <Styled.Description>{description}</Styled.Description>}
    </Styled.Content>

    {!!interactions && (
      <Styled.Interactions>{interactions}</Styled.Interactions>
    )}
  </Styled.DesktopContainer>
);
