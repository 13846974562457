import { useTaskControls } from 'features/tasks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TaskMetaInteractions } from 'shared/components/connected/task-meta-interactions';
import { TaskCardHeader } from 'shared/components/ui/task-card-header';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { DueDisplayOptions } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { focusOnEndOfInput } from 'shared/utils/focus-on-end-of-element';
import { shouldDueBeShown } from 'shared/utils/should-due-be-shown';
import { useTheme } from 'styled-components';

import { ContextMenu } from './context-menu';
import * as Styled from './task-card.style';

export type TaskCardProps = {
  task: Task;
  displayDue?: DueDisplayOptions[];
  onClick?: (id: ID) => void;
  onUnfocus?: (id: ID) => void;
  onEditChange?: (isEditing: boolean) => void;
  showDetailOnSelect?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  showRepeatingAsTodo?: boolean;
  hideGoal?: boolean;
  hideLifeArea?: boolean;
  hideMeta?: boolean;
  noHover?: boolean;
};

export const TaskCard: React.FC<TaskCardProps> = React.memo(
  ({
    task,
    displayDue,
    onClick,
    onUnfocus,
    onEditChange,
    showDetailOnSelect = true,
    isSelected,
    isDisabled,
    showRepeatingAsTodo,
    hideGoal,
    hideLifeArea,
    hideMeta,
    noHover,
  }) => {
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    const nameInputRef = useRef<HTMLTextAreaElement>(null);
    const [taskChanges, setTaskChanges] = useState<Partial<Task>>({});
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const today = useToday();
    const user = useUser();

    const openTaskDetail = useOpenTaskDetail();

    const changedTask = useMemo(
      () => ({ ...task, ...taskChanges }) as Task,
      [task, taskChanges],
    );

    const hideInteractions = useMemo(
      () =>
        !isSelected &&
        !task.childIds?.length &&
        (!task.endStrategy?.deadline ||
          !shouldDueBeShown(task.endStrategy.deadline, displayDue, today)) &&
        !task.schedules &&
        (!task.goalId || hideGoal) &&
        (!task.lifeAreaId || hideLifeArea) &&
        !task.reminderTime,
      [
        displayDue,
        hideGoal,
        hideLifeArea,
        isSelected,
        task.childIds?.length,
        task.endStrategy?.deadline,
        task.goalId,
        task.lifeAreaId,
        task.reminderTime,
        task.schedules,
        today,
      ],
    );

    const { onComplete, onUpdate } = useTaskControls({
      parentTaskId: task.parentIds?.[task.parentIds.length - 1],
    });

    const _onClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      showDetailOnSelect && openTaskDetail(task.id);
      onClick?.(task.id);
    };

    const _onContextMenuOpen = () => setContextMenuOpen(true);

    const _onContextMenuClose = () => setContextMenuOpen(false);

    const _onHover = () => setIsHovered(true);

    const _onUnHover = () => setIsHovered(false);

    const _onUpdate = () => onUpdate(changedTask);

    const _onComplete = () => onComplete(task);

    const onChangeName = (name: string) => {
      setTaskChanges({ name });
    };

    const onName = () => setIsEditing(true);

    useKeysDown(['Enter'], (e) => {
      if (!isEditing) {
        return;
      }

      e.preventDefault();
      setIsEditing(false);

      if (document.activeElement === nameInputRef.current) {
        _onUpdate();
        nameInputRef.current?.blur();
        onUnfocus?.(task.id);
        return;
      }

      !!nameInputRef.current && focusOnEndOfInput(nameInputRef.current);
    });

    useClickOutside(containerRef, () => {
      if (isEditing) {
        setIsEditing(false);
        _onUpdate();
      }

      if (isSelected) {
        onUnfocus?.(task.id);
      }
    });

    useEffect(() => {
      if (!isSelected) {
        setIsEditing(false);
        setIsHovered(false);
        nameInputRef.current?.blur();
      }
    }, [isSelected, nameInputRef]);

    useEffect(() => {
      setTaskChanges(task);
    }, [task]);

    useEffect(() => {
      onEditChange?.(isEditing);
    }, [onEditChange, isEditing]);

    return (
      <ContextMenu
        task={task}
        onOpen={isDisabled ? undefined : _onContextMenuOpen}
        onClose={_onContextMenuClose}
        hideMeta={hideMeta}
      >
        <Styled.Container
          ref={containerRef}
          onClick={isDisabled ? undefined : _onClick}
          $isSelected={isSelected || contextMenuOpen}
          onMouseEnter={theme.isMobile || noHover ? undefined : _onHover}
          onMouseLeave={theme.isMobile || noHover ? undefined : _onUnHover}
        >
          <TaskCardHeader
            task={changedTask}
            onComplete={_onComplete}
            onChangeName={onChangeName}
            onClickName={onName}
            nameInputRef={nameInputRef}
            showRepeatingAsTodo={showRepeatingAsTodo}
            isDisabled={isDisabled}
            weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
            today={today}
          />
          <Styled.Body>
            {!hideInteractions && (
              <TaskMetaInteractions
                task={task}
                hasTooltips={isHovered}
                hideEmpty={theme.isMobile || (!isSelected && !isHovered)}
                hideGoal={!isSelected && !isHovered && hideGoal}
                hideLifeArea={!isSelected && !isHovered && hideLifeArea}
                highlight={isHovered}
                displayDue={displayDue}
                preventInteraction={theme.isMobile}
              />
            )}
          </Styled.Body>
        </Styled.Container>
      </ContextMenu>
    );
  },
);
