import React, { useEffect } from 'react';

// based upon https://github.com/krutilins/fix-safari-scroll-on-focus-in-mobile
// and https://medium.com/@krutilin.sergey.ks/fixing-the-safari-mobile-resizing-bug-a-developers-guide-6568f933cde0

const touchYKey = 'clientY';

export const useBlockMobileScrollOutsideElement = (
  ref: React.RefObject<HTMLElement>,
) => {
  useEffect(() => {
    let scrollYStartPosition = 0;

    const options = { capture: true, passive: false };
    const handleTouchStart = (e: TouchEvent) => {
      const touchStart = e.touches[0];
      scrollYStartPosition = touchStart?.[touchYKey] ?? 0;
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!ref.current) {
        return;
      }

      const touch = e.touches[0];

      if (ref.current.contains(touch.target as HTMLElement)) {
        const scrollYPosition = touch[touchYKey];
        const scrolled = scrollYStartPosition - scrollYPosition;

        const scrollTop = ref.current.scrollTop;
        const scrollHeight = ref.current.scrollHeight;
        const clientHeight = ref.current.clientHeight;
        const nextScrollTop = scrollTop
          ? Math.round(scrollTop + clientHeight + scrolled)
          : scrollTop + scrolled;

        if (
          scrollHeight === clientHeight ||
          nextScrollTop >= scrollHeight ||
          nextScrollTop <= 0
        ) {
          e.preventDefault();
        }

        return;
      }

      e.preventDefault();
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove, options);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove, options);
    };
  }, [ref]);
};
