import styled, { css } from 'styled-components';

import { Sizes, Variants } from './types';

const ContainedButton = css`
  background: transparent;
  border: none;
  transition: background 200ms;

  @media (any-hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.palette.brown['50']};
    }
  }

  &:active {
    background: ${({ theme }) => theme.palette.brown['100']};
  }
`;
const OutlinedButton = css`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  transition: background 200ms;

  @media (any-hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.palette.brown['50']};
    }
  }

  &:active {
    background: ${({ theme }) => theme.palette.brown['100']};
  }
`;

const ExtraLargeButton = css`
  height: 4rem;
  width: 4rem;

  line-height: 62.5%;
  font-size: 2.4rem;
`;

const LargeButton = css`
  height: 3.2rem;
  width: 3.2rem;

  line-height: 62.5%;
  font-size: 2rem;
`;

const MediumButton = css`
  height: 3.2rem;
  width: 3.2rem;

  line-height: 62.5%;
  font-size: 1.6rem;
`;

const SmallButton = css`
  height: 2.8rem;
  width: 2.8rem;

  line-height: 62.5%;
  font-size: 1.4rem;
`;

const ExtraSmallButton = css`
  height: 1.8rem;
  width: 1.8rem;

  line-height: 62.5%;
  font-size: 1.4rem;
`;

export const Button = styled.button<{
  $variant: Variants;
  $size: Sizes;
  $preventDrag: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: transparent;
  cursor: pointer;

  color: inherit;

  ${({ $variant }) => {
    switch ($variant) {
      case Variants.Outlined:
        return OutlinedButton;
      default:
        return ContainedButton;
    }
  }};

  ${({ $size }) => {
    switch ($size) {
      case Sizes.ExtraLarge:
        return ExtraLargeButton;
      case Sizes.Large:
        return LargeButton;
      case Sizes.Small:
        return SmallButton;
      case Sizes.ExtraSmall:
        return ExtraSmallButton;
      default:
        return MediumButton;
    }
  }};

  ${({ $preventDrag }) =>
    $preventDrag &&
    css`
      -webkit-app-region: no-drag;
    `};
`;

export const IconContainer = styled.span<{ $rotation?: number }>`
  font-size: inherit;
  line-height: inherit;

  transition: transform 200ms;

  ${({ $rotation }) =>
    $rotation &&
    css`
      transform: rotate(${$rotation}deg);
    `};
`;
