import { index, Options } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const minContainerWidth = 232;
export const initialContainerWidth = 264;
export const maxContainerWidth = 464;

export const ResizableContainer = styled.nav<{
  $smallScreenOpen: boolean;
  $width: number;
  $isResizing: boolean;
}>`
  position: fixed;
  top: 0;
  left: ${({ $smallScreenOpen }) => ($smallScreenOpen ? 0 : '-100%')};
  z-index: ${index(Options.SIDE_NAVIGATION)};

  height: 100%;
  width: 24rem;

  transition: left 200ms;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    position: relative;
    left: initial;
    top: initial;
    width: ${({ $width }) => `${$width}px`};
    contain: paint;

    ${({ $isResizing }) =>
      !$isResizing &&
      css`
        transition: width 200ms;
      `};
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const ResizeHandle = styled.span<{ $isResizing: boolean }>`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 4px;
  cursor: col-resize;
  background: transparent;

  transition: background 200ms;

  &:hover {
    background: ${({ theme }) => theme.palette.divider};
  }

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    display: block;
  }

  ${({ theme, $isResizing }) =>
    $isResizing &&
    css`
      background: ${theme.palette.divider};
    `};
`;
