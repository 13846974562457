import { Locales } from 'shared/assets/localization';
import { ID } from 'shared/types/id';

import { createPushToken } from './create-push-token';
import { getPushToken } from './get-push-token';
import { updateUser } from './update-user';

export const setPushTokenOnUser = async (userId: ID, locale: Locales) => {
  if (Notification.permission !== 'granted') {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      return;
    }
  }

  // update user settings
  await updateUser({ notifications: { taskReminder: { desktop: true } } });

  const token = await getPushToken(userId);

  if (token) {
    await createPushToken(userId, token, locale);
  }
};
