import React, { forwardRef, useEffect } from 'react';
import { Checkbox, Colors } from 'shared/components/ui/checkbox';
import { Collapse } from 'shared/components/ui/collapse';
import { TaskMetaInteractionDueDate } from 'shared/components/ui/task-meta-interaction-due-date';
import { TaskMetaInteractionLinkedItem } from 'shared/components/ui/task-meta-interaction-linked-item';
import { TaskMetaInteractionPriority } from 'shared/components/ui/task-meta-interaction-priority';
import { TaskMetaInteractionReminderTime } from 'shared/components/ui/task-meta-interaction-reminder-time';
import { TaskMetaInteractionRepeat } from 'shared/components/ui/task-meta-interaction-repeat';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { DataType } from 'shared/types/data-type';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { Goal } from 'shared/types/goal';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { CreateTask, LinkOptions } from 'shared/types/task';
import { TaskPriorityOptions, TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './task-card.style';
import { TaskCardTitle } from './task-card-title';

const priorityColorMap: Record<TaskPriorityOptions, Colors> = {
  [TaskPriorityOptions.Low]: Colors.Info,
  [TaskPriorityOptions.Medium]: Colors.Warning,
  [TaskPriorityOptions.High]: Colors.Error,
  [TaskPriorityOptions.None]: Colors.Default,
};

export type NewTaskCardProps = {
  name: string;
  type?: TaskType;
  placeholder?: string;
  deadline: Timestamp | null;
  dateFormat: DateFormatOptions;
  weekStartsOn: WeekDays;
  schedules: HabitSchedule[] | null;
  goalId?: ID | null;
  lifeAreaId?: ID | null;
  goals: Goal[];
  lifeAreas: LifeArea[];
  priority?: TaskPriorityOptions | null;
  reminderTime: number | null;
  onChange: (task: Partial<CreateTask>) => void;
  onClick: () => void;
  onCreateGoal: (name: string) => void;
  nameInputRef?: React.RefObject<HTMLTextAreaElement>;
  onPremium: () => void;
  isSelected?: boolean;
  createHabitAllowed: boolean;
  noMeta?: boolean;
};

export const NewTaskCard = forwardRef<HTMLDivElement, NewTaskCardProps>(
  (
    {
      name,
      type,
      placeholder,
      deadline,
      dateFormat,
      weekStartsOn,
      schedules,
      goalId,
      lifeAreaId,
      goals,
      lifeAreas,
      priority,
      reminderTime,
      onChange,
      onClick,
      onCreateGoal,
      isSelected,
      nameInputRef = null,
      onPremium,
      createHabitAllowed,
      noMeta,
    },
    ref,
  ) => {
    const inputRef = useForwardedRef(nameInputRef);
    const onContainer = () => onClick();
    const onChangeValue = ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- id has value 'new' and should not be sent with the new task
      id,
      ...newTask
    }: Partial<CreateTask> & { id?: ID }) => {
      onChange(newTask);
    };

    const onChangeDeadline = (date?: Timestamp) =>
      onChange({ endStrategy: { deadline: date, completionCount: null } });

    const onChangeRepeat = (changes: {
      schedules: HabitSchedule[] | null;
      type: TaskType;
    }) => onChange(changes);

    const onChangeGoal = (link: { id: ID; type: LinkOptions } | null) =>
      onChange({
        lifeAreaId: link?.type === LinkOptions.LifeArea ? link.id : null,
        goalId: link?.type === LinkOptions.Goal ? link.id : null,
      });

    const onChangeReminder = (reminderTime?: number) =>
      onChange({ reminderTime });

    const onChangePriority = (priority: TaskPriorityOptions | null) =>
      onChange({ priority });

    useEffect(() => {
      isSelected ? inputRef.current?.focus() : inputRef.current?.blur();
    }, [isSelected, inputRef]);

    return (
      <Styled.Container
        ref={ref}
        onClick={onContainer}
        data-component-type={DataType.Task}
      >
        <Styled.Header>
          <Styled.CheckboxContainer>
            <Checkbox
              color={priority ? priorityColorMap[priority] : undefined}
              checked={false}
              readOnly
              isPlaceholder
            />
          </Styled.CheckboxContainer>

          <Styled.HeaderContent>
            <Styled.TitleContainer $isFaded={false}>
              <TaskCardTitle
                ref={inputRef}
                taskId="new"
                onChange={onChangeValue}
                placeholder={placeholder}
                value={name}
                onFocus={onContainer}
                isEditing={isSelected}
                fullWidth
              />
            </Styled.TitleContainer>
          </Styled.HeaderContent>
        </Styled.Header>

        {!noMeta && (
          <Collapse isOpen={isSelected}>
            {isSelected && (
              <>
                <Styled.Body>
                  <TaskMetaInteractionDueDate
                    dateFormat={dateFormat}
                    weekStartsOn={weekStartsOn}
                    onChange={onChangeDeadline}
                    value={deadline ?? undefined}
                    highlight
                  />
                  <TaskMetaInteractionRepeat
                    value={schedules}
                    type={type ?? TaskType.Task}
                    onChange={onChangeRepeat}
                    weekStartsOn={weekStartsOn}
                    changeToHabitAllowed={createHabitAllowed}
                    onPremium={onPremium}
                    highlight
                  />
                  <TaskMetaInteractionLinkedItem
                    goals={goals}
                    lifeAreas={lifeAreas}
                    value={goalId ?? lifeAreaId}
                    onChange={onChangeGoal}
                    onCreate={onCreateGoal}
                    highlight
                  />
                  <TaskMetaInteractionReminderTime
                    onChange={onChangeReminder}
                    value={reminderTime}
                    highlight
                  />
                  <TaskMetaInteractionPriority
                    value={priority}
                    onChange={onChangePriority}
                    highlight
                  />
                </Styled.Body>
              </>
            )}
          </Collapse>
        )}
      </Styled.Container>
    );
  },
);
