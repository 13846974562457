import { httpsCallable } from 'firebase/functions';
import { GoalTemplate } from 'shared/types/goal-template';

import { getFunctions } from './helpers';

export type Options = {
  text: string;
  limit?: number;
};

type Result = {
  templates: GoalTemplate[];
};

export const getGoalTemplatesBySearch = async ({
  text,
  limit = 35,
}: Options) => {
  const functions = getFunctions();
  const getGoalTemplates = httpsCallable<string, Result>(
    functions,
    'templates-searchTemplatesByText',
  );

  const { data } = await getGoalTemplates(
    JSON.stringify({ searchText: text, limit }),
  );
  return data.templates;
};
