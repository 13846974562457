import { useGoals } from 'features/goals';
import {
  useOverdueHabits,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysTodos,
} from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { TodayTypeSections } from 'shared/types/page-sections';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskType } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

export const useTypeTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const todos = useTodaysTodos();
  const habits = useTodaysHabits();
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits();
  const goals = useGoals();

  const tasks = useMemo(
    () => [...overdueTodos, ...overdueHabits, ...todos, ...habits],
    [overdueTodos, overdueHabits, todos, habits],
  );

  const sections = useMemo(
    () => [
      {
        id: TodayTypeSections.Tasks,
        items: getSortedTasks(
          tasks.filter(({ type }) => type !== TaskType.Habit),
          {
            today,
            weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.type?.sorting?.[
                TodayTypeSections.Tasks
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
          }),
        ),
      },
      {
        id: TodayTypeSections.Habits,
        items: getSortedTasks(
          tasks.filter(({ type }) => type === TaskType.Habit),
          {
            today,
            weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.type?.sorting?.[
                TodayTypeSections.Habits
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
          }),
        ),
      },
    ],
    [
      goals,
      t,
      tasks,
      today,
      user?.settings?.startOfWeek,
      user?.settings?.todayGroupSorting?.type?.sorting,
      user?.settings?.todoSortingMode,
    ],
  );

  const taskCardTasks = useMemo(
    () =>
      sections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [sections],
  );

  return {
    sections,
    taskCardTasks,
  };
};
