import styled, { css } from 'styled-components';

export const ChartContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(1)} ${theme.spacing(3)}`};
    `};
`;

export const CompletionBanner = styled.div`
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(0.5)} ${theme.spacing(3)}`};
    `};
`;
