import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { addYears, format } from 'date-fns';
import { useGoals } from 'features/goals';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToday } from 'shared/contexts/today';
import { useActivePath } from 'shared/hooks/use-active-path';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';

import { GoalsFilterOptions, goalsFilters } from '../../utils/goals-filter';
import * as Styled from './overview-navigation-tabs.style';

type NavigationPaths =
  | Paths.Goals
  | Paths.GoalsCompleted
  | Paths.GoalsArchived
  | Paths.GoalsThisYear
  | Paths.GoalsNextYear
  | Paths.GoalsUnplanned;

type NavigationNumbers = Record<NavigationPaths, number>;

const navigationTabs: { label: string; path: NavigationPaths }[] = [
  { label: 'pages.goals.tabs.active.label', path: Paths.Goals },
  { label: 'pages.goals.tabs.this-year.label', path: Paths.GoalsThisYear },
  { label: 'pages.goals.tabs.next-year.label', path: Paths.GoalsNextYear },
  { label: 'pages.goals.tabs.unplanned.label', path: Paths.GoalsUnplanned },
  { label: 'pages.goals.tabs.completed.label', path: Paths.GoalsCompleted },
  { label: 'pages.goals.tabs.archived.label', path: Paths.GoalsArchived },
];

export const OverviewNavigationTabs: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath();
  const navigate = useNavigate();
  const today = useToday();

  const goals = useGoals();
  const navigationNumbers = useMemo<NavigationNumbers>(
    () => ({
      [Paths.Goals]: goalsFilters[GoalsFilterOptions.Active](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
      [Paths.GoalsUnplanned]: goalsFilters[GoalsFilterOptions.Unplanned](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
      [Paths.GoalsCompleted]: goalsFilters[GoalsFilterOptions.Completed](
        goals,
        today,
      ).length,
      [Paths.GoalsArchived]: goalsFilters[GoalsFilterOptions.Archived](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
      [Paths.GoalsThisYear]: goalsFilters[GoalsFilterOptions.ThisYear](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
      [Paths.GoalsNextYear]: goalsFilters[GoalsFilterOptions.NextYear](
        goals,
        today,
      ).filter(({ parentIds }) => !parentIds?.length).length,
    }),
    [goals, today],
  );

  const activeIndex = useMemo(
    () => navigationTabs.findIndex(({ path }) => path === activePath),
    [activePath],
  );

  useEffect(() => {
    const archivedTabIndex = navigationTabs.findIndex(
      ({ path }) => path === Paths.GoalsArchived,
    );
    const archivedNumbers = navigationNumbers[Paths.GoalsArchived];

    if (activeIndex === archivedTabIndex && !archivedNumbers) {
      navigate(Paths.Goals, { replace: true });
    }
  }, [activeIndex, navigate, navigationNumbers]);

  return (
    <Styled.Container>
      <MuiTabs value={activeIndex} variant="scrollable" scrollButtons={false}>
        {navigationTabs.map(({ label, path }) =>
          path === Paths.GoalsArchived &&
          navigationNumbers[path] === 0 ? null : (
            <MuiTab
              key={path}
              component={QueryNavLink}
              to={path}
              label={t(label, {
                year: format(
                  path === Paths.GoalsNextYear ? addYears(today, 1) : today,
                  'yyyy',
                ),
              })}
              icon={
                navigationNumbers[path] ? (
                  <Styled.Badge $active={activePath === path}>
                    {navigationNumbers[path]}
                  </Styled.Badge>
                ) : undefined
              }
              iconPosition="end"
              replace
            />
          ),
        )}
      </MuiTabs>
    </Styled.Container>
  );
};
