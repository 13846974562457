import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { HabitDetailCard } from 'pages/habits-overview/components/habit-detail-card';
import React from 'react';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { Week } from '../../types/week';
import { HabitsList } from '../habits-list';

export type SortableHabitsProps = {
  onReorder?: (ids: ID[]) => void;
  habits: Habit[];
  goals: Goal[];
  lifeAreas: LifeArea[];
  weeks: Week[];
  weekStartsOn: WeekDays;
  onCompleteDate?: (id: ID, date: Timestamp) => void;
  onCompleteHabit?: (id: ID) => void;
  onEditHabit?: (id: ID) => void;
  onChangeHabitType?: (
    id: ID,
    type: TaskType.Habit | TaskType.Repeating,
  ) => void;
  onChangeHideOnTodo?: (id: ID) => void;
  onArchiveHabit?: (id: ID) => void;
  onDeleteHabit?: (id: ID) => void;
  scheduleDateWidth?: number;
  scheduleVisibleDates?: number;
  scheduleScrollToDate?: number;
};

export const SortableHabitsList: React.FC<SortableHabitsProps> = ({
  habits,
  onReorder,
  goals,
  lifeAreas,
  weeks,
  weekStartsOn,
  onCompleteDate,
  onCompleteHabit,
  onEditHabit,
  onChangeHabitType,
  onChangeHideOnTodo,
  onArchiveHabit,
  onDeleteHabit,
  scheduleDateWidth,
  scheduleVisibleDates,
  scheduleScrollToDate,
}) => {
  const {
    items,
    activeItem,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({ items: habits, onDragEnd: onReorder });
  const sensors = useSortableSensors();

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
        disabled={!onReorder}
      >
        <HabitsList
          items={items}
          goals={goals}
          lifeAreas={lifeAreas}
          weeks={weeks}
          weekStartsOn={weekStartsOn}
          scheduleScrollToDate={scheduleScrollToDate}
          scheduleDateWidth={scheduleDateWidth}
          scheduleVisibleDates={scheduleVisibleDates}
          onCompleteHabit={onCompleteHabit}
          onCompleteDate={onCompleteDate}
          onArchiveHabit={onArchiveHabit}
          onDeleteHabit={onDeleteHabit}
          onEditHabit={onEditHabit}
          onChangeHabitType={onChangeHabitType}
          onChangeHideOnTodo={onChangeHideOnTodo}
          isSortable={!!onReorder}
        />

        {!!activeItem && (
          <SortableOverlay>
            <OverlayComponentWrapper>
              <HabitDetailCard
                id={activeItem.id}
                icon={activeItem.iconName}
                name={activeItem.name}
                schedules={activeItem.schedules}
                hideOnTodo={!!activeItem.hideOnTodo}
                goalId={activeItem.goalId}
                lifeAreaId={activeItem.lifeAreaId}
                isFrozen={!!activeItem.frozenAt}
                isCompleted={!!activeItem.completedAt}
                isArchived={!!activeItem.archivedAt}
                goals={goals}
                lifeAreas={lifeAreas}
                weeks={weeks}
                weekStartsOn={weekStartsOn}
                scheduleScrollToDate={scheduleScrollToDate}
                scheduleDateWidth={scheduleDateWidth}
                scheduleVisibleDates={scheduleVisibleDates}
                onCompleteHabit={onCompleteHabit}
                onCompleteDate={onCompleteDate}
                onArchiveHabit={onArchiveHabit}
                onDeleteHabit={onDeleteHabit}
                onEditHabit={onEditHabit}
                onChangeHabitType={onChangeHabitType}
                onChangeHideOnTodo={onChangeHideOnTodo}
              />
            </OverlayComponentWrapper>
          </SortableOverlay>
        )}
      </SortableContext>
    </DndContext>
  );
};
