import { PopupMenu as PopupMenuUi } from 'shared/components/ui/popup-menu';
import styled from 'styled-components';

export const Container = styled.div``;

export const Dot = styled.span<{ $color: string }>`
  display: block;

  width: 0.6rem;
  height: 0.6rem;

  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const PopupMenu = styled(PopupMenuUi)`
  width: 30.4rem;
  height: 28rem;
`;
