import { rgba } from 'polished';
import { index, Options } from 'shared/assets/styles';
import { sideNavigationHoverClass } from 'shared/constants';
import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css } from 'styled-components';

export const SmallScreenBackdrop = styled.div<{ $open: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  right: 100%;
  z-index: ${index(Options.SIDE_NAVIGATION_BACKDROP)};

  opacity: ${({ $open }) => ($open ? 1 : 0)};
  background: ${({ theme }) => rgba(theme.palette.common.black, 0.3)};
  transition: opacity 200ms;

  ${({ $open }) =>
    $open &&
    css`
      left: 0;
      right: 0;
    `};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    display: none;
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const ContentContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  min-width: 19rem;
  width: 100%;
  height: 100dvh;

  background: ${({ theme }) => theme.palette.background.paper};

  .${sideNavigationHoverClass} {
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover {
    .${sideNavigationHoverClass} {
      opacity: 1;
    }
  }
`;

export const AppNavBar = styled.div`
  -webkit-app-region: drag;
  display: flex;
  justify-content: flex-end;

  padding: ${({ theme }) =>
    `${theme.spacing(1)} ${theme.spacing(3)}
    ${theme.spacing(1)} ${theme.spacing(2)}`};

  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const Header = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(1.5)} ${theme.spacing(3)}
    ${theme.spacing(1.5)} ${theme.spacing(2)}`};
`;

export const List = styled.ul`
  flex: 1;
  overflow: auto;
  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};

  list-style: none;
`;

export const ListItem = styled.li``;

export const SubNavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const SubNavigationListItem = styled.li``;

const LinkBase = css`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  min-width: 0;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(3)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  transition: background 200ms;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const DisabledLink = styled.span`
  ${LinkBase};
`;

export const Link = styled(QueryNavLink)<{ $active: boolean }>`
  ${LinkBase};
  text-decoration: none;

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) =>
        theme.palette.primaryExtended.opacityBackgroundBackground};
      color: ${({ theme }) =>
        theme.palette.primaryExtended.opacityBackgroundText};
    `};

  ${({ $active, theme }) =>
    !$active &&
    css`
      &:hover {
        background: ${theme.palette.brown['50']};
      }
    `}
`;

export const ComingSoon = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  display: block;

  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.grey['100']};
  border-radius: ${({ theme }) => theme.spacing(6)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const SubItemIcon = styled.span<{ $active: boolean }>`
  color: ${({ $active, theme }) =>
    $active
      ? theme.palette.primaryExtended.opacityBackgroundText
      : theme.palette.grey['400']};
  font-size: 1.6rem;
  line-height: 62.5%;
`;

export const LinkTitle = styled.span`
  flex: 1;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FeedbackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
`;
