import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signInUser } from 'shared/services/backend-api';
import { LoginErrorTypes } from 'shared/types/login-form';
import { User } from 'shared/types/user';

import { queryKey as useUserKey } from './use-user-query';

export const useLoginUserMutation = () => {
  const client = useQueryClient();

  return useMutation<
    User | null,
    LoginErrorTypes,
    {
      email: string;
      password: string;
    }
  >({
    mutationFn: async ({ email, password }) => {
      // await migrateAuth0IfNeeded({ email, password });
      const result = await signInUser(email, password);
      client.removeQueries({ queryKey: [useUserKey] });

      return result;
    },
  });
};
