import { doc, getDoc } from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { taskConverter } from './converters/task';
import { CollectionOptions, getDatabase } from './helpers';

export const getTaskById = async (id: ID) => {
  return doc(getDatabase(), CollectionOptions.Tasks, id).withConverter(
    taskConverter,
  );
};

export const getTaskDocById = async (id: ID) => {
  const query = await getTaskById(id);
  const snapshot = await getDoc(query);
  return snapshot.data();
};
