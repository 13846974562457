import React, { forwardRef } from 'react';
import { ForwardChevrons, X } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';

import * as Styled from './detail-column-container.style';
import { ResizableContainer } from './resizable-container';

export type DetailColumnContainerProps = {
  open: boolean;
  onClose: () => void;
  actions?: React.ReactNode[];
  contentRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
};

export const DetailColumnContainer = forwardRef<
  HTMLDivElement,
  DetailColumnContainerProps
>(({ open, onClose, actions = [], contentRef, children }, ref) => {
  return (
    <ResizableContainer ref={ref} contentRef={contentRef} open={open}>
      <Styled.Header>
        <Styled.MobileButton>
          <IconButton icon={X} size={Sizes.ExtraLarge} onClick={onClose} />
        </Styled.MobileButton>
        <Styled.DesktopButton>
          <IconButton
            icon={ForwardChevrons}
            size={Sizes.Large}
            onClick={onClose}
          />
        </Styled.DesktopButton>

        {!!actions.length && (
          <Styled.ActionsContainer>
            {actions.map((action, index) => (
              <React.Fragment key={`action-${index}`}>{action}</React.Fragment>
            ))}
          </Styled.ActionsContainer>
        )}
      </Styled.Header>
      {children}
    </ResizableContainer>
  );
});
