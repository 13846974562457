import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

const ContainerBase = css`
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const DesktopContainer = styled.div`
  ${ContainerBase};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const MobileContainer = styled.div`
  ${ContainerBase};

  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Description = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const MetaContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(3)};
`;

export const Interactions = styled.div<{ $mobile?: boolean }>`
  ${({ $mobile }) =>
    $mobile &&
    css`
      margin-left: auto;
    `};
`;
