import { Goal } from 'shared/types/goal';
import { GoalSortingMode, SortingMode } from 'shared/types/sorting-mode';
import { TaskCardTask } from 'shared/types/task-card-task';

export const processGoalsAndTasks = (items: (Goal | TaskCardTask)[]) =>
  items.reduce<(Goal | TaskCardTask)[]>((acc, item) => {
    const clonedItem = structuredClone(item);
    // task
    if ('fields' in clonedItem) {
      acc.push(clonedItem);
      return acc;
    }

    // goal
    const goalTasks = (
      items.filter(
        (goalTask) =>
          'fields' in goalTask && goalTask.fields.goalId === item.id,
      ) as TaskCardTask[]
    ).sort(
      (taskA, taskB) =>
        taskA.raw.task.createdAt.getTime() - taskB.raw.task.createdAt.getTime(),
    );
    const goalSubGoals = (
      items.filter(
        (goalTask) =>
          !('fields' in goalTask) &&
          goalTask.parentIds?.[goalTask.parentIds?.length - 1] === item.id,
      ) as Goal[]
    ).sort(
      (goalA, goalB) => goalA.createdAt.getTime() - goalB.createdAt.getTime(),
    );

    // Add all tasks to the taskSorting, but no double entries
    const goalTaskSorting =
      clonedItem.taskSortingMode === SortingMode.Custom
        ? clonedItem.taskSorting ?? []
        : [];
    clonedItem.taskSorting = [
      ...goalTaskSorting,
      ...goalTasks
        .filter(
          ({ id }) =>
            !goalTaskSorting.includes(id) &&
            !clonedItem.sections?.find(({ tasks }) => tasks.includes(id)),
        )
        .map(({ id }) => id),
    ];

    // add all subgoals to the goalSorting, but no double entries
    const goalSorting =
      clonedItem.goalSortingMode === GoalSortingMode.Custom
        ? clonedItem.goalSorting ?? []
        : [];

    clonedItem.goalSorting = [
      ...goalSorting,
      ...goalSubGoals
        .filter(({ id }) => !goalSorting.includes(id))
        .map(({ id }) => id),
    ];

    acc.push(clonedItem);
    return acc;
  }, []);
