import { rgba } from 'polished';

import { darkPalette, lightPalette } from './palette';
import { spacing } from './spacing';

type ShadowBox = {
  sideNav: string;
  menu: string;
  card: string;
  dialog: string;
  column: string;
  mobileNavigation: string;
};

export const darkShadowBox: ShadowBox = {
  dialog: `0 ${spacing(3)} ${spacing(6)} 0 ${rgba(
    darkPalette.common?.black ?? '#000',
    0.25,
  )}`,
  card: `0 1px 3px 0 ${rgba(darkPalette.common?.black ?? '#000', 0.05)}`,
  menu: `0 ${spacing(2)} ${spacing(6)} -${spacing(1.5)} ${rgba(
    darkPalette.common?.black ?? '#000',
    0.2,
  )}`,
  sideNav: `5px 0 ${spacing(8)} 0 ${rgba(
    darkPalette.common?.black ?? '#000',
    0.15,
  )}`,
  column: `0 0 ${spacing(6)} -${spacing(1.5)} ${rgba(
    darkPalette.common?.black ?? '#000',
    0.2,
  )}`,
  mobileNavigation: `0 -${spacing(0.5)} ${spacing(6)} 0 ${rgba(darkPalette.common?.black ?? '#000', 0.05)}`,
};

export const lightShadowBox: ShadowBox = {
  dialog: `0 ${spacing(3)} ${spacing(6)} 0 ${rgba(
    lightPalette.common?.black ?? '#000',
    0.25,
  )}`,
  card: `0 1px 3px 0 ${rgba(lightPalette.common?.black ?? '#000', 0.05)}`,
  menu: `0 ${spacing(2)} ${spacing(6)} -${spacing(1.5)} ${rgba(
    lightPalette.common?.black ?? '#000',
    0.2,
  )}`,
  sideNav: `5px 0 ${spacing(8)} 0 ${rgba(
    lightPalette.common?.black ?? '#000',
    0.15,
  )}`,
  column: `0 0 ${spacing(6)} -${spacing(1.5)} ${rgba(
    lightPalette.common?.black ?? '#000',
    0.2,
  )}`,
  mobileNavigation: `0 -${spacing(0.5)} ${spacing(6)} 0 ${rgba(lightPalette.common?.black ?? '#000', 0.05)}`,
};

declare module '@mui/material/styles' {
  interface Theme {
    shadowBox: ShadowBox;
  }

  interface ThemeOptions {
    shadowBox?: ShadowBox;
  }
}
