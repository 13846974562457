import { rgba } from 'polished';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => theme.spacing(3)};
  width: 100%;

  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(3)};
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing(0.25)} ${theme.spacing(0.75)} 0 ${rgba(theme.palette.common.black, 0.05)}`};
`;

export const IllustrationContainer = styled.span`
  position: relative;
  display: block;
`;

export const ImageContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 4rem;
  min-width: 4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.brown['100']};
  overflow: hidden;

  font-size: 2rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const Image = styled(BlurHashImage)`
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  min-width: 0;

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.span<{ $isFaded: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.h6)};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;

export const Description = styled.span<{ $count: number }>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ $count, theme }) =>
    $count > 0 ? theme.palette.text.primary : theme.palette.text.secondary};
`;

export const IconContainer = styled.span`
  display: inline-block;

  font-size: 2rem;
  line-height: 62.5%;
`;
