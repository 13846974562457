import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './empty-category-list.style';

export const EmptyCategoryList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Typography variant="h5" component="span">
        {t('pages.goal-templates.categories.empty-list.label')}
      </Typography>
    </Styled.Container>
  );
};
