import React, { useRef } from 'react';
import { User } from 'shared/assets/icons';
import { UserContextMenu } from 'shared/components/connected/user-context-menu';
import { Icon } from 'shared/components/ui/icon';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';

import * as Styled from './user-avatar.style';

export const UserAvatar: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  useClickOutside(containerRef, closeMenu);

  return (
    <>
      <Styled.Container ref={containerRef}>
        <Styled.Illustration onClick={openMenu}>
          <Styled.IconContainer>
            <Icon icon={User} />
          </Styled.IconContainer>
        </Styled.Illustration>

        {menuOpen && (
          <UserContextMenu
            onClose={closeMenu}
            referenceElement={containerRef}
            position="bottom-end"
          />
        )}
      </Styled.Container>
    </>
  );
};
