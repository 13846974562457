import React from 'react';
import {
  Check,
  IconFormIcons,
  IconFormNames,
  Lock,
  Target,
} from 'shared/assets/icons';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { TimeLeft } from 'shared/components/ui/time-left';
import { Image } from 'shared/types/image';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './small-screen-goal-card.style';

export type SmallScreenGoalCardProps = {
  name: string;
  image: Image | null;
  iconName: IconFormNames | null;
  color?: string | null;
  completion: number | null;
  deadline: Timestamp | null;
  isCompleted?: boolean;
  isFrozen?: boolean;
  isArchived?: boolean;
};

export const SmallScreenGoalCard: React.FC<SmallScreenGoalCardProps> = ({
  name,
  image,
  iconName,
  isCompleted,
  color,
  completion,
  deadline,
  isFrozen,
  isArchived,
}) => {
  const icon = iconName ? IconFormIcons[iconName] : Target;

  return (
    <Styled.Container>
      <Styled.IllustrationContainer>
        <Styled.ImageContainer>
          {!image && !!icon ? (
            <Icon icon={icon!} />
          ) : (
            <Styled.Image src={image?.url} alt={name} hash={image?.blurHash} />
          )}
        </Styled.ImageContainer>

        {isCompleted && (
          <Styled.CompletedContainer>
            <Icon icon={Check} />
          </Styled.CompletedContainer>
        )}

        {!!color && <Styled.ColorDot $color={color} />}
      </Styled.IllustrationContainer>

      <Styled.Content>
        <Styled.Title
          $isCompleted={!!isCompleted}
          $isFaded={!!isFrozen || !!isArchived}
        >
          {name}
        </Styled.Title>

        <Styled.Meta>
          {completion !== null && (
            <Styled.MetaItem $primary>
              <CircleProgress percentage={completion} isSecondary isSmall />
            </Styled.MetaItem>
          )}

          {!!deadline && (
            <Styled.MetaItem $overflowEllipsis>
              <TimeLeft date={deadline} />
            </Styled.MetaItem>
          )}
        </Styled.Meta>
      </Styled.Content>

      {isFrozen && (
        <Styled.IconContainer>
          <Icon icon={Lock} />
        </Styled.IconContainer>
      )}
    </Styled.Container>
  );
};
