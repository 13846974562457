import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div``;

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.spacing(3)};

  margin: 0;
  padding: ${({ theme }) => `${theme.spacing(3)} 0`};
  width: 100%;

  list-style: none;
  overflow: auto;
`;

export const ListItem = styled.li`
  width: 19rem;
`;
