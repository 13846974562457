import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
`;

export const Button = styled.button<{ $isPlaceholder: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  margin: 0;
  padding: ${({ theme }) =>
    `${theme.spacing(1.5)} ${theme.spacing(8)} ${theme.spacing(1.5)} ${theme.spacing(2)}`};
  width: 100%;

  background-color: transparent;
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};

  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  cursor: pointer;

  ${({ $isPlaceholder, theme }) =>
    $isPlaceholder &&
    css`
      color: ${theme.palette.text.secondary};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) =>
        `${theme.spacing(2.5)} ${theme.spacing(8)} ${theme.spacing(2.5)} ${theme.spacing(3)}`};
    `};
`;

export const InteractionContainer = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing(2)};

  transform: translateY(-50%);

  color: ${({ theme }) => theme.palette.text.primary};
  line-height: 62.5%;
`;

export const IconContainer = styled.span`
  display: block;
  transform: rotate(-90deg);
  font-size: inherit;
  line-height: 62.5%;
`;

export const ClearButton = styled.button`
  margin: 0;
  padding: 0;

  border: none;
  background: none;
  cursor: pointer;

  color: inherit;
  font-size: inherit;
  line-height: 62.5%;
`;
