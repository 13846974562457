import { MobileAddButton } from 'shared/components/ui/mobile-add-button';
import { useOpenCreateTask } from 'shared/contexts/task-detail';
import { DataType } from 'shared/types/data-type';

import * as Styled from './mobile-add-task-button.style';

export const MobileAddTaskButton = () => {
  const openCreateTaskColumn = useOpenCreateTask();

  return (
    <Styled.Container>
      <MobileAddButton
        onClick={openCreateTaskColumn}
        data-component-type={DataType.Task}
      />
    </Styled.Container>
  );
};
