import { useEffect, useMemo, useRef } from 'react';

export const useKeysDown = (
  keys: string[] | string[][],
  callback: (e: KeyboardEvent, keys: string[]) => void,
): void => {
  const pressed = useRef<string[]>([]);
  const keysToProcess: string[][] = useMemo(
    () =>
      Array.isArray(keys[0]) ? (keys as string[][]) : ([keys] as string[][]),
    [keys],
  );

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const keyId = e.code || e.key;

      // figure out if the pressed key is one we track
      const isTrackedKey = keysToProcess.reduce(
        (acc, keys) => acc || !!keys.find((key) => key === keyId),
        false,
      );

      // if the key is not tracked, or it is already tracked part of the pressed keys
      if (!isTrackedKey || pressed.current.includes(keyId)) {
        return;
      }

      pressed.current.push(keyId);

      const keysPressed = keysToProcess.map((combinedKeys) =>
        combinedKeys.map((key) => pressed.current.includes(key)),
      );

      keysPressed.forEach((pressedKeys, index) => {
        if (
          pressedKeys.every(Boolean) &&
          pressedKeys.length === pressed.current.length
        ) {
          callback(e, keysToProcess[index]);
        }
      });
    };

    const onKeyUp = (e: KeyboardEvent) => {
      const keyId = e.code || e.key;
      pressed.current = pressed.current.filter((code) => code !== keyId);
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [callback, keys, keysToProcess]);
};
