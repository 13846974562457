import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/components/connected/page-header';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useActivePath } from 'shared/hooks/use-active-path';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { mobileTodoNavigation } from 'shared/routes/navigation';
import { PageEvents } from 'shared/services/tracking';

import { MobileAddTaskButton } from './components/mobile-add-task-button';
import { TasksOverview } from './components/tasks-overview';

export const Upcoming: React.FC = () => {
  const { t } = useTranslation();
  useTrackPageVisitEvent(PageEvents.Upcoming, undefined);
  const activePath = useActivePath({ level: 2 });

  return (
    <GridContainer>
      <PageHeader
        title={t('pages.upcoming.title')}
        mobileNavigation={mobileTodoNavigation}
        activePath={activePath}
      />

      <TasksOverview />

      <MobileAddTaskButton />
    </GridContainer>
  );
};
