import { UtmParameters } from 'shared/types/utm-parameters';

export enum QueryParamOptions {
  OobCode = 'oobCode',
  Premium = 'premium',
}

export const globalQueryParamOptions: (QueryParamOptions | UtmParameters)[] = [
  ...Object.values(UtmParameters),
];
