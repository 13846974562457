import { useQuery } from '@tanstack/react-query';
import { getGoalTemplatesBySearch } from 'shared/services/backend-api';

export const queryKey = 'goal-templates';

export type Options = {
  text: string;
};

export const useGoalTemplatesSearchQuery = ({ text }: Options) =>
  useQuery({
    queryKey: [queryKey, text],
    queryFn: () => getGoalTemplatesBySearch({ text }),
  });
