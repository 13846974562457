import { useLogout, useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowDown,
  Dot,
  LogOut,
  MessageCircle,
  Settings,
  Star,
  Users,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { whatsappLink } from 'shared/constants';
import { useOpenFeedbackDialog } from 'shared/contexts/feedback-dialog';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useOpenSettings } from 'shared/contexts/user-settings';
import { useNewVersionAvailable } from 'shared/contexts/version';
import { useUser } from 'shared/hooks/use-user';
import { Entitlements } from 'shared/types/entitlements';

export type UserContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref' | 'location'
> & {
  onClose: () => void;
};

export const UserContextMenu: React.FC<UserContextMenuProps> = ({
  onClose,
  referenceElement,
  position = 'bottom-start',
}) => {
  const { t } = useTranslation();

  const user = useUser();
  const userHadTrial = useUserHadTrial();
  const newVersionAvailable = useNewVersionAvailable();

  const openPremiumDialog = useOpenPremiumDialog();
  const openFeedbackDialog = useOpenFeedbackDialog();
  const logout = useLogout();
  const openSettings = useOpenSettings();

  const userIsPremium = useMemo(
    () => user?.entitlements?.includes(Entitlements.Premium),
    [user?.entitlements],
  );

  const onSettings = () => {
    openSettings();
    onClose();
  };

  const onFeedback = () => {
    openFeedbackDialog();
    onClose();
  };

  // @ts-ignore -- reload(true) should forceGet: https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#forceget
  const onReload = () => window.location.reload(true);

  const onLogout = () => {
    logout();
    onClose();
  };

  const onWhatsappLink = () => {
    window.open(whatsappLink, '_blank');
    onClose();
  };

  const onIAP = () => {
    openPremiumDialog();
    onClose();
  };

  return (
    <PopupMenu referenceElement={referenceElement} position={position}>
      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onSettings}
            start={<Icon icon={Settings} />}
            tabIndex={0}
          >
            {t('context-menus.user.labels.settings')}
          </PopupMenuButton>
        </PopupMenuListItem>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onFeedback}
            start={<Icon icon={MessageCircle} />}
            tabIndex={0}
          >
            {t('context-menus.user.labels.share-feedback')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>

      {newVersionAvailable && (
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onReload}
              start={<Icon icon={ArrowDown} />}
              end={<Icon icon={Dot} />}
              endColor={Colors.Error}
              tabIndex={0}
            >
              {t('context-menus.user.labels.reload')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}

      {!userIsPremium && (
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onIAP}
              start={<Icon icon={Star} />}
              startColor={Colors.Warning}
              tabIndex={0}
            >
              {t(
                userHadTrial
                  ? 'context-menus.user.labels.upgrade-premium'
                  : 'context-menus.user.labels.try-premium-free',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}

      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onWhatsappLink}
            start={<Icon icon={Users} />}
            tabIndex={0}
          >
            {t('context-menus.user.labels.whatsapp-community')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuList>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onLogout}
            start={<Icon icon={LogOut} />}
            tabIndex={0}
          >
            {t('context-menus.user.labels.logout')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>
    </PopupMenu>
  );
};
