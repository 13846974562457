import styled, { css } from 'styled-components';

export const EmptyContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)}`};
    `};
`;
