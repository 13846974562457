import { Loader } from 'pages/root/components/loader';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { Header } from './components/header';
import { MobileAddTaskButton } from './components/mobile-add-task-button';
import { NavigationTabs } from './components/navgation-tabs';
import * as Styled from './habits-overview.style';

export const HabitsOverview: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.Habits, undefined);

  return (
    <Styled.Container>
      <GridContainer>
        <Header />
        <NavigationTabs />
      </GridContainer>

      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>

      <MobileAddTaskButton />
    </Styled.Container>
  );
};
