import { isSameDay } from 'date-fns';
import { useTaskById, useTaskControls } from 'features/tasks';
import React, { Suspense, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SubTasksList } from 'shared/components/connected/sub-tasks-list';
import { TaskMetaInteractions } from 'shared/components/connected/task-meta-interactions';
import { Checkbox, Colors } from 'shared/components/ui/checkbox';
import { subTaskLevel } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { useBlockMobileScrollOutsideElement } from 'shared/hooks/use-block-mobile-scroll-outside-element';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions } from 'shared/types/task-base';

import { Description } from './description';
import { ResizableTitle } from './resizable-title';
import * as Styled from './task-detail-column.style';

const priorityColorMap: Record<TaskPriorityOptions, Colors> = {
  [TaskPriorityOptions.Low]: Colors.Info,
  [TaskPriorityOptions.Medium]: Colors.Warning,
  [TaskPriorityOptions.High]: Colors.Error,
  [TaskPriorityOptions.None]: Colors.Default,
};

export type TaskDetailColumnProps = {
  taskId: ID;
  onClose: () => void;
};

export const TaskDetailColumn: React.FC<TaskDetailColumnProps> = ({
  taskId,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const today = useToday();
  const { onComplete, onUpdate } = useTaskControls();
  const task = useTaskById(taskId);

  const preventPropagation = (e: React.MouseEvent) => e.stopPropagation();

  const onChangeComplete = () => !!task && !task.frozenAt && onComplete(task);

  const onChangeTitle = (name: string) =>
    !!task && !task.frozenAt && onUpdate({ ...task, name });

  const onChangeNotes = (description: string | null) =>
    !!task && !task.frozenAt && onUpdate({ ...task, description });

  const completed = useMemo(() => {
    const habitCompletions = task?.schedules?.find(
      ({ endDate }) => !endDate,
    )?.completions;

    const completedToday = habitCompletions?.find((completion) =>
      isSameDay(completion, today),
    );

    return !!completedToday || !!task?.completedAt;
  }, [task?.completedAt, task?.schedules, today]);

  useEffect(() => {
    if (!task) {
      onClose();
    }
  }, [onClose, task]);
  useBlockMobileScrollOutsideElement(containerRef);

  return !!task ? (
    <Styled.Container ref={containerRef}>
      <Styled.Header>
        <Styled.TitleBar>
          <Styled.CheckboxContainer
            onClick={preventPropagation}
            $isFrozen={!!task.frozenAt}
          >
            <Checkbox
              color={
                task.priority ? priorityColorMap[task.priority] : undefined
              }
              checked={completed}
              onChange={onChangeComplete}
            />
          </Styled.CheckboxContainer>

          <Styled.TitleContainer>
            <ResizableTitle
              onChange={onChangeTitle}
              value={task.name}
              completed={completed}
              isFrozen={!!task.frozenAt}
            />
          </Styled.TitleContainer>
        </Styled.TitleBar>

        {!task.parentIds?.length && (
          <Styled.MetaContainer>
            <TaskMetaInteractions
              task={task}
              isFaded={!!task.frozenAt}
              highlight
              hasTooltips
              isMobileOutlined
            />
          </Styled.MetaContainer>
        )}
      </Styled.Header>

      <Styled.Body>
        <Styled.DescriptionContainer>
          <Description
            value={task.description ?? null}
            onChange={onChangeNotes}
            isFrozen={!!task.frozenAt}
            placeholder={t('general.labels.task-detail.notes.placeholder')}
          />
        </Styled.DescriptionContainer>

        {(task.parentIds?.length ?? 0) <= subTaskLevel && (
          <Styled.SubTasks>
            <Suspense>
              <SubTasksList task={task} />
            </Suspense>
          </Styled.SubTasks>
        )}
      </Styled.Body>
    </Styled.Container>
  ) : null;
};
