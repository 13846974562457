import { useCustomLifeAreas } from 'features/life-areas';
import { MobileAddButton } from 'shared/components/ui/mobile-add-button';
import { useOpenCreateLifeAreaDialog } from 'shared/contexts/life-area-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { PremiumFeature } from 'shared/types/premium-feature';

import * as Styled from './mobile-add-life-area-button.style';

export const MobileAddLifeAreaButton = () => {
  const customLifeAreas = useCustomLifeAreas();

  const openCreateDialog = useOpenCreateLifeAreaDialog();
  const openPremiumDialog = useOpenPremiumDialog();
  const createLifeAreaAllowed = usePremiumFeatureAllowed(
    PremiumFeature.UnlimitedLifeAreas,
    { totalAmountOfCustomLifeAreas: customLifeAreas.length },
  );

  return (
    <Styled.Container>
      <MobileAddButton
        onClick={createLifeAreaAllowed ? openCreateDialog : openPremiumDialog}
      />
    </Styled.Container>
  );
};
