import { useHabits, useInboxTodos, useRepeatingTasks } from 'features/tasks';
import { useMemo } from 'react';
import { TaskType } from 'shared/types/task-base';

export const useInboxTasks = () => {
  const todos = useInboxTodos();
  const repeatingTasks = useRepeatingTasks({
    noGoalsOnly: true,
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });
  const habits = useHabits({
    noGoalsOnly: true,
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });

  return useMemo(
    () =>
      [...todos, ...habits, ...repeatingTasks].filter(
        (task) => task.type !== TaskType.Habit || !task.hideOnTodo,
      ),
    [habits, repeatingTasks, todos],
  );
};
