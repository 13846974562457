import {
  disableNetwork as disableNetworkFirestore,
  enableNetwork,
} from 'firebase/firestore';
import { getRollbar } from 'shared/services/error-tracking';

import { getAuthentication } from './get-authentication';
import { getDatabase } from './get-database';

// initially, the network is enabled when a user boots up the application
let networkEnabled = true;

const enableNetworkOrReload = async () => {
  if (networkEnabled) {
    // when network already enabled, don't try it again, since this will cause the queries to reload and throw errors
    return;
  }
  networkEnabled = true;

  const database = getDatabase();
  const auth = await getAuthentication();

  try {
    if (!auth.currentUser?.uid) {
      await auth.currentUser?.getIdToken(true);
    }

    await enableNetwork(database);
  } catch (error) {
    getRollbar().error(
      'Firestore Connection Helper: cannot reconnect to network',
      error as Error,
    );
    window.location.reload();
  }
};

const disableNetwork = async () => {
  if (!networkEnabled) {
    // don't try to disconnect twice, as it could form a problem
    return;
  }
  networkEnabled = false;

  const database = getDatabase();
  try {
    if (networkEnabled) {
      await disableNetworkFirestore(database);
    }
  } catch (error) {
    getRollbar().error(
      'Firestore Connection Helper: cannot disable network',
      error as Error,
    );
  }
};

export const connectionHelper = async () => {
  const database = getDatabase();

  const handleOnline = () => {
    if (database) {
      enableNetworkOrReload();
    }
  };

  const handleOffline = () => {
    if (database) {
      disableNetwork();
    }
  };

  const handleVisibilityChange = () => {
    if (database) {
      document.visibilityState === 'visible'
        ? enableNetworkOrReload()
        : disableNetwork();
    }
  };

  const hasFocusChanged = () => {
    if (database) {
      document.hasFocus() ? enableNetworkOrReload() : disableNetwork();
    }
  };

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);
  window.addEventListener('visibilitychange', handleVisibilityChange);
  window.addEventListener('focus', hasFocusChanged);
  window.addEventListener('blur', hasFocusChanged);
};
