import { addDays, endOfDay } from 'date-fns';
import { useUpdateTask } from 'features/tasks';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import {
  DatepickerWithOptions,
  IconOptions,
} from 'shared/components/ui/datepicker-with-options';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { Habit } from 'shared/types/habit';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';

import * as Styled from './reschedule-button.style';

export type RescheduleButtonProps = {
  tasks: Task[];
};

export const RescheduleButton: React.FC<RescheduleButtonProps> = React.memo(
  ({ tasks }) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const user = useUser();
    const { menuOpen, openMenu, closeMenu } = useOpenMenu();

    const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

    const { submit: updateTask } = useUpdateTask();

    const onChangeDate = (date?: Date) => {
      if (!date) {
        return;
      }

      tasks.forEach((task) => {
        // tasks
        if (task.type === TaskType.Task) {
          updateTask({
            id: task.id,
            endStrategy: {
              completionCount: task.endStrategy?.completionCount ?? null,
              deadline: endOfDay(date),
            },
          });
          return;
        }

        // habits
        const schedules = structuredClone((task as Habit).schedules ?? []);

        const activeSchedule = schedules.find(({ endDate }) => !endDate);
        if (!activeSchedule) {
          return;
        }

        // skip the day before the newly set date to make it due on the selected date.
        activeSchedule.skips = [
          ...(activeSchedule.skips ?? []),
          addDays(date, -1),
        ];

        updateTask({ id: task.id, schedules });
      });

      closeMenu();
    };

    useClickOutside(containerRef, closeMenu);

    return (
      <Styled.Container onClick={stopPropagation} ref={containerRef}>
        <Button
          variant={Variants.Text}
          size={Sizes.Small}
          color={Colors.Inherit}
          onClick={openMenu}
        >
          {t('general.sections.overdue.reschedule.button.label')}
        </Button>

        {menuOpen && (
          <DatepickerWithOptions
            referenceElement={containerRef}
            value={undefined}
            onChange={onChangeDate}
            dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
            weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
            icon={IconOptions.Calendar}
          />
        )}
      </Styled.Container>
    );
  },
);
