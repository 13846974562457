import { useArchiveGoal } from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';
import { Archive, Trash2, Upload } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenCreateGoalTemplateDialog } from 'shared/contexts/create-goal-template';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import { useUser } from 'shared/hooks/use-user';
import { Paths } from 'shared/routes';
import { Goal } from 'shared/types/goal';

export type ManageListProps = {
  goal: Goal;
  onClose: () => void;
};

export const ManageList: React.FC<ManageListProps> = ({ goal, onClose }) => {
  const { t } = useTranslation();
  const user = useUser();
  const isOnDetailView = useMatch(Paths.GoalDetail);

  const openTemplateDialog = useOpenCreateGoalTemplateDialog();
  const openDeleteGoalDialog = useOpenDeleteGoalDialog();
  const archiveGoal = useArchiveGoal();

  const onCreateTemplate = (e: React.MouseEvent) => {
    e.preventDefault();
    openTemplateDialog(goal.id);
    onClose();
  };

  const onDeleteGoal = (e: React.MouseEvent) => {
    e.preventDefault();
    openDeleteGoalDialog(
      goal,
      isOnDetailView?.params.id === goal.id ? Paths.Goals : undefined,
    );
    onClose();
  };

  const onArchiveGoal = (e: React.MouseEvent) => {
    e.preventDefault();
    archiveGoal(goal);
  };

  return (
    <PopupMenuList>
      {user?.email.endsWith('@griply.app') && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onCreateTemplate}
            startColor={Colors.Info}
            start={<Icon icon={Upload} />}
            textColor={Colors.Info}
          >
            {t('context-menus.goal.labels.create-template')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onArchiveGoal}
          start={<Icon icon={Archive} />}
        >
          {t(
            !!goal.archivedAt
              ? 'context-menus.goal.labels.de-archive'
              : 'context-menus.goal.labels.archive',
          )}
        </PopupMenuButton>
      </PopupMenuListItem>
      <PopupMenuListItem>
        <PopupMenuButton onClick={onDeleteGoal} start={<Icon icon={Trash2} />}>
          {t('context-menus.goal.labels.delete')}
        </PopupMenuButton>
      </PopupMenuListItem>
    </PopupMenuList>
  );
};
