import { index, Options } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $isElectronMac: boolean;
  $menuDisplayed: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: ${index(Options.TOPBAR)};

  min-height: 4rem;
  height: 4rem;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(1.5)}`};

  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.grey['500']};

  ${({ theme, $isElectronMac, $menuDisplayed }) =>
    $isElectronMac &&
    css`
      -webkit-app-region: drag;
      padding: ${`${theme.spacing(1)} ${theme.spacing(1.5)}
          ${theme.spacing(1)} ${theme.spacing(19)}`};

      ${theme.breakpoints.up(theme.breakpoints.values.md)} {
        padding: ${$menuDisplayed
          ? `${theme.spacing(1)} ${theme.spacing(1.5)}
          ${theme.spacing(1)} ${theme.spacing(19)}`
          : `${theme.spacing(1)} ${theme.spacing(1.5)}`};
      }
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const SmallScreenIconButton = styled.div`
  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    display: none;
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const SmallScreenUpIconButton = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    display: block;
  }
`;

export const PremiumButton = styled.button<{ $isElectronMac: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-left: auto;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.warning.light};
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: none;
  cursor: pointer;
  transition: transform 200ms;

  color: ${({ theme }) => theme.palette.warning.dark};
  text-decoration: none;
  font-size: 1.2rem;

  @media (any-hover: hover) {
    &:hover {
      transform: scale(1.03);
    }
  }

  ${({ $isElectronMac }) =>
    $isElectronMac &&
    css`
      -webkit-app-region: no-drag;
    `};
`;
