import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  container-type: inline-size;
  display: flex;

  height: 18rem;
  border-radius: ${({ theme }) => theme.spacing(4)};
  background: ${({ theme }) => theme.gradients.premiumSideways};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column-reverse;
      height: 35rem;
    `};
`;

export const ImageContainer = styled.div`
  display: none;

  ${({ theme }) =>
    `@container (min-width: ${theme.breakpoints.values.xs + 1}px)`} {
    flex: 1;
    position: relative;
    display: block;
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex: 1;
      position: relative;
      display: block;
    `};
`;

export const Image = styled.img`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  justify-content: center;

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const Description = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  padding: 0;
  margin: 0;

  opacity: 0.6;
`;

export const Interactions = styled.div``;
