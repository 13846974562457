import { Typography } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';
import { NavigationItem } from 'shared/routes/navigation';

import * as Styled from './page-header.style';
import { UserAvatar } from './user-avatar';

export type MobilePageHeaderProps = {
  title: string;
  mobileNavigation?: NavigationItem[];
  activePath?: Paths;
  interactions?: React.ReactNode;
};

export const MobilePageHeader: React.FC<MobilePageHeaderProps> = ({
  title,
  mobileNavigation,
  activePath,
  interactions,
}) => {
  const { t } = useTranslation();
  const activeIndex = useMemo(
    () =>
      activePath
        ? mobileNavigation?.findIndex(({ pathsToMatch }) =>
            pathsToMatch?.includes(activePath),
          )
        : undefined,
    [activePath, mobileNavigation],
  );

  return (
    <Styled.MobileContainer>
      <Styled.TitleContainer>
        <Typography variant="h1">{title}</Typography>
        <UserAvatar />
      </Styled.TitleContainer>

      {(!!mobileNavigation?.length || !!interactions) && (
        <Styled.MetaContainer>
          {!!mobileNavigation?.length && (
            <MuiTabs
              value={activeIndex}
              variant="scrollable"
              scrollButtons={false}
            >
              {mobileNavigation.map((navigationItem) => (
                <MuiTab
                  key={navigationItem.id}
                  component={QueryNavLink}
                  to={navigationItem.link!}
                  label={t(navigationItem.translationLabel)}
                  replace
                />
              ))}
            </MuiTabs>
          )}

          {!!interactions && (
            <Styled.Interactions $mobile>{interactions}</Styled.Interactions>
          )}
        </Styled.MetaContainer>
      )}
    </Styled.MobileContainer>
  );
};
