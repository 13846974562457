import { useGoals, useUpdateGoal } from 'features/goals';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownRight, ArrowUpRight } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { Goal } from 'shared/types/goal';

export type MoveGoalListProps = {
  goal: Goal;
  onClose: () => void;
  openGoalSelect: () => void;
};

export const MoveGoalList: React.FC<MoveGoalListProps> = ({
  goal,
  onClose,
  openGoalSelect,
}) => {
  const { t } = useTranslation();
  const { submit: updateGoal } = useUpdateGoal();
  const goals = useGoals();
  const filteredGoals = useMemo(
    () => goals.filter(({ parentIds }) => !parentIds),
    [goals],
  );
  const onGoalSelect = (e: React.MouseEvent) => {
    e.preventDefault();
    openGoalSelect();
  };

  const onGoalToMain = (e: React.MouseEvent) => {
    e.preventDefault();
    updateGoal({ id: goal.id, parentIds: null });
    onClose();
  };

  return !!filteredGoals.length || !!goal.parentIds?.length ? (
    <PopupMenuList hasBottomBorder>
      {!!filteredGoals.length && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onGoalSelect}
            start={<Icon icon={ArrowDownRight} />}
          >
            {t('context-menus.goal.labels.move-goal')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
      {!!goal.parentIds?.length && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onGoalToMain}
            start={<Icon icon={ArrowUpRight} />}
          >
            {t('context-menus.goal.labels.make-main')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
    </PopupMenuList>
  ) : null;
};
