import { CircularProgress } from '@mui/material';
import { useLocalizedGoalTemplateCategories } from 'features/goal-templates';
import React from 'react';

import { CategoryOverview } from '../category-overview';
import * as Styled from './overview.style';

export const LoadingOverview: React.FC = () => (
  <Styled.LoaderContainer>
    <CircularProgress />
  </Styled.LoaderContainer>
);

export const Overview: React.FC = () => {
  const templateCategories = useLocalizedGoalTemplateCategories();

  return (
    <Styled.Container>
      <Styled.List>
        {templateCategories.map((category) => (
          <Styled.ListItem key={category.id}>
            <CategoryOverview
              title={category.name}
              templates={category.templates}
            />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Container>
  );
};
