import { globalQueryParamOptions } from './globally-allowed-query-paramaters';

export const getGlobalAllowedSearchParameters = (search: URLSearchParams) =>
  globalQueryParamOptions.reduce((acc, globalQueryParamOption) => {
    const values = search.getAll(globalQueryParamOption);
    if (values) {
      values.forEach((value) => acc.append(globalQueryParamOption, value));
    }
    return acc;
  }, new URLSearchParams());
