import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const UnfoldButton = styled.button`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing(0.5)};

  margin: 0;
  padding: ${({ theme }) => theme.spacing(0.5)};

  border: none;
  border-radius: ${({ theme }) => theme.spacing(1.5)};
  background: none;
  cursor: pointer;
  transform: translateY(-50%);
  transition: background 200ms;

  color: ${({ theme }) => theme.palette.grey['400']};

  &:hover {
    background: ${({ theme }) => theme.palette.brown['50']};
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      left: unset;
      right: ${({ theme }) => theme.spacing(2)};
    `};
`;

export const Container = styled.article<{
  $isDragging: boolean;
  $isMoving: boolean;
}>`
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};

  padding-left: ${({ theme }) => theme.spacing(3)};
  width: 100%;

  ${({ $isDragging, $isMoving }) =>
    ($isDragging || $isMoving) &&
    css`
      ${UnfoldButton} {
        display: none;
      }
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-left: 0;
      flex-direction: row-reverse;
    `};
`;

export const UnfoldIconContainer = styled.span<{ $open: boolean }>`
  display: block;

  transform: rotate(180deg);
  transition: transform 200ms;

  font-size: 1.4rem;
  line-height: 62.5%;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(270deg);
    `}

  ${({ theme, $open }) =>
    theme.isMobile &&
    css`
      font-size: 2.4rem;
      transform: rotate(${$open ? '270deg' : '90deg'});
    `};
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacing(5)} 0 ${theme.spacing(2)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      justify-content: flex-start;
    `};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => `${theme.spacing(1)} 0`};
`;

export const OtherSectionTitle = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
`;

export const TaskCount = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const IconButtonContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey['500']};
`;
