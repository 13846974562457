import { useCreateUnlimitedGoalsAllowed } from 'features/goals';
import { MobileAddButton } from 'shared/components/ui/mobile-add-button';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';

import * as Styled from './mobile-add-goal-button.style';

export const MobileAddGoalButton = () => {
  const openCreateGoalDialog = useOpenCreateGoalDialog();
  const openPremiumDialog = useOpenPremiumDialog();
  const createGoalAllowed = useCreateUnlimitedGoalsAllowed();

  return (
    <Styled.Container>
      <MobileAddButton
        onClick={createGoalAllowed ? openCreateGoalDialog : openPremiumDialog}
      />
    </Styled.Container>
  );
};
