import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};

  max-width: 29.8rem;

  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const IconContainer = styled.span`
  display: block;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(2)};

  color: ${({ theme }) => theme.palette.grey['100']};
  line-height: 62.5%;
`;
