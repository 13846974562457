import { createContext } from 'react';
import { ID } from 'shared/types/id';
import { CreateTask } from 'shared/types/task';

export type TaskDetailContextProps = {
  openTaskDetail: (task: ID | undefined) => void;
  openCreateTask: (initialValues?: Partial<CreateTask>) => void;
  activeTaskId: ID | undefined;
};

export const TaskDetailContext = createContext<TaskDetailContextProps>({
  openTaskDetail: () => {},
  openCreateTask: () => {},
  activeTaskId: undefined,
});
