import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

import { Sizes } from './types';

export const Container = styled.div<{ $size: Sizes; $canCollapse: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ $size, $canCollapse, theme }) => {
    switch ($size) {
      case Sizes.Small:
        return css`
          padding: ${theme.isMobile
            ? $canCollapse
              ? `${theme.spacing(4)} ${theme.spacing(3)} 0`
              : `${theme.spacing(1.5)} ${theme.spacing(3)} 0`
            : $canCollapse
              ? `${theme.spacing(4)} ${theme.spacing(3)} 0 ${theme.spacing(0.5)}`
              : `${theme.spacing(1.5)} ${theme.spacing(3)} 0`};
        `;
      default:
        return css`
          padding: ${theme.isMobile
            ? `${theme.spacing(6)} ${theme.spacing(3)} 0`
            : $canCollapse
              ? `${theme.spacing(6)} ${theme.spacing(6)} 0 ${theme.spacing(0.5)}`
              : `${theme.spacing(6)} ${theme.spacing(6)} 0`};
        `;
    }
  }}
`;

const TitleStyle = css`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;

  height: 3.2rem;
  color: inherit;
`;

export const CollapseButton = styled.button`
  ${TitleStyle};
  margin: 0;
  padding: 0;

  background: transparent;
  border: none;

  cursor: pointer;
  line-height: 1;
`;
export const TitleContainer = styled.span`
  ${TitleStyle};
`;

export const IconButtonContainer = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const TitleCount = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
