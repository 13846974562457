import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';

import { DataRow } from './data-row';
import { GoalDataGridView } from './goal-data-grid-view';
import { useColumns } from './use-columns';
import { useGoalOrdering } from './use-goal-ordering';

export type SelfSortingGoalDataGridProps = Omit<
  React.ComponentProps<typeof GoalDataGridView>,
  'columnToSort' | 'sortMode' | 'onSortChange' | 'rows' | 'hasDndRows'
> & {
  onReorder?: (ids: ID[]) => void;
  goals: Goal[];
  allGoals: Goal[];
  lifeAreas: LifeArea[];
  favorites: ID[];
};

export const SelfSortingGoalDataGrid: React.FC<
  SelfSortingGoalDataGridProps
> = ({
  goals,
  allGoals,
  lifeAreas,
  columnTypes,
  favorites,
  onReorder,
  ...rest
}) => {
  const columns = useColumns(columnTypes);
  const { rows, sortMode, onSortChange, sortColumn } = useGoalOrdering({
    goals,
    allGoals,
    lifeAreas,
    columnTypes,
    favorites,
  });

  const onDragEnd = (ids: ID[]) => {
    onSortChange(undefined);
    onReorder?.(ids);
  };

  const {
    items,
    activeItem,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({ items: rows, onDragEnd });
  const sensors = useSortableSensors();

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
        disabled={!onReorder}
      >
        <GoalDataGridView
          rows={items}
          columnTypes={columnTypes}
          sortMode={sortMode}
          onSortChange={onSortChange}
          columnToSort={sortColumn}
          hasDndRows={!!onReorder}
          {...rest}
        />

        {!!activeItem && (
          <SortableOverlay>
            <OverlayComponentWrapper>
              <DataRow columns={columns} data={activeItem} />
            </OverlayComponentWrapper>
          </SortableOverlay>
        )}
      </SortableContext>
    </DndContext>
  );
};
