import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useArchiveTask,
  useCompleteTask,
  useHabits,
  useUpdateTask,
} from 'features/tasks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { useOpenEditHabitDialog } from 'shared/contexts/habit-form';
import { useUser } from 'shared/hooks/use-user';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';

import { EmptyHabitsList } from '../empty-habits-list';
import { HabitsHeader } from '../habits-header';
import { HabitsList } from '../habits-list';
import * as Styled from './completed-habits-list.style';

export const CompletedHabitsList: React.FC = () => {
  const user = useUser();
  const navigate = useNavigate();
  const habits = useHabits({ completedOnly: true, unArchivedOnly: true });

  const { submit: updateTask } = useUpdateTask();
  const completeHabit = useCompleteTask();
  const deleteHabit = useOpenDeleteHabitDialog();
  const archiveHabit = useArchiveTask();
  const editHabit = useOpenEditHabitDialog();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const onComplete = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      completeHabit(habit);
    }
  };

  const onDelete = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      deleteHabit(habit);
    }
  };

  const onArchive = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      archiveHabit(habit);
    }
  };

  const onEdit = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      editHabit(habit, id);
    }
  };

  const editType = (id: ID, type: TaskType.Habit | TaskType.Repeating) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      updateTask({ id: habit.id, type });
    }
  };

  const editHideOnTodo = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      updateTask({
        id: habit.id,
        type: habit.type,
        hideOnTodo: !habit.hideOnTodo,
      });
    }
  };

  useEffect(() => {
    if (!habits.length) {
      navigate(Paths.Habits, { replace: true });
    }
  }, [habits.length, navigate]);

  return !habits.length ? (
    <EmptyHabitsList />
  ) : (
    <Styled.Container>
      <HabitsHeader />

      <HabitsList
        items={habits}
        goals={goals}
        lifeAreas={lifeAreas}
        weeks={[]}
        weekStartsOn={user?.settings?.startOfWeek ?? initialWeekStartsOn}
        onCompleteHabit={onComplete}
        onArchiveHabit={onArchive}
        onDeleteHabit={onDelete}
        onEditHabit={onEdit}
        onChangeHabitType={editType}
        onChangeHideOnTodo={editHideOnTodo}
      />
    </Styled.Container>
  );
};
