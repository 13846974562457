import { addYears, getYear } from 'date-fns';
import { FirestoreDataConverter } from 'firebase/firestore';
import { SortingMode, TodayGroupBy } from 'shared/types/sorting-mode';
import { User } from 'shared/types/user';
import { initialWeekStartsOn } from 'shared/types/week-days';

const todayGroupByOptions = Object.values(TodayGroupBy);

export const userConverter: FirestoreDataConverter<User> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();

    return {
      ...data,
      name: data.name ?? '',
      settings: {
        ...(data.settings ?? {}),
        goalTabSorting: {
          ...(data.settings?.goalTabSorting ?? {}),
          all: {
            sorting: data.settings?.goalSorting,
            ...(data.settings?.goalTabSorting?.all ?? {}),
          },
        },
        goalRoadmapSorting: data.settings?.goalRoadmapSorting ?? {
          // add deprecated sorting values if new sorting isn't used already
          inProgress: data.settings?.goalTabSorting?.active?.sorting ?? [],
          [getYear(new Date())]:
            data.settings?.goalTabSorting?.[getYear(new Date())]?.sorting ?? [],
          [getYear(addYears(new Date(), 1))]:
            data.settings?.goalTabSorting?.[getYear(new Date())]?.sorting ?? [],
        },
        todayGroupBy: todayGroupByOptions.includes(data.settings?.todayGroupBy)
          ? data.settings?.todayGroupBy
          : TodayGroupBy.None,
        todoSortingMode: data.settings?.todoSortingMode ?? SortingMode.DueDate,
        startOfWeek: data.settings?.startOfWeek ?? initialWeekStartsOn,
      },
    } as User;
  },
  toFirestore: (user) => user,
};
