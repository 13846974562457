import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ID } from 'shared/types/id';
import { CreateTask } from 'shared/types/task';
import { useTheme } from 'styled-components';

import { DesktopDetailColumn } from './desktop-detail-column';
import { MobileDetailColumn } from './mobile-detail-column';
import * as Styled from './task-detail.style';
import {
  TaskDetailContext,
  TaskDetailContextProps,
} from './task-detail-context';

export type TaskDetailProviderProps = {
  children: React.ReactNode;
};

export const TaskDetailProvider: React.FC<TaskDetailProviderProps> = ({
  children,
}) => {
  const closeTimerRef = useRef<number>();
  const [initialValues, setInitialValues] = useState<
    Partial<CreateTask> | undefined
  >(undefined);
  const [activeTask, setActiveTask] = useState<ID>();
  const theme = useTheme();

  const openTask = useCallback(
    (taskId: ID | undefined) => {
      closeTimerRef.current && window.clearTimeout(closeTimerRef.current);
      setActiveTask(taskId);
      setInitialValues(undefined);

      if (theme.isMobile) {
        document.body.style.overflow = taskId ? 'hidden' : '';
      }
    },
    [theme.isMobile],
  );

  const openCreateTask = useCallback(
    (initialValues?: Partial<CreateTask>) => {
      setInitialValues(initialValues ?? {});
      setActiveTask(undefined);

      if (theme.isMobile) {
        document.body.style.overflow = 'hidden';
      }
    },
    [theme.isMobile],
  );

  const closeDetail = useCallback(() => {
    setActiveTask(undefined);
    setInitialValues(undefined);
    document.body.style.overflow = '';
  }, []);

  const value = useMemo<TaskDetailContextProps>(
    () => ({
      openTaskDetail: openTask,
      openCreateTask,
      activeTaskId: activeTask,
    }),
    [activeTask, openCreateTask, openTask],
  );

  return (
    <TaskDetailContext.Provider value={value}>
      <Styled.Container>
        {children}

        {theme.isMobile ? (
          <MobileDetailColumn
            initialValues={initialValues}
            taskId={activeTask}
            onClose={closeDetail}
          />
        ) : (
          <DesktopDetailColumn taskId={activeTask} onClose={closeDetail} />
        )}
      </Styled.Container>
    </TaskDetailContext.Provider>
  );
};
