import { isAfter, isSameDay } from 'date-fns';
import { Task, TaskCheckOptions } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleDueDate } from 'shared/utils/get-schedule-due-date';

type Options = {
  today: Timestamp;
  showRepeatingAsTodo?: boolean;
  weekStartsOn: WeekDays;
};

export const getTaskCheckOption = (
  { type, completedAt, schedules }: Task,
  { today, showRepeatingAsTodo, weekStartsOn }: Options,
) => {
  if (type === TaskType.Task) {
    return completedAt ? TaskCheckOptions.Checked : TaskCheckOptions.Unchecked;
  }

  const activeSchedule = schedules?.find(({ endDate }) => !endDate);
  if (completedAt || !activeSchedule) {
    return TaskCheckOptions.RepeatChecked;
  }

  if (
    activeSchedule.completions.find((completion) =>
      isSameDay(today, completion),
    )
  ) {
    return showRepeatingAsTodo
      ? TaskCheckOptions.Checked
      : TaskCheckOptions.RepeatSingleChecked;
  }

  const dueDate = getScheduleDueDate(activeSchedule, { today, weekStartsOn });
  if (dueDate && !isSameDay(dueDate, today) && isAfter(dueDate, today)) {
    return TaskCheckOptions.RepeatSingleChecked;
  }

  return TaskCheckOptions.Unchecked;
};
