import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedGoalTemplateCategoryWithGoalTemplates } from 'shared/types/goal-template-category';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

import { useGoalTemplateCategories } from './use-goal-template-categories';

export const useLocalizedGoalTemplateCategories = () => {
  const { i18n } = useTranslation();
  const categories = useGoalTemplateCategories();

  return useMemo<LocalizedGoalTemplateCategoryWithGoalTemplates[]>(
    () =>
      categories.map((category) => ({
        ...category,
        name: getLocalizedStringValue(category.name, i18n.language),
      })),
    [categories, i18n.language],
  );
};
