import {
  useArchiveGoal,
  useGoalById,
  useGoals,
  useUpdateGoal,
} from 'features/goals';
import { useIsFavorite, useToggleFavorite } from 'features/user';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ArrowLeft, MoreHorizontal, Star } from 'shared/assets/icons';
import {
  Button,
  Colors,
  Sizes as ButtonSizes,
  Variants,
} from 'shared/components/ui/button';
import { GoalContextMenu } from 'shared/components/ui/goal-context-menu';
import { GridContainer } from 'shared/components/ui/grid-container';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useCompleteGoal } from 'shared/contexts/complete-goal';
import { useOpenCreateGoalTemplateDialog } from 'shared/contexts/create-goal-template';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import { useOpenEditGoalDialog } from 'shared/contexts/goal-form';
import { useOpenGoalProgress } from 'shared/contexts/goal-progress';
import { useBackFunction } from 'shared/contexts/history';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useUser } from 'shared/hooks/use-user';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { countMetricOptions } from 'shared/types/metric-template';
import { FavoriteType } from 'shared/types/user-settings';

import { GoalDetailIllustration } from '../goal-detail-illustration';
import * as Styled from './detail-navigation.style';

export const DetailNavigation: React.FC = () => {
  const interactionsRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const { id } = useParams();
  const user = useUser();
  const goals = useGoals();
  const goal = useGoalById(id);
  const isFavorite = useIsFavorite(id);

  const openEditGoalDialog = useOpenEditGoalDialog();
  const openProgress = useOpenGoalProgress();
  const completeGoal = useCompleteGoal();
  const openDeleteGoalDialog = useOpenDeleteGoalDialog();
  const archiveGoal = useArchiveGoal();
  const openTemplateDialog = useOpenCreateGoalTemplateDialog();
  const backFunction = useBackFunction(Paths.Goals);
  const toggleFavorite = useToggleFavorite();
  const { submit: updateGoal } = useUpdateGoal();

  const onEdit = () => {
    !!goal && openEditGoalDialog(goal, goal.id);
    closeMenu();
  };
  const onComplete = () => {
    !!goal && completeGoal(goal);
    closeMenu();
  };
  const onDelete = () => {
    !!goal && openDeleteGoalDialog(goal, Paths.Goals);
    closeMenu();
  };
  const onArchive = () => {
    !!goal && archiveGoal(goal);
    closeMenu();
  };
  const onCreateTemplate = user?.email.endsWith('@griply.app')
    ? () => {
        !!goal && openTemplateDialog(goal.id);
        closeMenu();
      }
    : undefined;
  const onUpdateProgress = () => {
    !!goal && goal.metric && openProgress(goal.id, goal.metric);
    closeMenu();
  };

  const onFavorite = () => {
    !!goal && toggleFavorite(goal.id, FavoriteType.Goal);
  };

  const onRemoveParentIds = (e: React.MouseEvent) => {
    e.preventDefault();
    updateGoal?.({ id, parentIds: null });
  };

  const onMoveGoal = (parentIds: ID[]) => {
    updateGoal({ id, parentIds });
    closeMenu();
  };

  return (
    <Styled.Container $hasImage={!!goal?.image}>
      {!!goal?.image && (
        <Styled.MobileImage>
          <GoalDetailIllustration illustration={goal.image} />
        </Styled.MobileImage>
      )}

      <GridContainer>
        <Styled.ButtonsContainer>
          <Styled.MobileBackButtonContainer $hasImage={!!goal?.image}>
            <IconButton
              icon={ArrowLeft}
              size={Sizes.Large}
              onClick={backFunction}
            />
          </Styled.MobileBackButtonContainer>
          <Styled.DesktopBackButtonContainer>
            <Button
              onClick={backFunction}
              startIcon={ArrowLeft}
              size={ButtonSizes.Medium}
              variant={Variants.Text}
              color={Colors.Inherit}
            >
              {t('general.labels.navigation.back.title')}
            </Button>
          </Styled.DesktopBackButtonContainer>

          <Styled.Interactions ref={interactionsRef}>
            <Styled.FavoriteButtonContainer $isFavorite={isFavorite}>
              <IconButton icon={Star} size={Sizes.Large} onClick={onFavorite} />
            </Styled.FavoriteButtonContainer>

            <Styled.MoreButtonContainer $hasImage={!!goal?.image}>
              <IconButton
                icon={MoreHorizontal}
                size={Sizes.Large}
                onClick={openMenu}
              />
            </Styled.MoreButtonContainer>
            {menuOpen && (
              <GoalContextMenu
                onClose={closeMenu}
                position="bottom-end"
                goals={goals}
                parentIds={goal?.parentIds ?? undefined}
                referenceElement={interactionsRef}
                onEditGoal={onEdit}
                onDeleteGoal={onDelete}
                isCompleted={!!goal?.completedAt}
                onCompleteGoal={onComplete}
                isArchived={!!goal?.archivedAt}
                onArchiveGoal={onArchive}
                onCreateTemplate={onCreateTemplate}
                onUpdateProgress={
                  goal?.metric?.id &&
                  !countMetricOptions.includes(goal?.metric?.id)
                    ? onUpdateProgress
                    : undefined
                }
                onGoalToMain={goal?.parentIds ? onRemoveParentIds : undefined}
                onMoveGoal={goal?.parentIds ? onMoveGoal : undefined}
                onFavorite={onFavorite}
                isFavorite={isFavorite}
              />
            )}
          </Styled.Interactions>
        </Styled.ButtonsContainer>
      </GridContainer>
    </Styled.Container>
  );
};
