import { useCallback } from 'react';
import { useResetPasswordAndLoginMutation } from 'shared/hooks/queries/use-reset-password-and-login-mutation';
import { useNavigateAppOpenScreen } from 'shared/hooks/use-navigate-app-open-screen';

export const useResetPasswordAndLogin = () => {
  const navigateAppOpen = useNavigateAppOpenScreen();
  const { mutateAsync, error, reset, isPending, isSuccess } =
    useResetPasswordAndLoginMutation();

  const resetPasswordAndLogin = useCallback(
    async (
      oobCode: string,
      { password, name }: { password: string; name?: string },
    ) => {
      const user = await mutateAsync({ code: oobCode, password, name });
      await navigateAppOpen(user);
    },
    [mutateAsync, navigateAppOpen],
  );

  return {
    resetPasswordAndLogin,
    error,
    isLoading: isPending,
    isSuccess,
    reset,
  };
};
