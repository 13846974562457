import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { DeleteLifeAreaError } from './delete-life-area-error';
import { DeleteLifeAreaLoading } from './delete-life-area-loading';
import { DeleteLifeAreaMessage } from './delete-life-area-message';

export type DeleteLifeAreaDialogProps = {
  open: boolean;
  name: string;
  onClose: () => void;
  onSubmit: () => void;
  onRetry: () => void;
  isLoading?: boolean;
  isError?: boolean;
};

export const DeleteLifeAreaDialog: React.FC<DeleteLifeAreaDialogProps> = ({
  open,
  name,
  onClose,
  onRetry,
  onSubmit,
  isLoading,
  isError,
}) => {
  const theme = useTheme();
  const content = useMemo(() => {
    if (isError) {
      return (
        <DeleteLifeAreaError name={name} onRetry={onRetry} onCancel={onClose} />
      );
    }

    if (isLoading) {
      return <DeleteLifeAreaLoading />;
    }

    return (
      <DeleteLifeAreaMessage
        name={name}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    );
  }, [isError, isLoading, name, onClose, onRetry, onSubmit]);

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={theme.isMobile}
      onClose={!isLoading ? onClose : undefined}
    >
      {content}
    </Dialog>
  );
};
