import {
  useOverdueHabits,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysTodos,
} from 'features/tasks';
import { useMemo } from 'react';

export const useTodayTasks = () => {
  const todos = useTodaysTodos();
  const habits = useTodaysHabits();
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits();

  return useMemo(
    () => [...overdueTodos, ...overdueHabits, ...todos, ...habits],
    [overdueTodos, overdueHabits, todos, habits],
  );
};
