import { PopupMenu as PopupMenuUi } from 'shared/components/ui/popup-menu';
import styled, { css } from 'styled-components';

export const PopupMenu = styled(PopupMenuUi)<{ $height?: number }>`
  width: 30.4rem;

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}px;
    `}
`;
