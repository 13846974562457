import {
  addDays,
  addWeeks,
  endOfDay,
  isAfter,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { Frequency, RRule } from 'rrule';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  today: Timestamp;
};

const frequencyToWeekAdjustmentsMap: Record<Frequency, number> = {
  [Frequency.SECONDLY]: -1,
  [Frequency.MINUTELY]: -1,
  [Frequency.HOURLY]: -1,
  [Frequency.DAILY]: -1,
  [Frequency.WEEKLY]: -1,
  [Frequency.MONTHLY]: -4,
  [Frequency.YEARLY]: -52,
};

export const getRruleDueDateBySchedule = (
  schedule: HabitSchedule,
  { today }: Options,
) => {
  const endOfToday = endOfDay(today);
  // create rrule
  let rrule = RRule.fromString(schedule.rrule.format);
  // get the last skip
  const lastSkip = schedule.skips?.[schedule.skips.length - 1];

  // check if due today
  rrule.options.dtstart = endOfDay(subDays(today, 1));
  const [dueToday] = rrule.between(startOfDay(today), endOfToday, true);

  if (dueToday) {
    // check if today is completed or skipped
    const completedToday = schedule.completions.find((completion) =>
      isSameDay(completion, today),
    );
    const skippedToday =
      !!lastSkip && (isSameDay(lastSkip, today) || isAfter(lastSkip, today));
    if (!completedToday && !skippedToday) {
      return endOfDay(dueToday);
    }
  }

  // if not
  // get the last completion
  const lastCompletion = schedule.completions.toSorted(
    (dateA, dateB) => dateB!.getTime() - dateA!.getTime(),
  )[0] as Date | undefined;
  // get startDate of schedule
  const scheduleStartDate = schedule.startDate;
  const rruleStartDate = [
    lastCompletion ? startOfDay(addDays(lastCompletion, 1)) : undefined,
    lastSkip ? startOfDay(addDays(lastSkip, 1)) : undefined,
    startOfDay(scheduleStartDate),
    addWeeks(endOfToday, frequencyToWeekAdjustmentsMap[rrule.options.freq]),
  ]
    .filter(Boolean)
    .sort((dateA, dateB) => dateB!.getTime() - dateA!.getTime())[0] as Date;

  // set startdate to latest date
  rrule.options.dtstart = endOfDay(subDays(rruleStartDate, 1));
  rrule.options.until = endOfToday;

  // check if there is an option before today
  const dueDate = rrule.all().pop();

  if (dueDate) {
    return endOfDay(dueDate);
  }

  // if not, get the first next option from the latest skip, latest completion or today (depending on which is last)
  const nextStartDate = [
    startOfDay(addDays(today, 1)),
    lastCompletion ? startOfDay(addDays(lastCompletion, 1)) : undefined,
    lastSkip ? startOfDay(addDays(lastSkip, 1)) : undefined,
  ]
    .filter(Boolean)
    .sort((dateA, dateB) => dateB!.getTime() - dateA!.getTime())[0] as Date;

  rrule = RRule.fromString(schedule.rrule.format);
  rrule.options.dtstart = nextStartDate;
  const afterDue = rrule.after(endOfToday);
  return afterDue ? endOfDay(afterDue) : undefined;
};
