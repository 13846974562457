import { IconFormNames } from 'shared/assets/icons';

import { ID } from './id';
import { Roles } from './roles';
import { Timestamp } from './timestamp';

export enum TaskType {
  Task = 'todo',
  Repeating = 'repeating',
  Habit = 'habit',
}

export enum DueDisplayOptions {
  Past = 'past',
  Today = 'today',
  Future = 'future',
}

export enum TaskPriorityOptions {
  None = 'none',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export const noPriorityOption = 'none';

export type TaskBase = {
  id: string;
  type: TaskType;
  roles: Roles;
  name: string;
  startDate: Timestamp;
  description?: string | null;
  iconName?: IconFormNames | null;
  goalId: ID | null;
  lifeAreaId: ID | null;
  reminderTime: number | null;
  parentIds: ID[] | null;
  childIds?: ID[] | null;
  subtaskOrder?: ID[] | null;
  createdAt: Timestamp;
  archivedAt: Timestamp | null;
  frozenAt: Timestamp | null;
  priority?: TaskPriorityOptions | null;
  endStrategy: {
    deadline: Timestamp | null;
    completionCount: number | null;
  } | null;
  completedAt: Timestamp | null;
  duration?: number | null;
  updatedAt: Timestamp | null;
};

export type NewTaskBase = {
  createdAt: Timestamp;
  name: string;
  iconName?: IconFormNames | null;
  goalId: ID | null;
  lifeAreaId: ID | null;
  reminderTime: number | null;
  startDate: Timestamp | null;
  parentIds: ID[] | null;
  priority: TaskPriorityOptions | null;
  endStrategy: {
    deadline: Timestamp | null;
    completionCount: number | null;
  } | null;
};
