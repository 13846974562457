import { isAfter, isSameDay } from 'date-fns';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { CheckedTaskState } from 'shared/types/task-card-task';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleDueDate } from 'shared/utils/get-schedule-due-date';

type Options = {
  today: Timestamp;
  showRepeatingAsTodo?: boolean;
  weekStartsOn: WeekDays;
};

export const getTaskCheckedState = (
  { type, completedAt, schedules }: Task,
  { today, showRepeatingAsTodo, weekStartsOn }: Options,
) => {
  if (type === TaskType.Task) {
    return completedAt ? CheckedTaskState.Checked : CheckedTaskState.Unchecked;
  }

  const activeSchedule = schedules?.find(({ endDate }) => !endDate);
  if (completedAt || !activeSchedule) {
    return CheckedTaskState.RepeatChecked;
  }

  if (
    activeSchedule.completions.find((completion) =>
      isSameDay(today, completion),
    )
  ) {
    return showRepeatingAsTodo
      ? CheckedTaskState.Checked
      : CheckedTaskState.RepeatSingleChecked;
  }

  const dueDate = getScheduleDueDate(activeSchedule, { today, weekStartsOn });
  if (dueDate && !isSameDay(dueDate, today) && isAfter(dueDate, today)) {
    return CheckedTaskState.RepeatSingleChecked;
  }

  return CheckedTaskState.Unchecked;
};
