import { CircularProgress } from '@mui/material';
import { useGoalTemplatesBySearch } from 'features/goal-templates';
import React from 'react';
import { GoalTemplateCard } from 'shared/components/ui/goal-template-card';
import { useShowGoalTemplatePreview } from 'shared/contexts/goal-template-preview';
import { ID } from 'shared/types/id';

import * as Styled from './search-overview.style';

export type SearchOverviewProps = {
  searchText: string;
};

export const LoadingSearchOverview: React.FC = () => (
  <Styled.LoaderContainer>
    <CircularProgress />
  </Styled.LoaderContainer>
);

export const SearchOverview: React.FC<SearchOverviewProps> = ({
  searchText,
}) => {
  const templates = useGoalTemplatesBySearch({ text: searchText });
  const openPreview = useShowGoalTemplatePreview();

  const showPreview = (id: ID) => {
    const template = templates.find((template) => template.id === id);

    if (!template) {
      return;
    }

    openPreview(template);
  };

  return (
    <Styled.Container>
      <Styled.List>
        {templates.map((template) => (
          <Styled.ListItem key={template.id}>
            <GoalTemplateCard template={template} onClick={showPreview} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Container>
  );
};
