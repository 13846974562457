import { redirectWithParameters } from 'shared/lib/react-router-dom';
import { Paths } from 'shared/routes';
import {
  getGoals,
  getUser,
  signInWithToken,
} from 'shared/services/backend-api';

export const userLoader = async () => {
  const user = await getUser();

  if (!user) {
    throw redirectWithParameters(Paths.Login);
  }

  return user;
};

export const loginLoader = async () => {
  await signInWithToken();
  const user = await getUser();

  if (user) {
    const goals = await getGoals();

    throw redirectWithParameters(goals.length ? Paths.Home : Paths.Goals);
  }

  return user;
};
