import { Paths } from 'shared/routes';

import { MainNavigationItemId, NavigationItem } from './types/navigation-item';

export const mobileTodoNavigation: NavigationItem[] = [
  {
    id: MainNavigationItemId.Today,
    translationLabel: 'general.menu.navigation.todo.today',
    link: Paths.Home,
    pathsToMatch: [Paths.Home],
    subItems: [],
  },
  {
    id: MainNavigationItemId.Inbox,
    translationLabel: 'general.menu.navigation.todo.inbox',
    link: Paths.Inbox,
    pathsToMatch: [Paths.Inbox],
    subItems: [],
  },
  {
    id: MainNavigationItemId.Upcoming,
    translationLabel: 'general.menu.navigation.todo.upcoming',
    link: Paths.Upcoming,
    pathsToMatch: [Paths.Upcoming],
    subItems: [],
  },
];
