import Typography from '@mui/material/Typography';
import React from 'react';
import { GoalTemplateCard } from 'shared/components/ui/goal-template-card';
import { useShowGoalTemplatePreview } from 'shared/contexts/goal-template-preview';
import { GoalTemplate } from 'shared/types/goal-template';
import { ID } from 'shared/types/id';

import * as Styled from './category-overview.style';

export type CategoryOverviewProps = {
  title: string;
  templates: GoalTemplate[];
};

export const CategoryOverview: React.FC<CategoryOverviewProps> = ({
  title,
  templates,
}) => {
  const openPreview = useShowGoalTemplatePreview();

  const showPreview = (id: ID) => {
    const template = templates.find((template) => template.id === id);

    if (!template) {
      return;
    }

    openPreview(template);
  };

  return (
    <Styled.Container>
      <Styled.Header>
        <Typography variant="h5" component="span">
          {title}
        </Typography>
      </Styled.Header>
      <Styled.List>
        {templates.map((template) => (
          <Styled.ListItem key={template.id}>
            <GoalTemplateCard template={template} onClick={showPreview} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Container>
  );
};
