import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @media (hover: none) {
    * {
      -webkit-tap-highlight-color: transparent;
      outline: none;
    }
  }
  
  body {
    line-height: unset;
    background: ${({ theme }) => (theme.isMobile ? theme.palette.background.paper : theme.palette.background.default)};
  }
`;
