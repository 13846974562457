import { Over } from '@dnd-kit/core';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';
import { TaskCardTask } from 'shared/types/task-card-task';

import { Item } from './types';

export const handleGoalSectionBetweenSections = (
  activeItem: GoalSection,
  overItem: Item | GoalSection,
  items: Item[],
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  // when moving a goal-section over a task, prevent the goal-section from swapping places with the task
  if (over.data.current?.type === DraggableOptions.Task) {
    return { items: [...items], changedItems: [] };
  }

  const activeParentIndex = items.findIndex(
    (item) =>
      !('fields' in item) &&
      item.sections?.some(({ id }) => id === activeItem.id),
  );
  const activeParent = items[activeParentIndex] as Goal | undefined;

  if (!activeParent) {
    return { items, changedItems: [] };
  }

  if (over.data.current?.type === DraggableOptions.GoalSection) {
    const overSection = overItem as GoalSection;
    const overParentIndex = items.findIndex(
      (item) =>
        !('fields' in item) &&
        item.sections?.some(({ id }) => id === overSection.id),
    );

    const overParent = items[overParentIndex] as Goal | undefined;

    if (!overParent || activeParent.id === overParent.id) {
      return { items, changedItems: [] };
    }

    const newIndex =
      activeParentIndex < overParentIndex
        ? 0
        : overParent.sections?.length ?? 0;
    overParent.sections = overParent.sections?.toSpliced(
      newIndex,
      0,
      activeItem,
    ) ?? [activeItem];
    activeParent.sections =
      activeParent.sections?.filter(({ id }) => id !== activeItem.id) ?? null;

    // update all tasks within the goal-section to have a new goal
    const goalSectionTasks = items.filter(({ id }) =>
      activeItem.tasks.includes(id),
    ) as TaskCardTask[];
    goalSectionTasks.forEach((task) => {
      task.fields.goalId = overParent.id;
    });
    return {
      items: [...items],
      changedItems: [activeParent, overParent, ...goalSectionTasks],
    };
  }

  if (
    over.data.current?.type === DraggableOptions.Goal &&
    activeParent.id !== overItem.id
  ) {
    const overGoal = overItem as Goal;
    const overIndex = items.findIndex(({ id }) => id === overGoal.id);
    const newIndex =
      activeParentIndex < overIndex ? 0 : overGoal.sections?.length ?? 0;
    // add goal-section to overGoal
    overGoal.sections = overGoal.sections?.toSpliced(
      newIndex,
      0,
      activeItem,
    ) ?? [activeItem];
    // remove goal-section from parent-goal
    activeParent.sections =
      activeParent.sections?.filter(({ id }) => id !== activeItem.id) ?? null;

    // update all tasks within the goal-section to have a new goal
    const goalSectionTasks = items.filter(({ id }) =>
      activeItem.tasks.includes(id),
    ) as TaskCardTask[];
    goalSectionTasks.forEach((task) => {
      task.fields.goalId = overGoal.id;
    });

    return {
      items: [...items],
      changedItems: [activeParent, overGoal, ...goalSectionTasks],
    };
  }

  return { items, changedItems: [] };
};
