import { index, Options } from 'shared/assets/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(20)};
  right: ${({ theme }) => theme.spacing(4)};

  z-index: ${index(Options.MOBILE_ADD_BUTTON)};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;
