import React, { useEffect, useRef, useState } from 'react';
import { AutoSizeTextArea } from 'shared/components/ui/auto-size-text-area';

export type NotesProps = {
  value: string | null;
  placeholder?: string;
  onChange: (value: string | null) => void;
  isFrozen?: boolean;
};

export const Description: React.FC<NotesProps> = ({
  value = '',
  onChange,
  placeholder,
  isFrozen,
}) => {
  const [description, setDescription] = useState(value);
  const timeoutRef = useRef<number>();

  const clearSubmit = () => {
    !!timeoutRef.current && window.clearTimeout(timeoutRef.current);
  };

  const delayedSubmit = (value: string) => {
    clearSubmit();
    timeoutRef.current = window.setTimeout(() => onChange(value), 500);
  };

  const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    delayedSubmit(e.target.value);
  };

  useEffect(() => {
    setDescription(value);
    clearSubmit();
  }, [value]);

  return (
    <AutoSizeTextArea
      value={description ?? ''}
      onChange={_onChange}
      placeholder={placeholder}
      disabled={isFrozen}
    />
  );
};
