import { Over } from '@dnd-kit/core';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection } from 'shared/types/goal-section';

import { Item } from './types';

export const handleGoalBetweenSections = (
  activeItem: Goal,
  overItem: Item | GoalSection,
  items: Item[],
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  // when moving a goal over a task, prevent the goal from swapping places with the task
  if (over.data.current?.type === DraggableOptions.Task) {
    return { items: [...items], changedItems: [] };
  }
  // when moving a goal over a goal-section, prevent the goal from swapping places with the goal-section
  if (over.data.current?.type === DraggableOptions.GoalSection) {
    return { items: [...items], changedItems: [] };
  }

  return { items, changedItems: [] };
};
