import styled, { css } from 'styled-components';

export const SortableContainer = styled.div<{
  $isDragging: boolean;
}>`
  position: relative;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      > * {
        opacity: 0;
      }

      &:after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: ${({ theme }) => theme.spacing(2)};
        background-color: ${({ theme }) => theme.palette.primary.light};
      }
    `};
`;

export const Container = styled.div<{
  $isSelected?: boolean;
  $isDisabled?: boolean;
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) =>
    `${theme.spacing(0.75)} ${theme.spacing(3)} ${theme.spacing(0.75)} ${theme.spacing(1)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};

  background: ${({ theme }) => theme.palette.background.default};

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${theme.palette.background.paper};
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
    `};
`;

export const CheckboxContainer = styled.div<{ $isFaded?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacing(0.25)};
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;

  line-height: 62.5%;
  font-size: 2rem;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 3.2rem;
      height: 3.2rem;
      min-width: 3.2rem;
      min-height: 3.2rem;
    `};

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.3;
    `};
`;

export const TitleContainer = styled.div<{ $isFaded?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.75)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  min-width: 0;

  line-height: 1;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      opacity: 0.5;
      color: ${theme.palette.text.secondary};
    `}

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-top: ${({ theme }) => theme.spacing(1.5)};
    `};
`;
