import {
  addYears,
  endOfYear,
  isAfter,
  isBefore,
  isSameDay,
  isSameYear,
} from 'date-fns';
import { Goal } from 'shared/types/goal';
import { Timestamp } from 'shared/types/timestamp';

export enum GoalsFilterOptions {
  Active = 'active',
  Unplanned = 'unplanned',
  Frozen = 'frozen',
  Completed = 'completed',
  Archived = 'archived',
  ThisYear = 'this-year',
  NextYear = 'next-year',
}

export const goalsFilters: Record<
  GoalsFilterOptions,
  (goals: Goal[], today: Timestamp) => Goal[]
> = {
  [GoalsFilterOptions.Unplanned]: (goals) =>
    goals.filter(
      ({ completedAt, archivedAt, startDate, deadline }) =>
        !archivedAt && !completedAt && !startDate && !deadline,
    ),
  [GoalsFilterOptions.Active]: (goals, today) =>
    goals.filter(
      ({ completedAt, archivedAt, startDate }) =>
        !archivedAt &&
        !completedAt &&
        !!startDate &&
        (isBefore(startDate, today) || isSameDay(startDate, today)),
    ),
  [GoalsFilterOptions.Frozen]: (goals) =>
    goals.filter(
      ({ completedAt, archivedAt, frozenAt }) =>
        !completedAt && !archivedAt && !!frozenAt,
    ),
  [GoalsFilterOptions.Completed]: (goals) =>
    goals.filter(({ archivedAt, completedAt }) => !archivedAt && !!completedAt),
  [GoalsFilterOptions.Archived]: (goals) =>
    goals.filter(({ archivedAt }) => !!archivedAt),
  [GoalsFilterOptions.ThisYear]: (goals, today) =>
    goals.filter(
      ({ archivedAt, completedAt, startDate, deadline }) =>
        !completedAt &&
        !archivedAt &&
        // startDate in this year after today or deadline in this year after today when there is no startDate
        ((startDate &&
          isAfter(startDate, today) &&
          isSameYear(startDate, today)) ||
          (!startDate &&
            deadline &&
            isAfter(deadline, today) &&
            isSameYear(deadline, today))),
    ),
  [GoalsFilterOptions.NextYear]: (goals, today) =>
    goals.filter(
      ({ archivedAt, completedAt, startDate, deadline }) =>
        !completedAt &&
        !archivedAt &&
        // startDate in next year or deadline in next year when there is no startDate
        ((startDate &&
          isAfter(startDate, endOfYear(today)) &&
          isBefore(startDate, addYears(endOfYear(today), 1))) ||
          (!startDate &&
            deadline &&
            isAfter(deadline, endOfYear(today)) &&
            isBefore(deadline, addYears(endOfYear(today), 1)))),
    ),
};
