import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { NewSubTaskCard } from 'shared/components/connected/sub-tasks-list/new-sub-task-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './reorderable-sub-task-list.style';
import { SubTaskCard } from './sub-task-card';

export type ReorderableSubTaskListProps = {
  tasks: TaskCardTask[];
  updateSubTask: (changes: {
    id: ID;
    name?: string;
    completedAt?: Timestamp | null;
  }) => void;
  createSubTask: (name: string) => void;
  isDisabled?: boolean;
  onReorder: (ids: ID[]) => void;
};

export const ReorderableSubTaskList: React.FC<ReorderableSubTaskListProps> = ({
  tasks,
  updateSubTask,
  createSubTask,
  isDisabled,
  onReorder,
}) => {
  const {
    items,
    activeItem,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({ items: tasks, onDragEnd: onReorder });
  const sensors = useSortableSensors();

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
      >
        <Styled.List>
          {items.map((task) => (
            <Styled.ListItem key={task.id}>
              <SubTaskCard
                task={task.raw.task}
                onChange={updateSubTask}
                isDisabled={isDisabled || !!activeItem}
              />
            </Styled.ListItem>
          ))}
          <Styled.ListItem>
            <NewSubTaskCard onSubmit={createSubTask} disabled={isDisabled} />
          </Styled.ListItem>
        </Styled.List>

        {!!activeItem && (
          <SortableOverlay>
            <OverlayComponentWrapper>
              <SubTaskCard task={activeItem.raw.task} isSelected isDisabled />
            </OverlayComponentWrapper>
          </SortableOverlay>
        )}
      </SortableContext>
    </DndContext>
  );
};
