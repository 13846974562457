import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const Circle = styled.span<{
  $percentage: number;
  $isSmall: boolean;
}>`
  position: relative;
  display: inline-block;

  width: 1.15em;
  height: 1.15em;

  border-radius: 50%;
  border: 1px solid currentColor;

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    inset: 0.2rem;
    background: ${({ $percentage }) =>
      `conic-gradient(currentColor ${$percentage}%,#0000 0)`};
  }

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      width: 1em;
      height: 1em;
    `}
`;

export const Label = styled.span<{
  $isSecondary: boolean;
  $isPrimary: boolean;
}>`
  ${({ $isSecondary, theme }) =>
    $isSecondary &&
    css`
      color: ${theme.palette.text.secondary};
    `};

  ${({ $isPrimary, theme }) =>
    $isPrimary &&
    css`
      color: ${theme.palette.text.primary};
    `};
`;
