import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { useCallback, useEffect, useState } from 'react';
import { ID } from 'shared/types/id';
import { DateSection } from 'shared/types/orderable-section';
import { TaskCardTask } from 'shared/types/task-card-task';
import {
  handleItemBetweenSectionsMovement,
  handleItemWithinSectionMovement,
} from 'shared/utils/handle-section-item-movement';

export const useDroppableDatesControls = ({
  initialSections,
  onDragEnd,
}: {
  initialSections: DateSection[];
  onDragEnd?: (sections: DateSection[], movedTask: TaskCardTask) => void;
}) => {
  const [currentSections, setCurrentSections] =
    useState<DateSection[]>(initialSections);
  const [activeTaskId, setActiveTaskId] = useState<ID>();

  useEffect(() => {
    setCurrentSections(initialSections);
  }, [initialSections]);

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => setActiveTaskId(active.id.toString()),
    [],
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over?.id || over.id === active.id || !activeTaskId) {
        return;
      }

      setCurrentSections((oldSections) => {
        const { sections } = handleItemBetweenSectionsMovement(
          oldSections,
          active,
          over,
        );

        return sections;
      });
    },
    [activeTaskId],
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveTaskId(undefined);
      if (!over?.id || !activeTaskId) {
        return;
      }

      const { sections, activeItem: activeTask } =
        handleItemWithinSectionMovement<TaskCardTask, DateSection>(
          currentSections,
          active,
          over,
        );

      if (!activeTask) {
        return;
      }

      return onDragEnd?.(sections, activeTask);
    },
    [activeTaskId, currentSections, onDragEnd],
  );

  const handleDragCancel = useCallback(() => {
    setCurrentSections(initialSections);
    setActiveTaskId(undefined);
  }, [initialSections]);

  return {
    sections: currentSections,
    activeId: activeTaskId,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
  };
};
