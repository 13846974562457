import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconFormIcons,
  IconFormNames,
  Lock,
  Target,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { Image } from 'shared/types/image';

import * as Styled from './small-screen-life-area-card.style';

export type SmallScreenLifeAreaCardProps = {
  name: string;
  image: Image | null;
  iconName: IconFormNames | null;
  color?: string | null;
  goalCount?: number;
  isFrozen?: boolean;
  isArchived?: boolean;
};

export const SmallScreenLifeAreaCard: React.FC<
  SmallScreenLifeAreaCardProps
> = ({ name, image, iconName, color, goalCount = 0, isFrozen, isArchived }) => {
  const { t } = useTranslation();
  const icon = iconName ? IconFormIcons[iconName] : Target;

  return (
    <Styled.Container>
      <Styled.IllustrationContainer>
        <Styled.ImageContainer>
          {!image && !!icon ? (
            <Icon icon={icon!} />
          ) : (
            <Styled.Image src={image?.url} alt={name} hash={image?.blurHash} />
          )}
        </Styled.ImageContainer>

        {!!color && <Styled.ColorDot $color={color} />}
      </Styled.IllustrationContainer>

      <Styled.Content>
        <Styled.Title $isFaded={!!isFrozen || !!isArchived}>
          {name}
        </Styled.Title>

        {!isArchived && (
          <Styled.Description $count={goalCount}>
            {t(
              'general.labels.life-area-overview.card.description.goal-count',
              {
                count: goalCount,
              },
            )}
          </Styled.Description>
        )}
      </Styled.Content>

      {isFrozen && (
        <Styled.IconContainer>
          <Icon icon={Lock} />
        </Styled.IconContainer>
      )}
    </Styled.Container>
  );
};
