import { useMutation, useQueryClient } from '@tanstack/react-query';
import { resetPasswordAndLogin, updateUser } from 'shared/services/backend-api';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';
import { User } from 'shared/types/user';

import { queryKey as useUserKey } from './use-user-query';

export const useResetPasswordAndLoginMutation = () => {
  const client = useQueryClient();

  return useMutation<
    User | null,
    ResetPasswordErrorTypes,
    {
      password: string;
      code: string;
      name?: string;
    }
  >({
    mutationFn: async ({ password, code, name }) => {
      const user = await resetPasswordAndLogin(code, password);

      if (name) {
        await updateUser({ name });
      }
      await client.invalidateQueries({ queryKey: [useUserKey] });

      return user;
    },
  });
};
