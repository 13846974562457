import React from 'react';

import * as Styled from './circle-progress.style';

export type CircleProgressProps = {
  percentage: number;
  hidePercentage?: boolean;
  isPrimary?: boolean;
  isSecondary?: boolean;
  isSmall?: boolean;
};

export const CircleProgress: React.FC<CircleProgressProps> = ({
  percentage,
  hidePercentage,
  isPrimary,
  isSecondary,
  isSmall,
}) => (
  <Styled.Container>
    <Styled.Circle $percentage={percentage} $isSmall={!!isSmall} />

    {!hidePercentage && (
      <Styled.Label $isPrimary={!!isPrimary} $isSecondary={!!isSecondary}>
        {percentage}%
      </Styled.Label>
    )}
  </Styled.Container>
);
