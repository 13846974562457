import { Locales } from 'shared/assets/localization';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { ThemeModes } from 'shared/types/theme-modes';
import { FavoriteReference, Notifications } from 'shared/types/user-settings';
import { WeekDays } from 'shared/types/week-days';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export type UserFields = {
  name: string;
  lastName: string;
  language: Locales;
  dateFormat: DateFormatOptions;
  startOfWeek: WeekDays;
  themePreference: ThemeModes;
  notifications: Notifications;
  favorites: FavoriteReference[];
  metaSource: string;
};

export const updateUser = async ({
  language,
  dateFormat,
  startOfWeek,
  favorites,
  themePreference,
  notifications,
  metaSource,
  ...baseFields
}: Partial<UserFields>) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  let data: {} = dateToFirestoreTimestamp({ ...baseFields });

  if (language) {
    data = { ...data, 'settings.language': language };
  }

  if (dateFormat) {
    data = { ...data, 'settings.dateFormat': dateFormat };
  }

  if (startOfWeek) {
    data = { ...data, 'settings.startOfWeek': startOfWeek };
  }

  if (favorites) {
    data = { ...data, 'settings.favorites': favorites };
  }

  if (themePreference) {
    data = { ...data, 'settings.themePreference.desktop': themePreference };
  }

  if (notifications) {
    Object.entries(notifications).forEach(([key, { desktop, mobile }]) => {
      if (typeof desktop === 'boolean') {
        data = { ...data, [`settings.notifications.${key}.desktop`]: desktop };
      }
      if (typeof mobile === 'boolean') {
        data = { ...data, [`settings.notifications.${key}.mobile`]: mobile };
      }
    });
  }

  if (metaSource) {
    data = { ...data, 'user_metadata.source': metaSource };
  }

  updateDocWithTimestamp(userRef, data);
};
