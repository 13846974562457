import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: auto;
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
`;

export const PremiumBannerContainer = styled.div`
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(3)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};
`;

export const ArchivedContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
