import Dialog from '@mui/material/Dialog';
import React from 'react';

import { DeleteGoalSectionMessage } from './delete-goal-section-message';

export type DeleteGoalSectionDialogProps = {
  open: boolean;
  name?: string;
  onClose: () => void;
  onSubmit: (deleteTasks: boolean) => void;
};

export const DeleteGoalSectionDialog: React.FC<
  DeleteGoalSectionDialogProps
> = ({ open, name, onClose, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DeleteGoalSectionMessage
        name={name}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    </Dialog>
  );
};
