import React from 'react';
import { Check, Lock } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { Image } from 'shared/types/image';

import * as Styled from './data-grid-name-image-cell.style';

export type DataGridNameImageCellProps = {
  name: string;
  image?: Image | null;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: string | null;
  isFrozen?: boolean;
  isCompleted?: boolean;
};

export const DataGridNameImageCell: React.FC<DataGridNameImageCellProps> = ({
  name,
  image,
  icon,
  color,
  isFrozen,
  isCompleted,
}) => (
  <Styled.Container>
    <Styled.IllustrationContainer>
      <Styled.ImageContainer>
        {!image && !!icon ? (
          <Icon icon={icon!} />
        ) : (
          <Styled.Image src={image?.url} alt={name} hash={image?.blurHash} />
        )}
      </Styled.ImageContainer>

      {isCompleted && (
        <Styled.CompletedContainer>
          <Icon icon={Check} />
        </Styled.CompletedContainer>
      )}

      {!!color && <Styled.ColorDot $color={color} />}
    </Styled.IllustrationContainer>

    <Styled.Title $isCompleted={!!isCompleted} $isFaded={!!isFrozen}>
      {name}
    </Styled.Title>

    {isFrozen && (
      <Styled.IconContainer>
        <Icon icon={Lock} />
      </Styled.IconContainer>
    )}
  </Styled.Container>
);
