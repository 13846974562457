import React, { useState } from 'react';
import { TaskCard } from 'shared/components/connected/task-card';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { DraggableOptions } from 'shared/types/draggable-types';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';

export type TaskProps = {
  task: TaskCardTask;

  selectedItem?: ID;
  onTask?: (id?: ID) => void;
  onUnfocus?: (id: ID) => void;
  hideGoal?: boolean;
  showRepeatingAsTodo?: boolean;
  isSortingDisabled?: boolean;
  hasDnd?: boolean;
};

export const Task: React.FC<TaskProps> = ({
  task,

  selectedItem,
  onTask,
  onUnfocus,
  hideGoal,
  showRepeatingAsTodo,
  isSortingDisabled,
  hasDnd,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const Container = hasDnd ? SortableContainer : React.Fragment;
  const containerProps = hasDnd
    ? {
        id: task.id,
        type: DraggableOptions.Task,
        isEditing,
        isDisabled: isSortingDisabled,
        hasHoverStyle: false,
      }
    : {};

  return (
    // @ts-ignore
    <Container {...containerProps}>
      <TaskCard
        task={task.raw.task}
        onUnfocus={onUnfocus}
        onClick={onTask}
        isSelected={selectedItem === task.id}
        hideGoal={hideGoal}
        showRepeatingAsTodo={showRepeatingAsTodo}
        onEditChange={setIsEditing}
      />
    </Container>
  );
};
