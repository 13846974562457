import {
  and,
  getDocs,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  where,
} from 'firebase/firestore';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';

import { taskConverter } from './converters/task';
import { CollectionOptions, getAuthentication, getCollection } from './helpers';

export type Options = {
  type?: TaskType;
  ids?: ID[];
  deadlineStartDate?: Timestamp;
  deadlineEndDate?: Timestamp;
  noDeadlineOnly?: boolean;
  uncompletedOnly?: boolean;
  completedOnly?: boolean;
  noScheduleOnly?: boolean;
  noGoalsOnly?: boolean;
  noSubTasksOnly?: boolean;
  unFrozenOnly?: boolean;
  unArchivedOnly?: boolean;
  archivedOnly?: boolean;
  completedAt?: Timestamp;
  goals?: ID[];
  lifeAreas?: ID[];
};

export const getTasks = async (options: Options) => {
  const auth = await getAuthentication();

  const conditions = [
    where('roles.all', 'array-contains', auth.currentUser?.uid),

    !!options.ids?.length && where('id', 'in', options.ids),
    !!options.type && where('type', '==', options.type),
    !!options.deadlineStartDate &&
      where('endStrategy.deadline', '>=', options.deadlineStartDate),
    !!options.deadlineEndDate &&
      where('endStrategy.deadline', '<=', options.deadlineEndDate),
    !!options.noDeadlineOnly && where('endStrategy.deadline', '==', null),
    !!options.noScheduleOnly && where('schedules', '==', null),
    !!options.uncompletedOnly && where('completedAt', '==', null),
    !!options.completedOnly && where('completedAt', '!=', null),
    !!options.unFrozenOnly && where('frozenAt', '==', null),
    !!options.unArchivedOnly && where('archivedAt', '==', null),
    !!options.archivedOnly && where('archivedAt', '!=', null),
    !!options.noGoalsOnly && where('goalId', '==', null),
    !!options.completedAt && where('completedAt', '>=', options.completedAt),
    !!options.goals?.length && where('goalId', 'in', options.goals),
    !!options.lifeAreas?.length && where('lifeAreaId', 'in', options.lifeAreas),
  ].filter(Boolean) as QueryFieldFilterConstraint[];

  return query(
    getCollection(CollectionOptions.Tasks).withConverter(taskConverter),
    and(...conditions),
    orderBy('createdAt', 'asc'),
  );
};

export const getTaskDocs = async (options: Options) => {
  const query = await getTasks(options);
  const snapshot = await getDocs(query);
  return snapshot.docs.map((task) => task.data());
};
