import React, { useMemo } from 'react';
import { Target } from 'shared/assets/icons';
import { ActionPlanGoalHeader } from 'shared/components/ui/action-plan-goal-header';
import { Collapse } from 'shared/components/ui/collapse';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { useToggle } from 'shared/hooks/use-toggle';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal as GoalType } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';

import * as Styled from './goal.style';
import { GoalSectionTaskList } from './goal-section-task-list';
import { OverwriteCollapseOptions } from './types';

export type GoalProps = {
  goal: GoalType;
  allGoals: GoalType[];
  lifeAreas: LifeArea[];

  imagePlaceholderIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onUpdateGoalProgress?: (id: ID) => void;
  onPremium: () => void;
  isDragging?: boolean;
  isMoving?: boolean;
  isHovered?: boolean;

  items: (GoalType | TaskCardTask)[];
  overwriteCollapse?: OverwriteCollapseOptions;
  onUpdateSection: (sectionId: ID, sectionName: string) => void;
  canCreateTask?: boolean;
  onTask?: (id?: ID) => void;
  onUnfocus?: (id: ID) => void;
  hideGoal?: boolean;
  showRepeatingAsTodo?: boolean;
  selectedItem?: ID;
  canCollapse?: boolean;
  initialOpen?: boolean;
  hasDnd?: boolean;
};

export const Goal: React.FC<GoalProps> = ({
  goal,
  allGoals,
  lifeAreas,

  imagePlaceholderIcon = Target,
  onUpdateGoalProgress,
  onPremium,
  isDragging,
  isMoving,
  isHovered,

  items,
  overwriteCollapse = OverwriteCollapseOptions.No,
  canCreateTask,
  onUpdateSection,
  onTask,
  onUnfocus,
  hideGoal,
  showRepeatingAsTodo,
  selectedItem,
  canCollapse,
  initialOpen,
  hasDnd,
}) => {
  const [collapseOpen, toggleCollapse] = useToggle(initialOpen);

  const completionPercentage = useMemo(
    () =>
      getCompletionPercentage(
        getGoalCompletion(goal, {
          goals: items.filter((item) => !('fields' in item)) as GoalType[],
        }),
      ),
    [goal, items],
  );

  const goalColor = useMemo(
    () => getGoalColor(goal.id, { goals: allGoals, lifeAreas }) ?? undefined,
    [allGoals, goal.id, lifeAreas],
  );

  const Container = hasDnd ? SortableContainer : React.Fragment;
  const containerProps = hasDnd
    ? {
        id: goal.id,
        type: DraggableOptions.Goal,
        hasHoverStyle:
          overwriteCollapse === OverwriteCollapseOptions.No
            ? !collapseOpen
            : overwriteCollapse !== OverwriteCollapseOptions.Open,
      }
    : {};

  const CollapseContainer = canCollapse ? Collapse : React.Fragment;
  const collapseProps = canCollapse
    ? {
        isOpen:
          overwriteCollapse === OverwriteCollapseOptions.No
            ? collapseOpen
            : overwriteCollapse === OverwriteCollapseOptions.Open,
      }
    : {};

  return (
    // @ts-ignore
    <Container {...containerProps}>
      <ActionPlanGoalHeader
        id={goal.id}
        name={goal.name}
        image={goal.image}
        iconName={goal.iconName}
        color={goalColor}
        progress={completionPercentage}
        deadline={goal.deadline}
        metric={goal.metric}
        isMainGoal={!goal.parentIds?.length}
        isCompleted={!!goal.completedAt}
        isArchived={!!goal.archivedAt}
        isFrozen={!!goal.frozenAt}
        imagePlaceholderIcon={imagePlaceholderIcon}
        onOpen={toggleCollapse}
        isOpen={collapseOpen}
        onUpdateGoalProgress={onUpdateGoalProgress}
        onPremium={onPremium}
        isDragging={isDragging}
        isMoving={isMoving}
        isHovered={isHovered}
      />
      {!isMoving && (
        <CollapseContainer {...collapseProps}>
          <Styled.SublistContainer>
            <GoalSectionTaskList
              goalId={goal.id}
              imagePlaceholderIcon={imagePlaceholderIcon}
              items={items}
              allGoals={allGoals}
              lifeAreas={lifeAreas}
              onPremium={onPremium}
              onUpdateGoalProgress={onUpdateGoalProgress}
              canCreateTask={canCreateTask}
              onUpdateSection={onUpdateSection}
              onTask={onTask}
              onUnfocus={onUnfocus}
              hideGoal={hideGoal}
              showRepeatingAsTodo={showRepeatingAsTodo}
              selectedItem={selectedItem}
              canCollapse={canCollapse}
              initialOpen={initialOpen}
              hasDnd={hasDnd}
              noRightSpacing
            />
          </Styled.SublistContainer>
        </CollapseContainer>
      )}
    </Container>
  );
};
