import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Button = styled.button<{ $hasSideNavButton: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};

  width: 100%;
  margin: 0;
  padding: ${({ $hasSideNavButton, theme }) =>
    $hasSideNavButton
      ? `${theme.spacing(1.5)} ${theme.spacing(2)}
      ${theme.spacing(1.5)} ${theme.spacing(3)}`
      : `${theme.spacing(1.5)} ${theme.spacing(3)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: none;
  background: transparent;
  transition: background 200ms;
  cursor: pointer;

  color: inherit;

  &:hover {
    background: ${({ theme }) => theme.palette.brown['50']};
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  min-width: 0;
`;
export const AvatarContainer = styled.div`
  position: relative;
`;

export const Name = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.4rem;
  min-width: 2.4rem;
  height: 2.4rem;
  min-height: 2.4rem;

  border-radius: 50%;
  background: ${({ theme }) => theme.palette.brown['100']};

  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.brown['200']};
`;

export const Image = styled.img`
  width: 2.4rem;
  height: 2.4rem;

  border-radius: 50%;
`;

export const ChevronContainer = styled.span<{ $open: boolean }>`
  transform: rotate(${({ $open }) => ($open ? '90deg' : '-90deg')});
  transition: transform 200ms ease-in-out;

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const SideNavigationButton = styled.span`
  display: none;
  margin-left: auto;
  font-size: 2rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.grey['500']};

  ${({ theme }) => theme.breakpoints.up(theme.breakpoints.values.md)} {
    display: inline-block;
  }
`;

export const NewVersionBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: block;

  width: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(2)};

  border-radius: 50%;
  background: ${({ theme }) => theme.palette.error.main};
  transform: translate(20%, -20%);
`;
