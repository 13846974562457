import { useGoalById, useGoalControls, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Plus } from 'shared/assets/icons';
import { SelfSortingGoalSectionTaskList } from 'shared/components/ui/goal-section-task-list';
import { IconButton } from 'shared/components/ui/icon-button';
import { Section } from 'shared/components/ui/section';
import { Sizes } from 'shared/components/ui/section/types';
import { SelfSortingTasksList } from 'shared/components/ui/tasks-list';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import {
  useActiveTaskId,
  useOpenTaskDetail,
} from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useHandleTaskGoalAndSectionChanges } from 'shared/hooks/use-handle-task-goal-and-section-changes';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { useTheme } from 'styled-components';

import { useActionPlanItems } from '../../hooks/use-action-plan-items';
import { ActionPlanContextMenu } from '../action-plan-context-menu';
import * as Styled from './action-plan.style';

export const ActionPlan: React.FC = () => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const today = useToday();
  const selectedTask = useActiveTaskId();

  const { id } = useParams();
  const openTaskDetail = useOpenTaskDetail();
  const actionContextButtonRef = useRef<HTMLButtonElement>(null);
  const {
    menuOpen: actionContextOpen,
    openMenu: openActionContext,
    closeMenu: closeActionContext,
  } = useOpenMenu();

  const user = useUser();
  const lifeAreas = useLocalizedLifeAreas();
  const openPremiumDialog = useOpenPremiumDialog();

  const [selectedItem, setSelectedItem] = useState<ID>();
  const clearSelectedItem = () => setSelectedItem(undefined);

  const goal = useGoalById(id);
  const goals = useGoals();

  const mainParentIds = useMemo(
    () => [...(goal?.parentIds ?? []), goal?.id!],
    [goal],
  );
  const { items, completedTasks, tasks, subTasks, archivedSubGoals } =
    useActionPlanItems();
  const allTasks = useMemo(
    () =>
      [...tasks, ...subTasks].map((task) =>
        mapTaskToTaskCard(task, {
          goals,
          t,
          today,
          showDue: true,
          hideGoal: true,
          weekStartsOn: user?.settings?.startOfWeek ?? initialWeekStartsOn,
        }),
      ),
    [goals, subTasks, t, tasks, today, user?.settings?.startOfWeek],
  );
  const onTask = (id?: ID) => {
    setSelectedItem(id);
    const taskCardTask = allTasks.find(
      (taskCardTask) => taskCardTask.id === id,
    );
    if (taskCardTask) {
      openTaskDetail(taskCardTask.id);
    }
  };

  const onReorder = useHandleTaskGoalAndSectionChanges(items);
  const { onCreateSubGoal, onUpdateGoal, onUpdateGoalProgress } =
    useGoalControls(goals, {
      onCreatePresets: { lifeAreaId: goal?.lifeAreaId },
    });

  const onUpdateSection = (sectionId: ID, sectionName: string) => {
    const goal = goals.find((goal) =>
      goal.sections?.find(({ id }) => id === sectionId),
    );
    if (!goal) {
      return;
    }

    const sections = goal.sections!.map((section) => ({
      ...section,
      name: section.id === sectionId ? sectionName : section.name,
    }));

    onUpdateGoal({ id, sections });
  };

  const _onCreateSubGoal = () => {
    if (!goal) {
      return;
    }

    onCreateSubGoal([...(goal.parentIds ?? []), goal.id]);
  };

  return (
    <Styled.Container>
      <Section
        title={t('pages.goal-detail.action-plan.title')}
        actions={[
          // <Styled.SortIconContainer>
          //   <IconButton icon={Sort} />
          // </Styled.SortIconContainer>,
          <IconButton
            icon={Plus}
            ref={actionContextButtonRef}
            onClick={openActionContext}
            size={theme.isMobile ? Sizes.Large : Sizes.Medium}
          />,
        ]}
      >
        <SelfSortingGoalSectionTaskList
          goalId={goal?.id ?? ''}
          allGoals={goals}
          lifeAreas={lifeAreas}
          onUpdateGoalProgress={onUpdateGoalProgress}
          onPremium={openPremiumDialog}
          onUpdateSection={onUpdateSection}
          onTask={onTask}
          onUnfocus={clearSelectedItem}
          hideGoal
          selectedItem={selectedTask ?? selectedItem}
          items={items}
          onCreateSubGoal={
            mainParentIds.length > 1 ? undefined : _onCreateSubGoal
          }
          onReorder={onReorder}
          newTaskInputRef={inputRef}
          canCreateTask
          canCollapse
        />
        {!!completedTasks.length && (
          <Styled.SectionContainer>
            <Section
              title={t('pages.goal-detail.tasks.completed.title')}
              titleCount={completedTasks.length}
              size={Sizes.Small}
              canCollapse
            >
              <SelfSortingTasksList
                tasks={completedTasks}
                selectedTask={selectedTask}
              />
            </Section>
          </Styled.SectionContainer>
        )}
        {!!archivedSubGoals.length && (
          <Styled.SectionContainer>
            <Section
              title={t('pages.goal-detail.tasks.archived.title')}
              titleCount={archivedSubGoals.length}
              size={Sizes.Small}
              canCollapse
            >
              <SelfSortingGoalSectionTaskList
                goalId={goal?.id ?? ''}
                allGoals={goals}
                lifeAreas={lifeAreas}
                onUpdateGoalProgress={onUpdateGoalProgress}
                onPremium={openPremiumDialog}
                onUpdateSection={onUpdateSection}
                onTask={onTask}
                onUnfocus={clearSelectedItem}
                hideGoal
                selectedItem={selectedTask ?? selectedItem}
                items={archivedSubGoals}
              />
            </Section>
          </Styled.SectionContainer>
        )}
      </Section>
      {actionContextOpen && (
        <ActionPlanContextMenu
          referenceElement={actionContextButtonRef}
          onClose={closeActionContext}
          newTaskRef={inputRef}
          id={id}
          parentIds={mainParentIds}
          lifeAreaId={goal?.lifeAreaId}
        />
      )}
    </Styled.Container>
  );
};
