import { rgba } from 'polished';
import { dragging } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css } from 'styled-components';

export const UnfoldButton = styled.button`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing(0.5)};

  margin: 0;
  padding: ${({ theme }) => theme.spacing(0.5)};

  border: none;
  border-radius: ${({ theme }) => theme.spacing(1.5)};
  background: none;
  cursor: pointer;
  transform: translateY(-50%);
  transition: background 200ms;

  color: ${({ theme }) => theme.palette.grey['400']};

  &:hover {
    background: ${({ theme }) => theme.palette.brown['50']};
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const Container = styled.article<{
  $isDragging: boolean;
  $isMoving: boolean;
  $isArchived: boolean;
}>`
  position: relative;
  padding: ${({ theme }) => `0 0 0 ${theme.spacing(3)}`};

  ${({ $isDragging, $isMoving }) =>
    ($isDragging || $isMoving) &&
    css`
      ${UnfoldButton} {
        display: none;
      }
    `};

  ${({ $isArchived }) =>
    $isArchived &&
    css`
      opacity: 0.5;
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
      margin: ${({ theme }) => `${theme.spacing(1)}  ${theme.spacing(2)}`};
    `};
`;

export const Body = styled.div<{
  $isDragging: boolean;
  $isHovered: boolean;
  $isOpen: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) =>
    `${theme.spacing(1.5)} ${theme.spacing(5)}
  ${theme.spacing(1.5)} ${theme.spacing(2)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.default};

  ${({ $isHovered, $isDragging, $isOpen, theme }) =>
    $isHovered &&
    !$isDragging &&
    !$isOpen &&
    css`
      background: ${theme.palette.grey['50']};
    `};

  ${({ $isDragging }) => $isDragging && dragging};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(3)}`};
      box-shadow: ${({ theme }) => theme.shadowBox.card};
    `};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  min-width: 0;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column;
      gap: ${theme.spacing(0.5)};
    `};
`;

export const LinkContainer = styled.div<{ $isCompleted: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  min-width: 2.6rem;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isCompleted }) =>
    $isCompleted &&
    css`
      text-decoration: line-through;
    `};
`;

export const UnfoldIconContainer = styled.span<{ $open: boolean }>`
  display: block;

  transform: rotate(180deg);
  transition: transform 200ms;

  font-size: 1.4rem;
  line-height: 62.5%;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(270deg);
    `}
`;

export const IllustrationContainer = styled.div`
  position: relative;
`;

const ImageContainer = css<{ $isCompleted: boolean }>`
  display: block;
  position: relative;

  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.brown['100']};

  color: ${({ theme }) => theme.palette.brown['300']};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      min-height: 4rem;
    `};
`;

export const BlurImageContainer = styled.div<{ $isCompleted: boolean }>`
  ${ImageContainer};

  overflow: hidden;
`;

export const ImageIconContainer = styled.span<{ $isCompleted: boolean }>`
  ${ImageContainer};
  padding: ${({ theme }) => theme.spacing(1)};

  font-size: 1.6rem;
  line-height: 62.5%;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) => theme.spacing(2.5)};
      font-size: 2rem;
    `};
`;

export const CompletedIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;

  width: 1.2rem;
  height: 1.2rem;

  border-radius: 50%;
  background: ${({ theme }) => theme.palette.success.main};
  transform: translate(25%, -25%);

  color: ${({ theme }) => theme.palette.success.contrastText};
  font-size: 0.8rem;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 1.6rem;
      height: 1.6rem;
      font-size: 1rem;
    `};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const LinkIconContainer = styled.span<{ $isFaded: boolean }>`
  display: block;

  opacity: 0;
  transform: rotate(180deg);
  transition: opacity 200ms;

  font-size: 1.6rem;
  line-height: 62.5%;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      margin-left: ${theme.spacing(2)};
      opacity: 1;
      transform: rotate(0deg);
    `};
`;

export const Link = styled(QueryNavLink)<{
  $isMain: boolean;
  $isFrozen: boolean;
}>`
  ${({ $isMain, theme }) =>
    typographyToCss(
      $isMain ? theme.typography.h6 : theme.typography.subtitle2,
    )};
  display: flex;
  align-items: center;
  min-width: 0;

  text-decoration: none;
  color: ${({ $isFrozen, theme }) =>
    $isFrozen ? theme.palette.text.secondary : 'inherit'};

  @media (any-hover: hover) {
    &:hover {
      ${LinkIconContainer} {
        opacity: 1;
      }
    }
  }
`;

export const LinkLabel = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MetaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      gap: ${theme.spacing(3)};
    `};
`;

export const MetaEntry = styled.div<{
  $isFrozen: boolean;
  $type: 'progress' | 'deadline';
}>`
  ${({ theme }) =>
    typographyToCss(
      theme.isMobile ? theme.typography.overline : theme.typography.body2,
    )};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  ${({ $type }) => {
    switch ($type) {
      case 'progress':
        return css`
          width: 6.4rem;
          color: ${({ theme }) => theme.palette.primary.main};
        `;
      default:
        return css`
          width: 12.8rem;
        `;
    }
  }}

  ${({ $isFrozen, theme }) =>
    $isFrozen &&
    css`
      color: ${theme.palette.text.secondary};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: unset;
      padding: 0;

      color: ${theme.palette.text.secondary};
    `};
`;

export const ProgressButton = styled.button`
  display: flex;
  align-items: center;

  margin: 0;
  padding: ${({ theme }) => theme.spacing(1)};

  border-radius: ${({ theme }) => theme.spacing(3)};
  border: none;
  background: transparent;
  transition: background 200ms;

  color: inherit;

  ${({ theme, onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${rgba(theme.palette.primary.main, 0.08)};
      }
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};
`;

export const MobileIconButtonContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey['500']};

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      display: none;
    `};
`;

export const DesktopIconButtonContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey['500']};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;
