import Typography from '@mui/material/Typography';
import React from 'react';

import { useTodayTasks } from '../../hooks/use-today-tasks';
import * as Styled from './today-count.style';

export const LoadingTodayCount: React.FC = () => <Styled.Container $loading />;

export const TodayCount: React.FC = () => {
  const tasks = useTodayTasks();

  return !!tasks.length ? (
    <Styled.Container>
      <Typography variant="overline" component="span">
        {tasks.length}
      </Typography>
    </Styled.Container>
  ) : null;
};
