import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { Checkbox } from 'shared/components/ui/checkbox';

import * as Styled from './delete-goal-section.style';

export type DeleteGoalSectionMessageProps = {
  name?: string;
  onSubmit: (deleteTasks: boolean) => void;
  onCancel: () => void;
};

export const DeleteGoalSectionMessage: React.FC<
  DeleteGoalSectionMessageProps
> = ({ name, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [deleteTasks, setDeleteTasks] = React.useState(false);

  const onChangeDeleteTasks = () =>
    setDeleteTasks((oldDeleteTasks) => !oldDeleteTasks);

  const _onSubmit = () => onSubmit(deleteTasks);

  return (
    <>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('forms.delete-goal-section.message.title')}
        </Typography>
      </Styled.Header>
      <Styled.Body>
        <Typography variant="body2">
          {name ? (
            <>
              {t('forms.delete-goal-section.message.description.1')}
              <Styled.GoalSectionName>{name}</Styled.GoalSectionName>
              {t('forms.delete-goal-section.message.description.2')}
            </>
          ) : (
            t('forms.delete-goal-section.message.description.no-name')
          )}
        </Typography>

        <Styled.CheckboxContainer>
          <Checkbox
            name="delete-tasks"
            id="delete-tasks"
            value="delete-tasks"
            checked={deleteTasks}
            onChange={onChangeDeleteTasks}
          />
          <Typography variant="body2" component="label" htmlFor="delete-tasks">
            {t('forms.delete-goal-section.message.delete-tasks.question')}
          </Typography>
        </Styled.CheckboxContainer>
      </Styled.Body>

      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          color={Colors.Inherit}
          size={Sizes.Medium}
          onClick={onCancel}
        >
          {t('forms.delete-goal-section.buttons.cancel.label')}
        </Button>
        <Button
          variant={Variants.Contained}
          color={Colors.Error}
          size={Sizes.Medium}
          onClick={_onSubmit}
        >
          {t('forms.delete-goal-section.buttons.submit.label')}
        </Button>
      </Styled.Interactions>
    </>
  );
};
