import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { HabitFormFields, validationSchema } from 'shared/types/habit-form';

export const useHabitForm = (
  submit: (habitData: HabitFormFields) => void,
  initialValues: HabitFormFields,
) => {
  const { handleSubmit, ...formProps } = useForm<HabitFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  const onSubmit = handleSubmit((data) => {
    submit(data);
  });

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
