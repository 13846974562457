import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $isSelected?: boolean;
}>`
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(1)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: transparent;
  transition: background-color 200ms;
  cursor: pointer;

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${theme.palette.brown['50']};
    `};
`;

export const Header = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

export const CheckboxContainer = styled.span<{ $isFaded?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacing(0.25)};
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;

  line-height: 62.5%;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.grey['400']};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 3.2rem;
      height: 3.2rem;
      min-width: 3.2rem;
      min-height: 3.2rem;
    `};

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.3;
    `};
`;

export const HeaderContent = styled.div<{ $isFaded?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.75)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  min-width: 0;

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.5;
    `}

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-top: ${({ theme }) => theme.spacing(1.5)};
      pointer-events: none;
    `};
`;

export const TitleContainer = styled.span<{ $isFaded: boolean }>`
  flex: 1;
  line-height: 1;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(6.5)};
`;

export const RepeatLabel = styled.span<{ $placeholder: boolean }>`
  position: relative;

  line-height: 62.5%;
  color: ${({ $placeholder, theme }) =>
    $placeholder ? theme.palette.text.secondary : theme.palette.text.primary};
`;
